import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import saveOutline from '@iconify/icons-eva/save-outline';
import { Icon } from '@iconify/react';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select
} from '@mui/material';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import LoadingScreenSmall from '../../LoadingScreenSmall';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PotentialMap({ place, onSave, modeprev, minutesprev, loading, button, onSwitch, catchment, mapCh, onGrid }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const array = [];
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'catchmentmap', // container id
      // style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('style.load', () => {
      map.current.setConfigProperty('basemap', 'lightPreset', 'dawn');
      map.setConfigProperty('basemap', 'showTransitLabels', true);
    });
    map.current.on('load', () => {

      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }
      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': ['get', 'fillColor']
              ,
              'fill-opacity': 0.3
            }
          }
        );
      }
      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 2
          }
        });
      }


      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
      getIso();
    });
  });

  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  let profile = mode;
  let minutes = minuter;
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });
  function getIsoAll() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=15,10,5&contours_colors=6706ce,04e813,4286f4&generalize=100&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
      console.log('data', data);
    });
  }
  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&generalize=100&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
    });
  }

  const onChange = (e) => {
    profile = e.target.value;
    setMode(e.target.value);
    getIso();
  };

  const onChangeDuration = (e) => {
    minutes = e.target.value;
    if (e.target.value === 'all') {

      getIsoAll();
    } else {
      setMinuter(e.target.value);
      getIso();
    }
  };

  const handleSave = () => {
    onSave(mode, minuter);
  };
  return (
    <Card sx={{ borderRadius: 1 }}>
      <div id="catchmentmap" style={{ width: '100%', height: '70vh' }} />
      <Card
        sx={{
          display: 'flex',
          position: 'absolute',
          borderRadius: 1,
          zIndex: 9,
          top: 0,
          left: '30%',
          right: '30%',
          background: '#fff',
          justifyContent: 'center',
          'margin-top': 10
        }}
      >
        <Grid container spacing={2} sx={{ alignItems: 'center', p: 2 }}>
          <Grid item xs={12} md={5}>
            <FormControl
              component="fieldset"
              justifyContent="center"
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <FormLabel component="legend">{translate('travelMode')}</FormLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={translate('travelMode')}
                value={mode}
                sx={{ height: '100%', variant: 'h3' }}
                onChange={onChange}
              >
                <MenuItem value={'walking'}>
                  {translate('walking')}
                </MenuItem>
                <MenuItem value={'cycling'}>
                  {translate('cycling')}
                </MenuItem>
                <MenuItem value={'driving'}>
                  {translate('driving')}
                </MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} md={5}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormLabel component="legend">{translate('minutes')}:</FormLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={translate('minutes')}
                value={minuter}
                sx={{ height: '100%', variant: 'h3' }}
                onChange={onChangeDuration}
              >
                <MenuItem value={'5'}>
                  5
                </MenuItem>
                <MenuItem value={'10'}>
                  10
                </MenuItem>
                <MenuItem value={'15'}>
                  15
                </MenuItem>
                <MenuItem value={'all'}>
                  All
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <IconButton size='large'>
              <Icon icon={saveOutline} onClick={handleSave} color="#008f00" />
            </IconButton>

          </Grid>
        </Grid>
        {loading && (
          <LoadingScreenSmall />
        )}
      </Card>
    </Card>
  );
}
