import { Alert, AlertTitle, Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.string, // Example ['admin', 'leader']
  children: PropTypes.node
};

const useCurrentRole = (profile) => {
  if (profile?.internal) {
    return 'internal';
  }
  // Logic here to get current user role
  const role = 'admin';
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user } = useAuth();
  const currentRole = useCurrentRole(user);
  if (!user) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: '#fff',
          'margin-left': 0,
          'margin-top': 0,
          overflow: 'auto'
        }}
      >
        <LoadingScreen />
      </Box>
    );
  }
  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
