import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import IntroGuard from '../guards/IntroGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import VasakronanGuard from '../guards/VasakronanGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import ClaimsGuard from '../guards/ClaimsGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));
const Overview = Loadable(lazy(() => import('../pages/dashboard/spaceopt/Overview')));
const Descriptive = Loadable(lazy(() => import('../pages/dashboard/spaceopt/Descriptive')));
const Prescriptive = Loadable(lazy(() => import('../pages/dashboard/spaceopt/Prescriptive')));
const Notifications = Loadable(lazy(() => import('../pages/dashboard/spaceopt/Notifications')));
const SpaceOptSettings = Loadable(lazy(() => import('../pages/dashboard/spaceopt/Settings')));
const SpaceOptPotential = Loadable(lazy(() => import('../pages/dashboard/spaceopt/SpaceOptPotential')));
const SpaceOptFup = Loadable(lazy(() => import('../pages/dashboard/spaceopt/SpaceOptFup')));
const SpaceOptTr = Loadable(lazy(() => import('../pages/dashboard/spaceopt/TenantReview')));
const WpClients = Loadable(lazy(() => import('../pages/dashboard/spaceopt/WpClients')));
const WpProject = Loadable(lazy(() => import('../pages/dashboard/spaceopt/WpProject')));
const MindTrendTool = Loadable(lazy(() => import('../pages/dashboard/MindTrendTool')));
const TurnoverCollector = Loadable(lazy(() => import('../pages/dashboard/TurnoverCollector')));
const MindApp = Loadable(lazy(() => import('../pages/dashboard/MindApp')));
const MindSales = Loadable(lazy(() => import('../pages/dashboard/MindSales')));

const MindProjects = Loadable(lazy(() => import('../pages/dashboard/MindProjects')));
const MindUtility = Loadable(lazy(() => import('../pages/dashboard/MindUtility')));
const MindLiquidity = Loadable(lazy(() => import('../pages/dashboard/MindLiquidity')));
const MindCosts = Loadable(lazy(() => import('../pages/dashboard/MindCosts')));
const Places = Loadable(lazy(() => import('../pages/dashboard/PlacesNew')));
const Place = Loadable(lazy(() => import('../pages/dashboard/Place')));
const Logistics = Loadable(lazy(() => import('../pages/dashboard/Logistics')));
const PlaceNew = Loadable(lazy(() => import('../pages/dashboard/PlaceNew')));
const PopulationV = Loadable(lazy(() => import('../pages/dashboard/Populationv')));
const AOHV = Loadable(lazy(() => import('../pages/dashboard/Aohv')));
const RoadV = Loadable(lazy(() => import('../pages/dashboard/Roadv')));
const DwellV = Loadable(lazy(() => import('../pages/dashboard/Dwellv')));
const SupplyV = Loadable(lazy(() => import('../pages/dashboard/Supplyv')));
const OverviewV = Loadable(lazy(() => import('../pages/dashboard/Overviewv')));
const ConsumtionV = Loadable(lazy(() => import('../pages/dashboard/Consumtionv')));
const CatchmentV = Loadable(lazy(() => import('../pages/dashboard/Catchmentv')));
const Assistantv = Loadable(lazy(() => import('../pages/dashboard/Assistantv')));
const Paypergo = Loadable(lazy(() => import('../pages/dashboard/Paypergo')));

const WeatherV = Loadable(lazy(() => import('../pages/dashboard/Weatherv')));
const TrafficV = Loadable(lazy(() => import('../pages/dashboard/Trafficv')));
const Spaces = Loadable(lazy(() => import('../pages/dashboard/Spaces')));
const Space = Loadable(lazy(() => import('../pages/dashboard/Space')));
const Site = Loadable(lazy(() => import('../pages/dashboard/Site')));
const SiteCompany = Loadable(lazy(() => import('../pages/dashboard/SiteCompany')));
const SiteLandlord = Loadable(lazy(() => import('../pages/dashboard/SiteLandlord')));
const SpaceNew = Loadable(lazy(() => import('../pages/dashboard/SpaceNew')));
const Properties = Loadable(lazy(() => import('../pages/dashboard/Properties')));
const Property = Loadable(lazy(() => import('../pages/dashboard/Property')));
const PropertyNew = Loadable(lazy(() => import('../pages/dashboard/PropertyNew')));
const Missions = Loadable(lazy(() => import('../pages/dashboard/Missions')));
const Mission = Loadable(lazy(() => import('../pages/dashboard/Mission')));
const BigFatMap = Loadable(lazy(() => import('../pages/dashboard/BigFatMap')));

const GuidedLease = Loadable(lazy(() => import('../pages/dashboard/GuidedLease')));

const Contracts = Loadable(lazy(() => import('../pages/dashboard/Contracts')));
const Contract = Loadable(lazy(() => import('../pages/dashboard/Contract')));
const Activities = Loadable(lazy(() => import('../pages/dashboard/Activities')));
const Activity = Loadable(lazy(() => import('../pages/dashboard/Activity')));
const Companies = Loadable(lazy(() => import('../pages/dashboard/Companies')));
const CompaniesRetail = Loadable(lazy(() => import('../pages/dashboard/CompaniesRetail')));
const Company = Loadable(lazy(() => import('../pages/dashboard/Company')));
const Contacts = Loadable(lazy(() => import('../pages/dashboard/Contacts')));
const Contact = Loadable(lazy(() => import('../pages/dashboard/Contact')));
const Needs = Loadable(lazy(() => import('../pages/dashboard/demand/need/Needs')));
const OutdatedNeeds = Loadable(lazy(() => import('../pages/dashboard/demand/need/OutdatedNeeds')));
const NeedSelector = Loadable(lazy(() => import('../pages/dashboard/demand/need/NeedSelector')));
const Need = Loadable(lazy(() => import('../pages/dashboard/demand/need/Need')));
const Match = Loadable(lazy(() => import('../pages/dashboard/demand/need/Match')));
const MatchLl = Loadable(lazy(() => import('../pages/dashboard/demand/need/MatchClient')));

const Tenantable = Loadable(lazy(() => import('../pages/dashboard/Tenantable')));
const Actic = Loadable(lazy(() => import('../pages/dashboard/Actic')));
const Prjcts = Loadable(lazy(() => import('../pages/dashboard/Prjcts')));
const Prjct = Loadable(lazy(() => import('../pages/dashboard/Prjct')));
const Truth = Loadable(lazy(() => import('../pages/dashboard/Truth')));
const NewPlace = Loadable(lazy(() => import('../pages/dashboard/NewPlace')));
const TargetGroup = Loadable(lazy(() => import('../pages/dashboard/TargetGroup')));
const FootfallComparison = Loadable(lazy(() => import('../pages/dashboard/FootfallComparison')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const MarketNew = Loadable(lazy(() => import('../pages/dashboard/MarketNew')));
const GoogleRatings = Loadable(lazy(() => import('../pages/dashboard/GoogleRatings')));
const DistanceVis = Loadable(lazy(() => import('../pages/dashboard/DistanceVis')));

const Inspiration = Loadable(lazy(() => import('../pages/dashboard/neo/inspiration/Inspiration')));

const Prestudy = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Prestudy')));
const Employees = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Employees')));
const Management = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Management')));
const CurrentWorkplace = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/CurrentWorkplace')));
const Area = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Area')));
const Market = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Market')));
const Scenarios = Loadable(lazy(() => import('../pages/dashboard/neo/prestudy/Scenarios')));

const Concretization = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/Concretization')));
const Directives = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/Directives')));
const WorkplaceStrategy = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/WorkplaceStrategy')));
const Masterplan = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/Masterplan')));
const Program = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/Program')));
const Requirements = Loadable(lazy(() => import('../pages/dashboard/neo/concretization/Requirements')));

const Solution = Loadable(lazy(() => import('../pages/dashboard/neo/solution/Solution')));
const Layout = Loadable(lazy(() => import('../pages/dashboard/neo/solution/Layout')));
const NeoContracts = Loadable(lazy(() => import('../pages/dashboard/neo/solution/NeoContracts')));
const Establishment = Loadable(lazy(() => import('../pages/dashboard/neo/solution/Establishment')));
const Fup = Loadable(lazy(() => import('../pages/dashboard/neo/solution/Fup')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <IntroGuard>
            <ClaimsGuard>
              <DashboardLayout />
            </ClaimsGuard>
          </IntroGuard>
        </AuthGuard>
      ),
      children: [
        { element: <TrafficV /> },
        { path: '', element: <TrafficV /> },
        { element: <Navigate to="/" replace /> },
        { path: 'app', element: <TrafficV /> },
        {
          path: 'mind',
          children: [
            { element: <Navigate to="/mind/overview" replace /> },
            {
              path: 'overview',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindApp />
                </RoleBasedGuard>
              )
            },
            {
              path: 'sales',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindSales />
                </RoleBasedGuard>
              )
            },
            {
              path: 'projects',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindProjects />
                </RoleBasedGuard>
              )
            },
            {
              path: 'utility',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindUtility />
                </RoleBasedGuard>
              )
            },
            {
              path: 'liquidity',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindLiquidity />
                </RoleBasedGuard>
              )
            },
            {
              path: 'costs',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindCosts />
                </RoleBasedGuard>
              )
            },
            {
              path: 'forecasts',
              element: (
                <RoleBasedGuard accessibleRoles="internal">
                  <MindTrendTool />
                </RoleBasedGuard>
              )
            },
            {
              path: 'turnovers',
              element: (
                <TurnoverCollector />
              )
            }
          ]
        },
        { path: 'auth/linkedin/callback', element: <MindApp /> },
        {
          path: 'places',
          children: [
            { element: <Navigate to="/places/places" replace /> },
            { path: 'places', element: <Places /> },
            { path: 'places/:id', element: <Place /> },
            { path: 'new-place', element: <PlaceNew /> },
            { path: 'overview', element: <OverviewV /> },
            { path: 'population', element: <PopulationV /> },
            { path: 'road', element: <RoadV /> },
            { path: 'consumtion', element: <ConsumtionV /> },
            { path: 'dwell', element: <DwellV /> },
            { path: 'supply', element: <SupplyV /> },
            { path: 'catchment', element: <CatchmentV /> },
            { path: 'traffic', element: <TrafficV /> },
            { path: 'weather', element: <WeatherV /> },
            { path: 'selfserve', element: <Paypergo /> },
            { path: 'aoh', element: <AOHV /> },
            { path: 'landeat', element: <LandingPage /> },
            { path: 'assistant', element: <Assistantv /> }
          ]
        },
        {
          path: 'spaces',
          children: [
            { element: <Navigate to="/spaces/spaces" replace /> },
            { path: 'spaces', element: <Spaces /> },
            { path: 'spaces/:id', element: <Space /> },
            { path: 'sites/:id', element: <Site /> },
            { path: 'sites/cpy', element: <SiteCompany /> },
            { path: 'sites/ll', element: <SiteLandlord /> },
            { path: 'new-space', element: <SpaceNew /> }
          ]
        },
        {
          path: 'tenantable',
          children: [
            { element: <Navigate to="/tenantable/dashboard" replace /> },
            { path: 'dashboard', element: <Tenantable /> },
          ]
        },
        {
          path: 'actic',
          children: [
            { element: <Navigate to="/actic/dashboard" replace /> },
            { path: 'dashboard', element: <Actic /> },
          ]
        },
        {
          path: 'logistics',
          children: [
            { element: <Navigate to="/logistics/spaces" replace /> },
            { path: 'spaces', element: <Logistics /> },
            { path: 'spaces/:id', element: <Space /> },
            { path: 'new-space', element: <SpaceNew /> }
          ]
        },
        {
          path: 'properties',
          children: [
            { element: <Navigate to="/properties/properties" replace /> },
            { path: 'properties', element: <Properties /> },
            { path: 'properties/:id', element: <Property /> },
            { path: 'new-property', element: <PropertyNew /> }
          ]
        },
        {
          path: 'prjcts',
          children: [
            { element: <Navigate to="/prjcts/prjcts" replace /> },
            { path: 'prjcts', element: <Prjcts /> },
            { path: 'prjcts/:id', element: <Prjct /> },
            { path: 'truth', element: <Truth /> },
            { path: 'bfm', element: <BigFatMap /> },
            { path: 'targetgroup', element: <TargetGroup /> },
            { path: 'marketnew', element: <MarketNew /> },
            { path: 'footfallcomparison', element: <FootfallComparison /> },
            { path: 'customer', element: <Prjcts /> },
            { path: 'newplace', element: <NewPlace /> }
          ]
        },
        {
          path: 'missions',
          children: [
            { element: <Navigate to="/missions/missions" replace /> },
            { path: 'missions', element: <Missions /> },
            { path: 'missions/:id', element: <Mission /> },
            { path: 'guided', element: <GuidedLease /> }
          ]
        },
        {
          path: 'activities',
          children: [
            { element: <Navigate to="/activities/activities" replace /> },
            { path: 'activities', element: <Activities /> },
            { path: 'activities/:id', element: <Activity /> }
          ]
        },
        {
          path: 'contracts',
          children: [
            { element: <Navigate to="/contracts/contracts" replace /> },
            { path: 'contracts', element: <Contracts /> },
            { path: 'contracts/:id', element: <Contract /> }
          ]
        },
        {
          path: 'companies',
          children: [
            { element: <Navigate to="/companies/companies" replace /> },
            { path: 'companies', element: <Companies /> },
            { path: 'companies/:id', element: <Company /> }
          ]
        },
        {
          path: 'companiesretail',
          children: [
            { element: <Navigate to="/companies/companiesretail" replace /> },
            { path: 'companiesretail', element: <CompaniesRetail /> },
            { path: 'companies/:id', element: <Company /> }
          ]
        },
        {
          path: 'contacts',
          children: [
            { element: <Navigate to="/contacts/contacts" replace /> },
            { path: 'contacts', element: <Contacts /> },
            { path: 'contacts/:id', element: <Contact /> }
          ]
        },
        {
          path: 'needs',
          children: [
            { element: <Navigate to="/needs/needs" replace /> },
            { path: 'needs', element: <Needs /> },
            { path: 'oldneeds', element: <OutdatedNeeds /> },
            { path: 'needs/:id', element: <Need /> },
            { path: 'match', element: <Match /> },
            { path: 'matchll', element: <MatchLl /> },
            { path: 'new', element: <NeedSelector /> }]
        },
        {
          path: 'chat',
          children: [
            { element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        {
          path: 'spaceopt',
          children: [
            { element: <Navigate to="/spaceopt/overview" replace /> },
            { path: 'overview', element: <Overview /> },
            { path: 'descriptive', element: <Descriptive /> },
            { path: 'prescriptive', element: <Prescriptive /> },
            { path: 'notifications', element: <Notifications /> },
            { path: 'settings', element: <SpaceOptSettings /> },
            { path: 'trd', element: <SpaceOptTr /> },
            { path: 'tr', element: <WpClients /> },
            { path: 'tr/:id', element: <WpProject /> },
            { path: 'potential', element: <SpaceOptPotential /> },
            { path: 'fup', element: <SpaceOptFup /> }
          ]
        },
        {
          path: 'neo',
          children: [
            { element: <Navigate to="/neo/overview" replace /> },
            { path: 'overview', element: <Overview /> },
            { path: 'inspiration', element: <Inspiration /> },

            { path: 'prestudy', element: <Prestudy /> },
            { path: 'prestudy/employees', element: <Employees /> },
            { path: 'prestudy/management', element: <Management /> },
            { path: 'prestudy/currentworkplace', element: <CurrentWorkplace /> },
            { path: 'prestudy/area', element: <Area /> },
            { path: 'prestudy/market', element: <Market /> },
            { path: 'prestudy/scenarios', element: <Scenarios /> },

            { path: 'concretization', element: <Concretization /> },
            { path: 'concretization/directives', element: <Directives /> },
            { path: 'concretization/workplacestrategy', element: <WorkplaceStrategy /> },
            { path: 'concretization/masterplan', element: <Masterplan /> },
            { path: 'concretization/program', element: <Program /> },
            { path: 'concretization/requirements', element: <Requirements /> },

            { path: 'solution', element: <Solution /> },
            { path: 'solution/layout', element: <Layout /> },
            { path: 'solution/contracts', element: <NeoContracts /> },
            { path: 'solution/establishments', element: <Establishment /> },
            { path: 'solution/fup', element: <Fup /> },

          ]
        },
        {
          path: 'google',
          children: [
            { element: <Navigate to="/google/keywords" replace /> },
            { path: 'keywords', element: <GoogleRatings /> }
          ]
        },
        {
          path: 'distance',
          children: [
            { element: <Navigate to="/distance/wiz" replace /> },
            { path: 'wiz', element: <DistanceVis /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'comingsoon', element: <ComingSoon /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <TrafficV /> },
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
