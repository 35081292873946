import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card, Avatar, Typography, CardContent } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import SvgIconStyle from '../../SvgIconStyle';

import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import { useTheme } from '@mui/styles';

mapboxgl.accessToken = mapConfig;
const GeoJSON = require('geojson');

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  traffic: PropTypes.any.isRequired,
  catchment: PropTypes.any.isRequired
};

export default function PotentialMap({ place, origins, competition, traffic, catchment, onHover, type, isAoh, onSelect }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      // style: 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 13
    });
    map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
    // map.current.scrollZoom.disable();
  });
  useEffect(() => {
    if (!map.current || !traffic) return; // wait for map to initialize
    map.current.on('load', () => {
      // map.current.loadImage('https://docs.mapbox.com/help/demos/using-recolorable-images-in-mapbox-maps/shop-15.png', (error, image) => {
      //if (error) throw error;
      //  map.addImage('store-icon', image, { 'sdf': true });
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: traffic
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      if (!map.current.getSource('origins')) {
        map.current.addSource('origins', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getSource('competition')) {
        map.current.addSource('competition', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      map.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': {
              property: 'value',
              stops: [
                [0, '#008f00'],
                [1000, '#30D5C8'],
                [2000, '#FFFF00'],
                [5000, '#FFD700'],
                [10000, '#8b0000']
              ]
            }, // blue color fill
            'fill-opacity': 0.6
          }
        });
        map.current.addLayer({
          id: 'trafficline',
          type: 'line',
          source: 'maine', // reference the data source
          layout: { visibility: 'none' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!map.current.getLayer('origins-heat')) {
        map.current.addLayer(
          {
            id: 'origins-heat',
            type: 'heatmap',
            source: 'origins',
            maxzoom: 13,
            paint: {
              // Increase the heatmap weight based on frequency and property magnitude
              'heatmap-weight': [
                'interpolate',
                ['linear'],
                ['get', 'trips'],
                0,
                0,
                6,
                1
              ],
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              'heatmap-intensity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                9,
                3
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.2,
                'rgb(103,169,207)',
                0.4,
                'rgb(209,229,240)',
                0.6,
                'rgb(253,219,199)',
                0.8,
                'rgb(239,138,98)',
                1,
                'rgb(178,24,43)'
              ],
              // Adjust the heatmap radius by zoom level
              'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                2,
                12,
                20
              ],
              // Transition from heatmap to circle layer by zoom level
              'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 12, 1]
            }
          }
        );
      }
      if (!map.current.getLayer('origins')) {
        map.current.addLayer({
          id: 'origins',
          type: 'circle',
          source: 'origins',
          minzoom: 12,
          paint: {
            'circle-color': {
              property: 'trips',
              stops: [
                [10, 'green'],
                [20, 'blue'],
                [30, 'yellow'],
                [40, 'orange'],
                [50, 'red']
              ]
            },
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }

      if (!map.current.getLayer('competition')) {
        map.current.addLayer({
          id: 'competition',
          type: 'circle',
          source: 'competition',
          paint: {
            'circle-color': {
              property: 'rating',
              stops: [
                [1, 'red'],
                [2, 'orange'],
                [3, 'yellow'],
                [4, 'blue'],
                [5, 'green']
              ]
            },
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
      if (!document.getElementById('contentdiv')) {
        const layers = ['0 - 1000', '1000 - 2000', '2000 - 5000', '5000 - 10000', '10000+'];
        const colors = ['#008f00', '#30D5C8', '#FFFF00', '#FFD700', '#8b0000'];
        const legend = document.getElementById('legend');
        const item = document.createElement('div');
        item.id = 'contentdiv';
        const value = document.createElement('span');
        value.innerHTML = type === 'population' ? `<h3>People</h3>`: `<h3>People/day</h3>`;
        legend.style.setProperty('background-color', theme.palette.background.default)
        item.appendChild(value);
        legend.appendChild(item);
        layers.forEach((layer, i) => {
          if (i < 5) {
            const color = colors[i];
            const item = document.createElement('div');
            const key = document.createElement('span');
            key.style = 'display:inline-block;border-radius:20%;width:10px;height:10px;margin-right:5px;';
            key.style.backgroundColor = color;
            const value = document.createElement('span');
            value.innerHTML = `${layer}`;
            item.appendChild(key);
            item.appendChild(value);
            legend.appendChild(item);
          }
        });
      };

      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        if (!states.length) {
          onHover(0);
        } else {
          onHover(Math.round(states[0].properties.value));
        };
      });

      map.current.on('click', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        if (!states.length) {
          onSelect(0);
        } else {
          console.log('selected');
          onSelect(Math.round(states[0].properties.area_code));
        };
      });
      map.current.on('mousemove', (event) => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['competition']
        });
        if (!features.length) {
          popup.remove();
          return;
        }
        popup
          .setLngLat(features[0].geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="https://maps.app.goo.gl/${features[0].properties.place_id}">
            ${features[0].properties.name}
            </a></h5><p>
            Rating: ${features[0].properties.rating} (${features[0].properties.user_ratings_total} omdömen)
            </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      map.current.on('mousemove', (event) => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['origins']
        });
        if (!features.length) {
          popup.remove();
          return;
        }
        popup
          .setLngLat(features[0].geometry.coordinates)
          .setHTML(
            `<h5 className="display-4">
            ${features[0].properties.name}
            </a></h5><p>
            Visits: ${features[0].properties.trips}
            </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
      // });
    });
  });

  useEffect(() => {
    if (!map.current || !catchment.length) return;
    map?.current?.getSource('catchment')?.setData({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [catchment]
      }
    });
  }, [catchment]);
  useEffect(() => {
    if (!map.current) return;
    map?.current?.getSource('maine')?.setData(traffic);
  }, [type, traffic, map?.current]);

  useEffect(() => {
    if (!origins || origins?.length === 0) {
      map.current?.getSource('origins')?.setData();
    } else if (!origins || !map.current || !map.current.getSource('origins')) return;
    const points = GeoJSON.parse(origins, { Point: ['lat', 'lng'], include: ['trips', 'name'] });
    map.current?.getSource('origins')?.setData(points);

  }, [origins]);

  useEffect(() => {
    if (!competition || !map.current || !map.current.getSource('competition')) return;
    const points = GeoJSON.parse(competition, { Point: ['lat', 'lng'], include: ['rating', 'name', 'user_ratings_total', 'place_id'] });
    map.current.getSource('competition').setData(points);
    if (!place) return;
    const coords = [place.longitude, place.latitude];
    let lineArr = [];
    const linesz = competition.map((o) => {
      const lineCoords = { geo: { type: 'LineString', coordinates: [coords, [o.lng, o.lat]] }, ...o };
      return lineCoords;
    })

    const lines = GeoJSON.parse(linesz, { GeoJSON: 'geo', include: ['rating'] });
    map.current.getSource('route').setData(lines);

  }, [competition]);
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };

  const popup = new mapboxgl.Popup();
  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Box position="fixed" style={{ width: '100%', height: '100vh' }}>
      <div id="map" style={{ width: '100%', height: '100vh' }} />

      <div
        id="legend"
        style={{
          position: 'absolute',
          bottom: 10,
          right: theme.spacing(3),
          background: '#fff',
          'margin-right': 0,
          overflow: 'auto',
          'border-radius': 15,
          padding: 10,
          'line-height': 18,
          'margin-bottom': 20,
          width: 200
        }}
      />
    </Box>
  );
}

