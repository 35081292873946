import { LineChart } from '@mui/x-charts/LineChart';
import { Stack, Typography, Card } from '@mui/material';

const xLabels = [
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030'
];

export default function DashedLineChart({ data, datac }) {
  console.log('trend data', data);
 // const values = data?.sort((a, b) => (b.year > a.year ? 1 : -1))
  const values = data.map((a) => a.idx);
  const valuesc = datac?.sort((a, b) => (b.year > a.year ? 1 : -1)).map((a) => a.idx);


  return (

    <LineChart
      height={400}
      series={datac?.length ? ([
        {
          data: values, label: 'Population index', id: 'pvId',
          connectNulls: false
        },
        {
          data: valuesc, label: 'Comp', id: 'pvIdc',
          color: '#808080',
          connectNulls: false
        },

      ]
      ) : ([
        {
          data: values, label: 'Population index', id: 'pvId',
          connectNulls: false
        }
      ]
      )}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        '.MuiLineElement-root, .MuiMarkElement-root': {
          strokeWidtharray: '1 4',
        },
        '.MuiLineElement-series-pvId': {
          strokeDasharray: '0 0',
        },
        '.MuiLineElement-series-uvIdc': {
          strokeDasharray: '4 4 4 1',
        },
        '.MuiLineElement-series-uvId': {
          strokeDasharray: '4 1',
        },
        '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
          fill: '#fff',
        },
        '& .MuiMarkElement-highlighted': {
          stroke: 'none',
        },
      }}
    />
  );
}