import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { styled } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function AnalyticsCurrentSubject({ industrydata }) {
  const filteredData = industrydata
    .filter((i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office')
    .sort((a, b) => (a.oppRev < b.oppRev ? 1 : -1));
  if (!industrydata || industrydata.length < 1) return null;
  const opparray = filteredData.map((i) => Math.max(i.oppRev, 0));
  const categoriez = filteredData.map((i) => i.name);
  const newchartdata = [{ name: 'Opportunity', data: opparray }];
  const maxVal = Math.max(opparray);
  const minVal = Math.min(opparray);
  const chartOptions = merge(BaseOptionChart(), {
    options: {
      chart: {
        type: 'bar',
        height: 440,
        stacked: true
      },
      colors: ['#008FFB', '#FF4560'],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },

      grid: {
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: minVal,
        max: maxVal,
        title: {
          // text: 'Age',
        }
      },
      tooltip: {
        shared: false,
        x: {
          formatter(val) {
            return `${Math.abs(val)}%`;
          }
        },
        y: {
          formatter(val) {
            return `${Math.abs(val)}%`;
          }
        }
      },
      xaxis: {
        categories: categoriez,
        title: {
          text: 'Opportunity'
        },
        labels: {
          formatter: (val) => Math.round(val)
        }
      }
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="bar" series={newchartdata} options={chartOptions} height={340} />
    </ChartWrapperStyle>
  );
}
