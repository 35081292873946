import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contracts: [],
  contract: null,
  tkn: null,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getContractsSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload;
    },

    // GET POST INFINITE
    getContractsInitial(state, action) {
      state.isLoading = false;
      state.contracts = action.payload;
    },

    getMoreContracts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getContractSuccess(state, action) {
      state.isLoading = false;
      state.contract = action.payload;
    },

    // GET POST
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.linkedin = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreContracts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllContracts() {
  console.log('getting contracts');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/contracts/index');
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getContractsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContractsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/contracts/indexinit',
        params: {
          index,
          step
        }
      });

      const results = response.data.contracts.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getContractsInitial(response.data.contracts));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContract(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/contracts/contract/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getContractSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
