import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

ClaimsGuard.propTypes = {
  children: PropTypes.node
};

export default function ClaimsGuard({ children }) {
  const { user } = useAuth();

  const claims = user?.dummy;
  if (!claims) {
    return (
      <LoadingScreen />
    );
  }

  return <>{children}</>;
}
