import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  spacetypes: [],
  industries: [],
  forecasts: [],
  spacetype: null,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'spacetype',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getSpacetypesSuccess(state, action) {
      state.isLoading = false;
      state.spacetypes = action.payload;
    },

    // GET POSTS
    getIndustriesSuccess(state, action) {
      state.isLoading = false;
      state.industries = action.payload;
    },
    // GET POSTS
    getForecastsSuccess(state, action) {
      state.isLoading = false;
      state.forecasts = action.payload;
    },
    // GET POST INFINITE
    getSpacetypesInitial(state, action) {
      state.isLoading = false;
      state.spacetypes = action.payload;
    },

    getMoreSpacetypes(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getSpacetypeSuccess(state, action) {
      state.isLoading = false;
      state.spacetype = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreSpacetypes } = slice.actions;

// ----------------------------------------------------------------------

export function getAllSpacetypes() {
  console.log('spacetypes load');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spacetypes/index');
      console.log('Spacetypes loaded');
      dispatch(slice.actions.getSpacetypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllIndustries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spacetypes/indexindustries');
      dispatch(slice.actions.getIndustriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAllForecasts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/forecasts/index');
      dispatch(slice.actions.getForecastsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSpacetypesInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spacetypes/', {
        params: { index, step }
      });

      const results = response.data.results.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getPostsInitial(response.data.results));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpacetype(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spacetypes/spacetype', {
        params: { id, id2 }
      });
      dispatch(slice.actions.getSpacetypeSuccess(response.data.spacetypetype));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
