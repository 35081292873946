import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Card } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

const GeoJSON = require('geojson');
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  spaces: PropTypes.any.isRequired
};
///  TODO: check out what is going on with empty array to catchment

export default function PotentialMap(props) {
  const { place, spaces, catchment } = props;
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const array = useState([]);
  console.log(catchment);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat - 0.01],
      zoom: 15
    });
  });

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [polyg]
            }
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'fill',
          source: 'catchment', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#008f00', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }

      const sourceIdSpaces = 'spaces';
      const sourcespaces = map.current.getSource(sourceIdSpaces);
      if (!sourcespaces) {
        map.current.addSource('spaces', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] })
          }
        });
      }

      if (!map.current.getLayer('places')) {
        map.current.addLayer({
          id: 'places',
          type: 'circle',
          source: 'spaces',
          paint: {
            'circle-color': '#AE452B',
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
      // Initialize the marker at the query coordinates
      const popup = new mapboxgl.Popup();
      // Make the API call
      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
        if (!features.length) {
          popup.remove();
          return;
        }
        const feature = features[0];
        popup
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="/properties">
              ${feature.properties.name}
              </a></h5><p>
              ${feature.properties.city_name}
              </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });
  });

  useEffect(() => {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/places/findincatchment/${place.id}`;
    const body = {
      catchment: JSON.stringify(array)
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((response) => {
        const spc = response;
        const url = `https://plejsapp-api.herokuapp.com/api/v1/places/findpincatchment/${place.id}`;
        const body = {
          catchment: JSON.stringify(array)
        };
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Network response was not ok.');
          })

          .then((response) => {
            sendUnitsToMap(spc, response);
          })
          .catch((error) => console.log(error.message));
      })
      .catch((error) => console.log(error.message));
  }, [array]);

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };

  function sendUnitsToMap(sp, pr) {
    if (map.current) {
      const sourceIdspace = 'spacesc';
      const sourcespace = map.current.getSource(sourceIdspace);
      if (sourcespace && sp) {
        const spacedata = GeoJSON.parse(sp, { Point: ['latitude', 'longitude'] });
        sourcespace.setData(spacedata);
      }

      const sourceIdprop = 'propertiesc';
      const sourceprop = map.current.getSource(sourceIdprop);
      if (sourceprop && pr) {
        sourceprop.setData(GeoJSON.parse(pr, { Point: ['latitude', 'longitude'] }));
      }
    }
    if (sp || pr) {
      catchment(sp, pr);
    }
  }
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const storezgeojson = GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] });
  if (map.current && map.current.getSource('store')) {
    map.current.getSource('store').setData(storezgeojson);
  }
  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <Box>
        <div id="map" style={{ width: '100%', height: '90vh' }} />
      </Box>
    </Card>
  );
}
