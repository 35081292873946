import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 480;
const LEGEND_HEIGHT = 0;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function TruthBar({ industrydata, forecast, height }) {
  const { translate } = useLocales();
  const theme = useTheme();
  if (!forecast) return <LoadingScreen />;
  const demandarray = forecast.industrydata
    .sort((a, b) => (b.demandPhysical > a.demandPhysical ? 1 : -1))
    .map((i) => Math.round(i.demandPhysical / 100000) / 10);
  const categories = forecast.industrydata
    .sort((a, b) => (b.demandPhysical > a.demandPhysical ? 1 : -1))
    .map((i) => translate(i.name));
  const newchartdata = [{ name: translate('demand'), data: demandarray }];
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: '#F15B46'
            },
            {
              from: -45,
              to: 0,
              color: '#FEB019'
            }
          ]
        },
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: 'MSEK'
      },
      labels: {
        formatter(y) {
          return y.toFixed(1);
        }
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="bar" series={newchartdata} options={chartOptions} height={height} />
    </ChartWrapperStyle>
  );
}
