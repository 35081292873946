import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Stack } from '@mui/material';
//
import { fPercent } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../charts';
import LoadingScreen from '../../LoadingScreen';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const QNO = ['Q1', 'Q2', 'Q3', 'Q4'];
const MONTHSNO = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const YEAR = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029];
const YEARSTR = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029'];
const GRAPH_TYP = ['index', 'yearly_growth', 'digital_share', 'physical_share'];

export default function GoalLine({ prognosis }) {
  const [seriesData, setSeriesData] = useState(2022);
  const { translate } = useLocales();
  const [graphType, setGraphType] = useState('index');
  const today = new Date();
  const year = today.getFullYear();
  if (!prognosis || prognosis.length < 1) return <LoadingScreen />;
  const data = prognosis
    .filter((s) => s.space_type !== 'Not service')
    .map((spacetype) => {
      const fc = spacetype.forecasts;
      const indexValue = YEARSTR.map((month, index) => Math.round(fc.find((f) => f.year === month).index) || 100);
      const growthValue = YEARSTR.map((month, index) => fc.find((f) => f.year === month).yearly_growth || 0);
      const physicalValue = YEARSTR.map((month, index) => fc.find((f) => f.year === month).physical_share);
      const digitalValue = YEARSTR.map((month, index) => fc.find((f) => f.year === month).digital_share);
      return {
        index: { name: translate(spacetype.space_type), data: indexValue },
        yearly_growth: { name: translate(spacetype.space_type), data: growthValue },
        phyiscal_share: { name: translate(spacetype.space_type), data: physicalValue },
        digital_share: { name: translate(spacetype.space_type), data: digitalValue }
      };
    });
  const indexData = data.map((sp) => sp.index);
  const growthData = data.map((sp) => sp.yearly_growth);
  const physicalData = data.map((sp) => sp.physical_share);
  const digitalData = data.map((sp) => sp.digital_share);
  const chartData = [
    {
      type: 'index',
      data: indexData
    },
    {
      type: 'yearly_growth',
      data: growthData
    },
    {
      type: 'physical_share',
      data: physicalData
    },
    {
      type: 'digital_share',
      data: digitalData
    }
  ];

  const handleChangeGraphType = (event) => {
    setGraphType(event.target.value);
  };
  const chartOptions = merge(BaseOptionChart(), {
    xaxis: { categories: YEAR },
    legend: {
      onItemClick: {
        toggleDataSeries: true
      }
    }
  });
  return (
    <Card>
      <CardHeader
        title={translate('Forecast')}
        action={
          <Stack direction="row" spacing={0}>
            <TextField
              select
              fullWidth
              value={graphType}
              SelectProps={{ native: true }}
              onChange={handleChangeGraphType}
              sx={{
                '& fieldset': { border: '0 !important' },
                '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
              }}
            >
              {GRAPH_TYP.map((option) => (
                <option key={option} value={option}>
                  {translate(option)}
                </option>
              ))}
            </TextField>
          </Stack>
        }
      />

      {chartData.map((item) => (
        <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.type === graphType && (
            <ReactApexChart type="line" series={item.data} options={chartOptions} height={565} />
          )}
        </Box>
      ))}
    </Card>
  );
}
