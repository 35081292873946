// routes
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import 'react-internet-meter/dist/index.css';
import NoInternetConnection from './NoInternet';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import ThemeLocalization from './components/ThemeLocalization';
import ThemePrimaryColor from './components/ThemePrimaryColor';
// import AssistantLight from './pages/dashboard/AssistantLight';
import useAuth from './hooks/useAuth';
import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { getJourneys } from './redux/slices/deal';
import { useDispatch } from 'react-redux';
import { getAllPlaces, getMyPlaces, getSamplePlaces } from './redux/slices/place';
import { Box, Fab, Card, Button } from '@mui/material';
import { Insights } from '@mui/icons-material';

function App() {
  const { isInitialized } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useAuth();
  const [isChat, setIsChat] = useState(false);
  const [wifiSpeed, setwifiSpeed] = useState("Checking ... ");
  //   {wifiSpeed > 1000 ? <Router /> : <SlowConnection />}
  useEffect(() => {
    if (!user.id) return;
     dispatch(getJourneys());
    if (user?.internal) {
      // console.log('user', user);
      console.log('getting all places');
      // dispatch(getMyPlaces(user.companyHubspot));
      dispatch(getAllPlaces());
    } else if (!user?.companyHubspot)  {
      dispatch(getSamplePlaces());
    } else {
      console.log('getting my places');
      dispatch(getMyPlaces(user.companyHubspot));
    }
  }, [user, dispatch]);
  

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <ScrollToTop />

            <NoInternetConnection>
              <ReactInternetSpeedMeter
                txtSubHeading="Internet is too slow"
                outputType="modal"
                customClassName={null}
                txtMainHeading="Slow connection..."
                pingInterval="4000000" // sec
                thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
                threshold={1}
                imageUrl="https://media.nationalgeographic.org/assets/photos/000/267/26734_c25-0-964-743_r800x633.jpg"
                downloadSize="1781287"  //bytes
                callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed    down ${speed}`)}
                callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
              />
              {isInitialized ? <Router /> : <LoadingScreen />}
              {/*
              <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                {isChat ? (
                  <Card sx={{ height: '60vh', width: '50vw' }}>
                    <AssistantLight />
                    <Button onClick={() => setIsChat(false)} sx={{ position: 'absolute', bottom: 20, right: 20 }}>
                      Stäng
                    </Button>
                  </Card>
                ) : (
                  <Fab variant="extended" onClick={() => setIsChat(true)}>
                    <Insights sx={{ mr: 1 }} />
                    Hanna AI
                  </Fab>
                )}
              </Box>
              */ }
            </NoInternetConnection>
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig >
  );
}

export default App;
