import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Avatar, Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  width: '15vw',
  height: '20vh',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 24,
  color: 'inherit',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const ScoreStyle = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '15vw',
  height: '20vh',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  traffic: PropTypes.any.isRequired,
  catchment: PropTypes.any.isRequired
};

export default function PotentialMap(props) {
  const { translate } = useLocales();
  const { place, traffic, catchment, onSquareClicked, trafficfigure } = props;
  const map = useRef(null);
  const [surroundingVisitors, setSurroundingVisitors] = useState(trafficfigure);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const cleanTraffic = traffic.map((square) => {
    const geom = square.geo.replace('POLYGON((', '').replace('))', '');
    const splitgeom = geom.split(', ');
    const splittwicegeom = splitgeom.map((coordinates) => {
      const stringarray = coordinates.split(' ');
      const float0 = parseFloat(stringarray[0]);
      const float1 = parseFloat(stringarray[1]);
      const floatarray = [float0, float1];
      return floatarray;
    });
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline Maine.
        coordinates: [splittwicegeom]
      },
      properties: {
        visitors: square.trips
      }
    };
    return feature;
  });

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 13
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!map.current || !cleanTrafficNew || cleanTrafficNew.length === 0) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: cleanTrafficNew
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': {
              property: 'visitors',
              stops: [
                [20, '#30D5C8'],
                [50, '#FFFF00'],
                [100, '#FFD700'],
                [500, '#8b0000']
              ]
            }, // blue color fill
            'fill-opacity': 0.6
          }
        });
        map.current.addLayer({
          id: 'trafficline',
          type: 'line',
          source: 'maine', // reference the data source
          layout: { visibility: 'none' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }

      const layers = ['20 - 50', '50 - 100', '100 - 500', '100+'];
      const colors = ['#30D5C8', '#FFFF00', '#FFD700', '#8b0000'];
      const legend = document.getElementById('legend');
      layers.forEach((layer, i) => {
        if (i < 5) {
          const color = colors[i];
          const item = document.createElement('div');
          const key = document.createElement('span');
          key.style = 'display:inline-block;border-radius:20%;width:10px;height:10px;margin-right:5px;';
          key.style.backgroundColor = color;
          const value = document.createElement('span');
          value.innerHTML = `${layer}`;
          item.appendChild(key);
          item.appendChild(value);
          legend.appendChild(item);
        }
      });
      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        setSurroundingVisitors(Math.round(states[0].properties.visitors));
      });
      map.current.on('click', 'maine', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        console.log(states);
        const clicked = states[0].properties;
        console.log(clicked);
        onSquareClicked(clicked);
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });

  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const cleanTrafficNew = cleanTraffic;

  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Card sx={{ height: '60vh', weight: '100%' }}>
      <Box>
        <div id="map" style={{ width: '100%', height: '60vh' }} />

        <div
          id="legend"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            background: '#fff',
            'margin-right': 10,
            overflow: 'auto',
            'border-radius': 15,
            padding: 10,
            'line-height': 18,
            height: 120,
            'margin-bottom': 10,
            width: 140
          }}
        />
      </Box>
    </Card>
  );
}
