import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import closeFill from '@iconify/icons-eva/close-fill';
import editOutline from '@iconify/icons-eva/edit-outline';
import saveOutline from '@iconify/icons-eva/save-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip
} from '@mui/material';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import LoadingScreenSmall from '../../LoadingScreenSmall';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PotentialMap({ place, onSave, modeprev, minutesprev, loading, button, onSwitch, catchment, mapCh, onCatchment, onLoad }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!catchment) return;
    // map.current.getSource('iso').setData(catchment);
  }, [catchment]);

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {

      map.current.on('style.load', () => {
        map.current.setConfigProperty('basemap', 'lightPreset', 'Night');
      });
      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': '#53758E',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
        );
      }

      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#AF452B',
            'line-width': 2
          }
        });
      }



      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
      getIso();
    });
  });

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  console.log(mode, minuter);
  let profile = mode;
  let minutes = minuter;
  let colors = '53758E';
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });

  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      console.log(query, data);
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
      const coordinatesArray = data.features[0].geometry.coordinates[0];
      onCatchment(coordinatesArray, profile, minutes);
      onLoad();
      // onCatchment(coordinatesArray);
    });
  }

  const onChange = (e) => {
    profile = e.target.value;
    setMode(e.target.value);
    getIso();
  };

  const onChangeDuration = (e) => {
    minutes = e.target.value;
    setMinuter(e.target.value);
    if (e.target.value === '5%2C10%2C15%2C20') {
      colors = '08519c%2C3182bd%2C6baed6%2Cbdd7e7';
    } else if (e.target.value === '5') {
      colors = 'B7C8D5';
    } else if (e.target.value === '10') {
      colors = 'B7C8D5';
    } else {
      colors = 'B7C8D5';
    }
    getIso();
  };

  return (
    <>
      <div id="map" style={{ width: '100%', height: '70vh' }} />
      <Tooltip title={translate('drawPolygon')}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: '#fff',
            marginLeft: 1,
            marginTop: 1,
            overflow: 'auto'
          }}
        >
          <Icon icon={editOutline} onClick={onSwitch} />
        </IconButton>
      </Tooltip>

      <Card
        sx={{
          display: 'flex',
          position: 'absolute',
          borderRadius: 1,
          zIndex: 9,
          top: 0,
          left: '30%',
          right: '30%',
          background: '#fff',
          justifyContent: 'center',
          'margin-top': 10
        }}
      >
        <Grid container spacing={2} sx={{ alignItems: 'center', p: 2 }}>
          <Grid item xs={12} md={6}>
            <FormControl
              component="fieldset"
              justifyContent="center"
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <FormLabel component="legend">{translate('travelMode')}</FormLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={translate('travelMode')}
                value={mode}
                sx={{ height: '100%', variant: 'h3' }}
                onChange={onChange}
              >
                <MenuItem value={'walking'}>
                  {translate('walking')}
                </MenuItem>
                <MenuItem value={'cycling'}>
                  {translate('cycling')}
                </MenuItem>
                <MenuItem value={'driving'}>
                  {translate('driving')}
                </MenuItem>
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormLabel component="legend">{translate('minutes')}:</FormLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label={translate('minutes')}
                value={minuter}
                sx={{ height: '100%', variant: 'h3' }}
                onChange={onChangeDuration}
              >
                <MenuItem value={'5'}>
                  5
                </MenuItem>
                <MenuItem value={'10'}>
                  10
                </MenuItem>
                <MenuItem value={'15'}>
                  15
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {button !== 'icon' ? (
            <Grid item xs={12}>
              <LoadingButton fullWidth onClick={onSave} variant="contained" size="large">
                {translate('next')}
              </LoadingButton>
            </Grid>
          ) : (
            <>
              {loading ? (
                <LoadingScreenSmall />
              ) : (
                <Stack spacing={2} direction="row">
                  <IconButton>
                    <Icon icon={saveOutline} onClick={onSave} color="#008f00" />
                  </IconButton>
                  <IconButton>
                    <Icon icon={closeFill} color="#AF452B" />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Grid>
      </Card>
    </>
  );
}
