import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {
  Card
} from '@mui/material';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PotentialMap({ place, modeprev, minutesprev }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const array = [];
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'catchmentcmap', // container id
      // style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('style.load', () => {
      map.current.setConfigProperty('basemap', 'lightPreset', 'dawn');
      map.setConfigProperty('basemap', 'showTransitLabels', true);
    });
    map.current.on('load', () => {

      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }
      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': ['get', 'fillColor']
              ,
              'fill-opacity': 0.3
            }
          }
        );
      }
      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 2
          }
        });
      }


      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
      getIso();
    });
  });

  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  let profile = mode;
  let minutes = minuter;
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });
  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&generalize=100&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
    });
  }

  return (
    <Card sx={{ borderRadius: 1 }}>
      <div id="catchmentcmap" style={{ width: '100%', height: '70vh' }} />
    </Card>
  );
}
