import PropTypes from 'prop-types';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

AboutRadar.propTypes = {
  industrydata: PropTypes.array
};
// ----------------------------------------------------------------------

export default function AboutRadar({ industrydata }) {
  const theme = useTheme();
  const filteredData = industrydata.filter(
    (i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office'
  );
  if (!industrydata || industrydata.length < 1) return null;
  const demandarray = filteredData.map((i) => Math.round(i.demandTurnover / 100000) / 10);
  const supplyarray = filteredData.map((i) => Math.round(i.supplyTurnover / 100000) / 10);
  const opparray = filteredData.map((i) => Math.max(Math.round(i.oppRev / 100000) / 10, 0));
  const categories = filteredData.map((i) => i.name);
  const newchartdata = [
    { name: 'Demand', data: demandarray },
    { name: 'Supply', data: supplyarray },
    { name: 'Opportunity', data: opparray }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: 2 },
    fill: { opacity: 0.48 },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="radar" series={newchartdata} options={chartOptions} height={340} />
    </ChartWrapperStyle>
  );
}
