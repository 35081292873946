import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// material
import closeFill from '@iconify/icons-eva/close-fill';
import saveOutline from '@iconify/icons-eva/save-outline';
import {
  FormLabel,
  FormControl,
  Box,
  Stack,
  IconButton,
  Card,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import $ from 'jquery';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

const GeoJSON = require('geojson');
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  spaces: PropTypes.any.isRequired
};

export default function PotentialMap(props) {
  const { place, spaces, catchment, onSave } = props;
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [array, setArray] = useState([]);
  const [minuter, setMinuter] = useState('5');
  const [mode, setMode] = useState('walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
  });

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': '#5a3fc0',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
        );
      }

      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }

      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [polyg]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }

      const sourceIdSpaces = 'spaces';
      const sourcespaces = map.current.getSource(sourceIdSpaces);
      if (!sourcespaces) {
        map.current.addSource('spaces', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] })
          }
        });
      }

      if (!map.current.getLayer('places')) {
        map.current.addLayer({
          id: 'places',
          type: 'circle',
          source: 'spaces',
          paint: {
            'circle-color': '#AE452B',
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
      // Initialize the marker at the query coordinates
      const popup = new mapboxgl.Popup();
      // Make the API call
      getIso();
      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
        if (!features.length) {
          popup.remove();
          return;
        }
        const feature = features[0];
        popup
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="/properties">
              ${feature.properties.name}
              </a></h5><p>
              ${feature.properties.city_name}
              </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });

    map.current.on('click', 'places', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
      if (!features.length) {
        return;
      }
      const feature = features[0];
      console.log(feature);
    });
  });

  useEffect(() => {
    const arr = array;
    const url = `https://plejsapp-api.herokuapp.com/api/v1/places/findincatchment/${place.id}`;
    const body = {
      catchment: JSON.stringify(arr)
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        const spc = response;
        const url = `https://plejsapp-api.herokuapp.com/api/v1/places/findpincatchment/${place.id}`;
        const body = {
          catchment: JSON.stringify(arr)
        };
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Network response was not ok.');
          })

          .then((response) => {
            sendUnitsToMap(spc, response);
          })
          .catch((error) => console.log(error.message));
      })
      .catch((error) => console.log(error.message));
  }, [array]);

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';

  function sendUnitsToMap(sp, pr) {
    if (map.current) {
      const sourceIdspace = 'spacesc';
      const sourcespace = map.current.getSource(sourceIdspace);
      if (sourcespace && sp) {
        const spacedata = GeoJSON.parse(sp, { Point: ['latitude', 'longitude'] });
        sourcespace.setData(spacedata);
      }

      const sourceIdprop = 'propertiesc';
      const sourceprop = map.current.getSource(sourceIdprop);
      if (sourceprop && pr) {
        sourceprop.setData(GeoJSON.parse(pr, { Point: ['latitude', 'longitude'] }));
      }
    }
    if (sp || pr) {
      catchment(sp, pr);
    }
  }

  let profile = mode;
  let minutes = minuter;
  let colors = '08519c';
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const storezgeojson = GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] });
  if (map.current && map.current.getSource('store')) {
    map.current.getSource('store').setData(storezgeojson);
  }
  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&contours_colors=${colors}&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
      const coordinatesArray = data.features[0].geometry.coordinates[0];
      setArray(coordinatesArray);
      props.onCatchment(coordinatesArray);
      props.onLoad();
      // onCatchment(coordinatesArray);
    });
  }

  const onChange = (e) => {
    profile = e.target.value;
    setMode(e.target.value);
    getIso();
  };

  const onChangeDuration = (e) => {
    minutes = e.target.value;
    setMinuter(e.target.value);
    if (e.target.value === '5%2C10%2C15%2C20') {
      colors = '08519c%2C3182bd%2C6baed6%2Cbdd7e7';
    } else if (e.target.value === '5') {
      colors = '08519';
    } else if (e.target.value === '10') {
      colors = '3182bd';
    } else {
      colors = 'bdd7e7';
    }
    getIso();
  };

  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <Stack sx={{ pt: 3 }}>
        <Box sx={{ justifyContent: 'center', width: '100%', mx: 3 }}>
          <Stack direction="row" spacing={1} sx={{ width: '100%', height: '100%' }}>
            <FormControl
              component="fieldset"
              justifyContent="center"
              sx={{ width: '40%', display: 'flex', justifyContent: 'center' }}
            >
              <FormLabel component="legend">Travel Mode:</FormLabel>
              <RadioGroup row aria-label="mode" name="mode-radio-group" value={mode} onChange={onChange}>
                <FormControlLabel value="walking" control={<Radio />} label="Walking" />
                <FormControlLabel value="cycling" control={<Radio />} label="Cycling" />
                <FormControlLabel value="driving" control={<Radio />} label="Driving" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" sx={{ width: '40%' }}>
              <FormLabel component="legend">Minutes:</FormLabel>
              <RadioGroup
                row
                aria-label="minutes"
                name="minutes-radio-group"
                value={minuter}
                onChange={onChangeDuration}
              >
                <FormControlLabel value="5" control={<Radio />} label="5" />
                <FormControlLabel value="10" control={<Radio />} label="10" />
                <FormControlLabel value="15" control={<Radio />} label="15" />
              </RadioGroup>
            </FormControl>
            <IconButton onClick={onSave}>
              <Icon icon={saveOutline} color="#008f00" />
            </IconButton>
            <IconButton>
              <Icon icon={closeFill} color="#AF452B" />
            </IconButton>
          </Stack>
        </Box>
        <Box>
          <div id="map" style={{ width: '100%', height: '90vh' }} />
        </Box>
      </Stack>
    </Card>
  );
}
