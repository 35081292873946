import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { map } from 'lodash';

import axios from 'axios';
import { dispatch } from '../store';

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  }
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      console.log('board', board);
      const order = board.columns.map((col) => col.displayOrder);
      const cards = objFromArray(board.cards);
      const columns = objFromArray(board.columns);
      console.log('columns', columns);
      console.log('cards', cards);
      const columnOrder = order;
      state.board = {
        cards,
        columns,
        columnOrder
      };
    },

    persistCard(state, action, pipeline_id) {
      const columns = action.payload.columns;
      const card = action.payload.card;
      console.log('card', card, state.board.cards);
      console.log('columns', columns, state.board.columns);
      state.board.cards[card.id] = card
      state.board.columns = columns;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(columns, cards) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = { cards, columns };
      dispatch(slice.actions.getBoardSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistCard(newDeal, columns, pipeline_id) {
  console.log(newDeal, columns);
  return async (dispatch) => {
    try {
      console.log(newDeal.id);
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/deals/update/${parseInt(newDeal.id)}`, {
        deal: newDeal,
        dealstage_id: newDeal.dealstage_id,
        id: parseInt(newDeal.id),
        pipeline_id
      });
      console.log('response', response);
      dispatch(slice.actions.persistCard({columns, card: response.data, pipeline_id}));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
