import { LineChart } from '@mui/x-charts/LineChart';
import { Stack, Typography, Card } from '@mui/material';

const xLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function DashedLineChart({ data, datac }) {
  console.log('trend data', data);
  const dat = new Date();
  const thisyear = dat.getFullYear();
  const thismonthact = dat.getMonth();
  const ty = data.filter((d) => d.year === thisyear);
  const thismonth = ty.sort((a, b) => (b.month > a.month ? 1 : -1))[0].month;
  console.log('this month', thismonth);
  const ly = data.filter((d) => d.year === thisyear - 1);
  const l2y = data.filter((d) => d.year === thisyear - 2);
  const l3y = data.filter((d) => d.year === thisyear - 3);
  const l4y = data.filter((d) => d.year === thisyear - 4);
  const l5y = data.filter((d) => d.year === thisyear - 5);

  const tyc = datac.filter((d) => d.year === thisyear);
  const lyc = datac.filter((d) => d.year === thisyear - 1);

  const ety = xLabels.map((d, idx) => ty.find((t) => t.month === idx)?.value || null);
  const ely = xLabels.map((d, idx) => ly.find((t) => t.month === idx)?.value || null);
  const el2y = xLabels.map((d, idx) => l2y.find((t) => t.month === idx)?.value || null);
  const el3y = xLabels.map((d, idx) => l3y.find((t) => t.month === idx)?.value || null);
  const el4y = xLabels.map((d, idx) => l4y.find((t) => t.month === idx)?.value || null);
  const el5y = xLabels.map((d, idx) => l5y.find((t) => t.month === idx)?.value || null);

  const etyc = xLabels.map((d, idx) => tyc.find((t) => t.month === idx)?.value || null);
  const elyc = xLabels.map((d, idx) => lyc.find((t) => t.month === idx)?.value || null);

  const ffTm = data.find((d) => d.year === thisyear && d.month === thismonth).value;
  const ffTmL5y = data.find((d) => d.year === thisyear - 5 && d.month === thismonth).value || 0;
  const ffTaktIdx = Math.round(ffTm / ffTmL5y * 100);
  return (
    <Stack>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>

        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {ffTaktIdx || 0}
          </Typography>

          <Typography variant='subtitle2'>
            Covid-Index
          </Typography>
          <Typography variant='caption'>
            {`(${xLabels[thismonth]} ${thisyear} v. ${xLabels[thismonth]} ${thisyear - 5})`}
          </Typography>
        </Card>
      </Stack>

      <LineChart
        height={400}
        series={datac.length ? ([
          
          {
            data: ely, label: '2023', id: 'pvId',
            connectNulls: false
          },
          {
            data: ety, label: '2024', id: 'uvId',
            connectNulls: false
          },
          {
            data: el2y, label: '2022', id: 'uvId2',
            connectNulls: false
          },
          {
            data: el3y, label: ' 2021', id: 'uvId3',
            connectNulls: false
          },
          {
            data: el4y, label: ' 2020', id: 'uvId4',
            connectNulls: false
          },
          {
            data: el5y, label: ' 2019', id: 'uvId5',
            connectNulls: false
          },

        ]
        ) : ([

          {
            data: ety, label: '2024', id: 'uvId',
            connectNulls: false
          },
          {
            data: ely, label: '2023', id: 'pvId',
            connectNulls: false
          },
          {
            data: el2y, label: '2022', id: 'uvId2',
            connectNulls: false
          },
          {
            data: el3y, label: ' 2021', id: 'uvId3',
            connectNulls: false
          },
          {
            data: el4y, label: ' 2020', id: 'uvId4',
            connectNulls: false
          },
          {
            data: el5y, label: ' 2019', id: 'uvId5',
            connectNulls: false
          },
        ]
        )}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        sx={{
          '.MuiLineElement-root, .MuiMarkElement-root': {
            strokeWidtharray: '1 4',
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-uvIdc': {
            strokeDasharray: '4 4 4 1',
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '4 1',
          },
          '.MuiLineElement-series-uvId2': {
            strokeDasharray: '4 1',
          },
          '.MuiLineElement-series-uvId3': {
            strokeDasharray: '4 1',
          },
          '.MuiLineElement-series-uvId4': {
            strokeDasharray: '4 1',
          },
          '.MuiLineElement-series-uvId5': {
            strokeDasharray: '4 1',
          },
          '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
            fill: '#fff',
          },
          '& .MuiMarkElement-highlighted': {
            stroke: 'none',
          },
        }}
      />
    </Stack>
  );
}