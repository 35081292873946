// material
import { Box, Table, TableRow, TableBody, TableCell, TableHead, TableContainer } from '@mui/material';
import { useEffect, useState } from 'react';

import Scrollbar from '../../Scrollbar';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------
export default function PotentialTable({ forecast, type }) {
  const { translate } = useLocales();
  const [values, setValues] = useState([]);
  const [typeText, setTypeText] = useState('Demand');
  console.log('type forecast', type, forecast);

  useEffect(() => {
    if (type === 3) {
      const array = forecast
        .industrydata
        .filter((i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office')
        .sort((a, b) => (a.demandPhysical < b.demandPhysical ? 1 : -1));
      const fixedArray = array.map((i) => {
        const fixed = { id: i.id, name: i.name, value: i.demand };
        return fixed;
      });
      setValues(fixedArray);
      setTypeText('Demand');
    } else if (type === 5) {
      const array = forecast
        .industrydata
        .filter((i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office')
        .sort((a, b) => (a.demandPhysical < b.demandPhysical ? 1 : -1));
      const fixedArray = array.map((i) => {
        const fixed = { id: i.id, name: i.name, value: i.supply };
        return fixed;
      });
      setValues(fixedArray);
      setTypeText('Supply');
    } else if (type === 6){
      const array = forecast
        .industrydata
        .filter((i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office')
        .sort((a, b) => (a.demandPhysical < b.demandPhysical ? 1 : -1));
      const fixedArray = array.map((i) => {
        const fixed = { id: i.id, name: i.name, value: i.demand - i.supply || 0 };
        return fixed;
      });
      setValues(fixedArray);
      setTypeText('Retail Potential');
    } else {
      const array = forecast
        .industrydata
        .filter((i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office')
        .sort((a, b) => (a.demandPhysical < b.demandPhysical ? 1 : -1));
      const fixedArray = array.map((i) => {
        const fixed = { id: i.id, name: i.name, value: i.demandPhysical || 0 };
        return fixed;
      });
      setValues(fixedArray);
      setTypeText('Efterfrågan (MSEK)');
    }
  }, [forecast]);
  if (!forecast) return null;

  return (
    <Box sx={{ width: '85%' }}>
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate('industry')}</TableCell>
                <TableCell>{translate(typeText)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{translate(row.name)}</TableCell>
                  <TableCell>{Math.round(row.value / 100000) / 10}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Box>
  );
}
