import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import Geocode from 'react-geocode';
// material
import { Icon } from '@iconify/react';
import syncOutline from '@iconify/icons-eva/sync-outline';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Box, IconButton, TextField, Snackbar, Alert } from '@mui/material';
// utils
import fakeRequest from '../../../utils/fakeRequest';
import useLocales from '../../../hooks/useLocales';

//
import NewPlaceMap from './NewPlaceMap';
import { geoConfig } from '../../../config';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------
NewPlaceForm.propTypes = {
  onPlace: PropTypes.func
};

export default function NewPlaceForm({ onPlace }) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [lng, setLng] = useState(0.0);
  const [lat, setLat] = useState(0.0);
  const [polygons] = useState([]);
  const [geojson, setGeojson] = useState([]);
  const [indx] = useState(0);
  const [name, setName] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [zip, setZip] = useState('');
  const [countyname, setCountyname] = useState('');
  const [countryname, setCountryname] = useState('');

  useEffect(() => {
    if (polygons.length < 1) return;
    const array = [];
    for (let t = 0; t < polygons[indx].length; t += 1) {
      const polygon = polygons[indx];
      const coordi = polygon[t];
      const giveValue = function (mykey) {
        return coordi[mykey];
      };
      const lat = parseFloat(giveValue('lat'));
      const lon = parseFloat(giveValue('lon'));
      array.push([lon, lat]);
    }
    const polt = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [array]
          }
        }
      ]
    };
    setGeojson(polt);
  }, [polygons, indx]);

  const handleMarkerDragged = (lngat) => {
    setLng(lngat.lng);
    setLat(lngat.lat);
  };

  const handleName = (name, city) => {
    if (name.length === 0 || city.length === 0) return;
    Geocode.fromAddress(name + city)
      .then((response) => {
        const addcomp = response.results[0].address_components;
        const countrytrial = addcomp.filter((comp) => comp.types[0] === 'country');
        const zip = addcomp.filter((comp) => comp.types[0] === 'postal_code');
        const countytrial = addcomp.filter((comp) => comp.types[0] === 'administrative_area_level_1');
        setCountyname(countytrial[0].long_name);
        setZip(zip[0].long_name.replace(/ /g, ''));
        setCountryname(countrytrial[0].long_name);
        const { lat, lng } = response.results[0].geometry.location;
        setLng(lng);
        setLat(lat);
      })
      .catch(() => setOpenSnack(true));
  };

  const NewPlaceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    city: Yup.string().required('City is required'),
    description: Yup.string().required('Description is required'),
    cover: Yup.mixed()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      city: '',
      cover: null,
      tags: ['Place'],
      publish: true,
      drawn: false,
      metaDescription: ''
    },
    validationSchema: NewPlaceSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await fakeRequest(500);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleBlur = (e) => {
    if (values.title && values.city.length > 2) {
      setName(values.title);
      handleName(values.title, values.city);
      if (e.keyCode === 13) {
        handleClick();
      }
    }
  };
  const handleClick = () => {
    const placc = {
      name: values.title,
      city_name: values.city,
      latitude: lat,
      longitude: lng,
      user_id: 1,
      county_id: 1,
      country_name: countryname,
      county_name: countyname,
      zip
    };
    onPlace(placc);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
          {translate('noplace')}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box sx={{ p: 3 }}>
                <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      label={translate('name')}
                      onKeyUp={handleBlur}
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      label={translate('city')}
                      onKeyUp={handleBlur}
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {name ? (
                      <Stack direction="row" spacing={3}>
                        <LoadingButton onClick={handleClick} variant="contained" size="large" loading={isSubmitting}>
                          {translate('next')}
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <LoadingButton
                        fullWidth
                        onClick={handleBlur}
                        variant="outlined"
                        size="large"
                        loading={isSubmitting}
                      >
                        {translate('search')}
                      </LoadingButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <div>
                <Box sx={{ height: '70vh' }}>
                  <NewPlaceMap
                    coords={[lng, lat]}
                    drawn={values.drawn}
                    geojsonpoly={geojson}
                    onMarkerDragged={handleMarkerDragged}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
