import PropTypes from 'prop-types';
// material

import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent, Box } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.secondary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string
};

export default function AppWelcome({ displayName, onFw, onBack }) {
  const { translate } = useLocales();
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 5, lg: 10 },
          color: 'black',
          width: '100%'
        }}
      >
        <Typography gutterBottom variant="h4">
          {translate('Hall')},
          <br /> {!displayName ? '...' : displayName}!
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto' }}>
          {translate('Looking to start your analysis?')}
        </Typography>
        <Card>
          <Box sx ={{ height: '50vh' }} component="img" src="/static/home/introanalysis.png" />
        </Card>
      </CardContent>
    </RootStyle>
  );
}
