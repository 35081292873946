import { Icon } from '@iconify/react';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
import useLocales from '../../hooks/useLocales';

//
import LogoFooter from '../../components/LogoFooter';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'Instagram', icon: instagramFilled, href: 'https://www.instagram.com/mind_realestate' },
  { name: 'Linkedin', icon: linkedinFill, href: 'https://www.linkedin.com/company/mind-realestate' }
];

const LINKS = [
  {
    headline: 'Mind Real Estate',
    children: [
      { name: 'aboutHeroHeading', href: PATH_PAGE.about },
      { name: 'contactButton', href: PATH_PAGE.contact }
    ]
  },
  {
    headline: 'footerContactHeading',
    children: [
      { name: 'info@mindrealestate.se', href: 'mailto:info@mindrealestate.se' },
      { name: 'cv@mindrealestate.se', href: 'mailto:cv@mindrealestate.se' },
      { name: 'Grev Turegatan 30 @ Helio GT30, 114 38 Stockholm', href: '#' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate } = useLocales();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <LogoFooter sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate('footerMission')}
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton
                  key={social.name}
                  color="primary"
                  sx={{ p: 1 }}
                  href={social.href}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={social.name}
                >
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              <Stack key="mre" spacing={2}>
                <Typography variant="subtitle1"> Mind Real Estate </Typography>
                <Stack key="mre2" spacing={0}>
                  {LINKS[0].children.map((link) => (
                    <Link
                      to={link.href}
                      key={translate(link.name)}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {translate(link.name)}
                    </Link>
                  ))}
                </Stack>
              </Stack>
              <Stack key="kontakt" spacing={2}>
                <Typography component="p" variant="subtitle1">
                  {translate('footerContactHeading')}
                </Typography>
                <Stack key="kontaktunder" spacing={0}>
                  <Link
                    href="mailto:info@mindrealestate.se"
                    key="info@mindrealestate.se"
                    target="_blank"
                    rel="noreferrer"
                    color="inherit"
                    variant="body2"
                    sx={{ display: 'block' }}
                  >
                    info@mindrealestate.se
                  </Link>
                  <Link
                    href="mailto:cv@mindrealestate.se"
                    target="_blank"
                    rel="noreferrer"
                    key="cv@mindrealestate.se"
                    color="inherit"
                    variant="body2"
                    sx={{ display: 'block' }}
                  >
                    cv@mindrealestate.se
                  </Link>
                  <Link
                    href="https://goo.gl/maps/noDrgqKsvqfZsToF6"
                    target="_blank"
                    rel="noreferrer"
                    key="gmaps"
                    color="inherit"
                    variant="body2"
                    sx={{ display: 'block' }}
                  >
                    Malmskillnadsgatan 36, Arena Sergel, 111 57 Stockholm
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2023. Mind Real Estate
        </Typography>
      </Container>
    </RootStyle>
  );
}
