import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

import useSettings from '../hooks/useSettings';

import logo from './loho.png';
// import logo from './logoneow.png';
//import logo from './logomaie.png';
import logoneg from './lohoinv.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

const APP_BAR_MOBILE = 64;
export default function Logo({ sx }) {
  const { themeMode } = useSettings();

  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: APP_BAR_MOBILE - 4, ...sx, py: 1 }}>
      {themeMode === 'light' ? <img src={logo} alt="Logo" /> : <img src={logoneg} alt="Logo" />}
    </Box>
  );
}
