import { LineChart } from '@mui/x-charts/LineChart';
import { Stack, Typography, Card, styled, ListItemIcon, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import SvgIconStyle from '../../SvgIconStyle';
const ICONS = {
  sun: <Icon icon="fluent:weather-haze-20-regular" width={'100%'} height={'100%'} />,
  cloud: <Icon icon="fluent:weather-haze-20-regular" width={'100%'} height={'100%'} />,
  mix: <Icon icon="fluent:weather-haze-20-regular" width={'100%'} height={'100%'} />,
  rain: <Icon icon="fluent:weather-haze-20-regular" width={'100%'} height={'100%'} />
};
const xoldLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/yr/symbols/lightmode/svg/${weatherSymbolKeys[name]}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const weatherSymbolKeys = {
  clearsky_day: '01d',
  clearsky_night: '01n',
  clearsky_polartwilight: '01m',
  fair_day: '02d',
  fair_night: '02n',
  fair_polartwilight: '02m',
  partlycloudy_day: '03d',
  partlycloudy_night: '03n',
  partlycloudy_polartwilight: '03m',
  cloudy: '04',
  rainshowers_day: '05d',
  rainshowers_night: '05n',
  rainshowers_polartwilight: '05m',
  rainshowersandthunder_day: '06d',
  rainshowersandthunder_night: '06n',
  rainshowersandthunder_polartwilight: '06m',
  sleetshowers_day: '07d',
  sleetshowers_night: '07n',
  sleetshowers_polartwilight: '07m',
  snowshowers_day: '08d',
  snowshowers_night: '08n',
  snowshowers_polartwilight: '08m',
  rain: '09',
  heavyrain: '10',
  heavyrainandthunder: '11',
  sleet: '12',
  snow: '13',
  snowandthunder: '14',
  fog: '15',
  sleetshowersandthunder_day: '20d',
  sleetshowersandthunder_night: '20n',
  sleetshowersandthunder_polartwilight: '20m',
  snowshowersandthunder_day: '21d',
  snowshowersandthunder_night: '21n',
  snowshowersandthunder_polartwilight: '21m',
  rainandthunder: '22',
  sleetandthunder: '23',
  lightrainshowersandthunder_day: '24d',
  lightrainshowersandthunder_night: '24n',
  lightrainshowersandthunder_polartwilight: '24m',
  heavyrainshowersandthunder_day: '25d',
  heavyrainshowersandthunder_night: '25n',
  heavyrainshowersandthunder_polartwilight: '25m',
  lightssleetshowersandthunder_day: '26d',
  lightssleetshowersandthunder_night: '26n',
  lightssleetshowersandthunder_polartwilight: '26m',
  heavysleetshowersandthunder_day: '27d',
  heavysleetshowersandthunder_night: '27n',
  heavysleetshowersandthunder_polartwilight: '27m',
  lightssnowshowersandthunder_day: '28d',
  lightssnowshowersandthunder_night: '28n',
  lightssnowshowersandthunder_polartwilight: '28m',
  heavysnowshowersandthunder_day: '29d',
  heavysnowshowersandthunder_night: '29n',
  heavysnowshowersandthunder_polartwilight: '29m',
  lightrainandthunder: '30',
  lightsleetandthunder: '31',
  heavysleetandthunder: '32',
  lightsnowandthunder: '33',
  heavysnowandthunder: '34',
  lightrainshowers_day: '40d',
  lightrainshowers_night: '40n',
  lightrainshowers_polartwilight: '40m',
  heavyrainshowers_day: '41d',
  heavyrainshowers_night: '41n',
  heavyrainshowers_polartwilight: '41m',
  lightsleetshowers_day: '42d',
  lightsleetshowers_night: '42n',
  lightsleetshowers_polartwilight: '42m',
  heavysleetshowers_day: '43d',
  heavysleetshowers_night: '43n',
  heavysleetshowers_polartwilight: '43m',
  lightsnowshowers_day: '44d',
  lightsnowshowers_night: '44n',
  lightsnowshowers_polartwilight: '44m',
  heavysnowshowers_day: '45d',
  heavysnowshowers_night: '45n',
  heavysnowshowers_polartwilight: '45m',
  lightrain: '46',
  lightsleet: '47',
  heavysleet: '48',
  lightsnow: '49',
  heavysnow: '50',
};
const ListItemIconStyle = styled(ListItemIcon)({
  width: 44,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
export default function DashedLineChart({ data, rainyDays, rainyPercentage }) {
  console.log('trend data', data);
  const dat = new Date();
  const dats = new Date();
  const nextThreeDays = new Date(dats.setDate(dats.getDate() + 3));
  const xLabels = data.map((d) => new Date(d.time));
  const wind = data.map((d) => d.data.instant.details.wind_speed);
  const past = data.map((d) => {
    const datef = new Date(d.time);
    if (datef <= dat) {
      return d.data.instant.details.air_temperature
    } else {
      return null;
    };
  });

  const near = data.map((d) => {
    const datef = new Date(d.time);
    if (datef >= dat && datef <= nextThreeDays) {
      return d.data.instant.details.air_temperature
    } else {
      return null;
    };
  })
  console.log('past', past);
  console.log('near', near);
  const long = data.map((d) => {
    const datef = new Date(d.time);
    if (datef >= nextThreeDays) {
      return d.data.instant.details.air_temperature
    } else {
      return null;
    };
  })
  console.log('long', long);
  const ffTemp = data[0].data.instant.details.air_temperature;
  const ffWind = data[0].data.instant.details.wind_speed;
  const ffWeather = data[0].data.instant.details.wind_speed;
  return (
    <Stack>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant='h4'>
                {ffTemp || 0} C
              </Typography>

              <Typography variant='subtitle2'>
                Temperatur
              </Typography>

            </Box>
            <ListItemIconStyle>{getIcon(data[0].data.next_1_hours.summary.symbol_code) && getIcon(data[0].data.next_1_hours.summary.symbol_code)}</ListItemIconStyle>

          </Stack>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant='h4'>
                {Math.round(rainyDays) || 0} ({Math.round(rainyPercentage * 100)}%)
              </Typography>

              <Typography variant='subtitle2'>
                Dagar med nederbörd
              </Typography>

            </Box>

          </Stack>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {ffWind || 0} m/s
          </Typography>

          <Typography variant='subtitle2'>
            Vindhastighet
          </Typography>
        </Card>
      </Stack>

      <LineChart
        height={800}
        series={[
          {
            data: past, label: 'Faktisk', id: 'pvId',
            connectNulls: false, yAxisKey: 'leftAxisId'
          },

          {
            data: near, label: 'Prognos', id: 'uvId',
            connectNulls: false,
            yAxisKey: 'leftAxisId'
          },

          {
            data: long, label: 'Långtidsprognos', id: 'uvIdc',
            connectNulls: false, yAxisKey: 'leftAxisId'
          },

          {
            data: wind, label: 'Vind', id: 'wvid',
            connectNulls: false, yAxisKey: 'rightAxisId',
            showMark: false,
          }
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels, valueFormatter: (date) => `${date.toString().slice(0, 2)} ${date.toLocaleDateString().slice(0, -5)} - ${date.toLocaleTimeString().slice(0, -3)}`, }]}
        yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
        rightAxis="rightAxisId"
        sx={{
          '.MuiLineElement-root, .MuiMarkElement-root': {
            strokeWidtharray: '1 4',
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-wvId': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-uvIdc': {
            strokeDasharray: '4 4 4 1',
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '4 1',
          },
          '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
            fill: '#fff',
          },
          '& .MuiMarkElement-highlighted': {
            stroke: 'none',
          },
        }}
      />
    </Stack>
  );
}