import { LineChart } from '@mui/x-charts/LineChart';
import { Stack, Typography, Card } from '@mui/material';
import { BarChart } from '@mui/x-charts';

export default function TrendAges({ data, place }) {
  console.log('datap', data);

  function uniqueArray(a) {
    return [...new Set(a)];
  }
  const sorted = data.filter((d) => (d.year === 2023 && d.age !== 'uppgift saknas'));
  const adat = uniqueArray(sorted);

  const xLabels = adat.map((d) => d.age);
  const women = adat.filter((d) => d.gender === 'kvinnor').map((d) => d.population);
  const men = adat.filter((d) => d.gender === 'män').map((d) => d.population);

  const values = adat.map((d) => d.count);
  if (!xLabels.length || !values.length) return;
  return (
    <Stack>
      <Typography variant='h4'>
        {place.city_name}
      </Typography>
      <BarChart
        height={400}
        xAxis={[{
          scaleType: 'band', data: xLabels,
        }]}
        series={[
          {
            curve: "step",
            data: women,
            label: 'Female',
            id: 'pvId',
            area: true,
            stack: 'total',
            showMark: false
          },
          {
            curve: "step",
            data: men,
            label: 'Male',
            id: 'uvId',
            area: true,
            stack: 'total',
            showMark: false
          },
        ]}
      />
    </Stack>
  );
}