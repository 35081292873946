import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';
import logo from './Logo_hori.png';
//import logo from './logo_indivd.png';
import logoneg from './Logo_hori_neg.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const { themeMode } = useSettings();

  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 180, ...sx }}>
      {themeMode === 'light' ? <img src={logo} alt="Logo" /> : <img src={logo} alt="Logo" />}
    </Box>
  );
}
