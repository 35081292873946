import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  tenants: [],
  tenant: null
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getTenantsSuccess(state, action) {
      state.isLoading = false;
      state.tenants = action.payload;
    },

    // GET POST
    getTenantSuccess(state, action) {
      state.isLoading = false;
      state.tenant = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreTenants } = slice.actions;

// ----------------------------------------------------------------------

export function getAllTenants() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/tenants/index');
      dispatch(slice.actions.getTenantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTenant() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/tenants/index');
      dispatch(slice.actions.getTenantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTenants() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/tenants/index');
      dispatch(slice.actions.getTenantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTenant(tenant) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/tenants/update/${tenant.id}`, {
        body: { tenant }
      });
      console.log('Updated tenant: ', response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTenant(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/tenants/tenant', {
        params: { id, id2 }
      });
      console.log('Tenants fetched: ', response.data);
      dispatch(slice.actions.getTenantSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
