import { merge } from 'lodash';
import { useState, useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Stack } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
//
import { PotentialOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 480;
const LEGEND_HEIGHT = 0;
const PRICES = [
  { name: 'Budget', share_consumtion: 0.4 },
  { name: 'Mid', share_consumtion: 0.3 },
  { name: 'Premium', share_consumtion: 0.2 },
  { name: 'Luxury', share_consumtion: 0.1 }
];

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function TruthPotentialBar({ supplyArray, forecast, height, allPriceArray }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const [spaceType, setSpaceType] = useState('All');

  if (!supplyArray || supplyArray.length < 1) return;
  const spaceTypes = [...supplyArray.map((i) => i.name)];
  const categories = PRICES.map((i) => i.name);
  const allSqm = supplyArray.reduce((a, v) => (a += v.sqm), 0);
  const allTurnover = supplyArray.reduce((a, v) => (a += v.turnover), 0);
  const allCount = supplyArray.reduce((a, v) => (a += v.count), 0);
  const allSupply = PRICES.map((p) => {
    const data = supplyArray.map((sa) => sa.pricedata.find((pd) => pd.name === p.name));
    const sqm = data.filter((ps) => ps.name === p.name).reduce((a, v) => (a += v.sqm), 0);
    const turnover = data.filter((ps) => ps.name === p.name).reduce((a, v) => (a += v.turnover), 0);
    const count = data.filter((ps) => ps.name === p.name).reduce((a, v) => (a += v.count), 0);
    const pp = {
      name: p.name,
      sqm,
      turnover,
      count
    }
    return pp;
  });
  const allDemand = forecast.pricedata;
  const demandArray = forecast.industrydata;
  console.log(allSupply);

  const filteredDemand = spaceType !== 'All' ? demandArray.find((s) => s.name === spaceType).pricedata : allDemand;

  const filteredSupply = spaceType !== 'All' ? supplyArray.find((s) => s.name === spaceType).pricedata : allSupply;
  const potential = PRICES
    .map((i) => {
      const sup = filteredSupply.find((s) => s.name === i.name);
      const dem = filteredDemand.find((s) => s.name === i.name);
      const demTo = dem?.demand || 0;
      const supTo = sup?.turnover || 0;
      const potential = Math.max(demTo - supTo, 0);
      const data = { type: spaceType, name: i.name, supply: supTo, demand: demTo };
      return data;
    })
    .sort((a, b) => (b.potential > a.potential ? 1 : -1));
  const supply = potential.map((i) => Math.round(i.supply / 100000) / 10);
  const demand = potential.map((i) => Math.round(i.demand / 100000) / 10);
  const newchartdata = [
    { name: translate('demand'), data: demand },
    { name: translate('Supply'), data: supply }
  ];
  const chartOptions = merge(PotentialOptionChart(), {
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: '#F15B46'
            },
            {
              from: -45,
              to: 0,
              color: '#FEB019'
            }
          ]
        },
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: 'MSEK'
      },
      labels: {
        formatter(y) {
          return y.toFixed(1);
        }
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });

  const handleChangeSpaceType = (event) => {
    setSpaceType(event.target.value);
  };

  return (
    <Card>
      <CardHeader
        title={translate('Price points')}
        action={
          <Stack direction="row" spacing={0}>
            <TextField
              select
              fullWidth
              value={spaceType}
              SelectProps={{ native: true }}
              onChange={handleChangeSpaceType}
              sx={{
                '& fieldset': { border: '0 !important' },
                '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
              }}
            >
              {spaceTypes.map((option) => (
                <option key={option} value={option}>
                  {translate(option)}
                </option>
              ))}
            </TextField>
          </Stack>
        }
      />

      <Box key='a' sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={newchartdata} options={chartOptions} height={height} />
      </Box>
    </Card>
  );
}
