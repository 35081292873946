import { useState } from 'react';
import { Icon } from '@iconify/react';
import roundFullscreen from '@iconify/icons-ic/round-fullscreen';
import { IconButton } from '@mui/material';
import roundFullscreenExit from '@iconify/icons-ic/round-fullscreen-exit';
// material
import { MIconButton } from '../@material-extend';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function SettingFullscreenMenu({ isHome }) {
  const { themeMode } = useSettings();
  const [fullscreen, setFullscreen] = useState(false);
  if (!themeMode) return null;
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <IconButton
      size="large"
      variant="outlined"
      color="inherit"
      aria-label="Fullscreen toggle"
      onClick={toggleFullScreen}
      sx={{
        ml: 1,
        ...(isHome && { color: 'primary.main' }),
        ...(themeMode === 'light' && { color: 'text.primary' })
      }}
    >
      <Icon icon={fullscreen ? roundFullscreenExit : roundFullscreen} width={24} height={24} />
    </IconButton>
  );
}
