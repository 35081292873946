import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingLandlords: false,
  isLoadingTurnovers: false,
  error: false,
  newCompany: '',
  contact: '',
  newNote: '',
  landlord: '',
  companies: [],
  landlords: [],
  cpyneeds: [],
  matchingprojects: [],
  matchingneeds: [],
  matchingneedprojects: [],
  tenants: [],
  tenantsArea: [],
  needs: [],
  oldneeds: [],
  turnovers: [],
  matches: [],
  need: '',
  cpyactivities: [],
  cpymissions: [],
  contacts: [],
  data: null,
  company: null,
  index: 0,
  step: 25,
  after: 0,
  hasMore: true,
  maxLength: '',
  maxLengthLl: ''
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingLandlords(state) {
      state.isLoadingLandlords = true;
    },
    startLoadingTurnovers(state) {
      state.isLoadingTurnovers = true;
    },
    // GET POST INFINITE
    getCompaniesInitial(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.companies = [...state.companies, ...action.payload.results];
    },
    // GET POST INFINITE
    getCompaniesZero(state, action) {
      state.isLoading = false;
      console.log('zero');
      console.log(action.payload);
      state.companies = action.payload.results;
    },
    getMoreCompanies(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },
    getMoreRetailCompanies(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET POST INFINITE
    getCompanies(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.companies = [...state.companies, ...action.payload.companies];
      state.maxLength = action.payload.rowcount;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCompaniesInternalSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.companies.sort((a, b) => (b.name < a.name ? 1 : -1));
    },
    getTenantsInternalSuccess(state, action) {
      state.isLoading = false;
      state.tenants = action.payload;
    },
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.sort((a, b) => (b.properties.name < a.properties.name ? 1 : -1));
    },
    getLandlordsSuccess(state, action) {
      state.isLoadingLandlords = false;
      state.landlords = action.payload.landlords;
      state.maxLengthLl = action.payload.rowcount;
    },
    getLandlordSuccess(state, action) {
      console.log('landll', action.payload);
      state.isLoading = false;
      state.landlord = action.payload.landlord;
    },
    getTenantsSuccess(state, action) {
      state.isLoading = false;
      state.tenants = action.payload.sort((a, b) => (b.properties.name < a.properties.name ? 1 : -1));
    },

    getCpySuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      // console.log('loading companies', action.payload);
    },

    // GET POST
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload.company;
    },
    // GET POST
    getCompanyActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.cpyactivities = action.payload.activities;
      state.cpymissions = action.payload.missions;
    },
    // GET POST
    getCompanyMatchSuccess(state, action) {
      state.isLoading = false;
      state.matchingprojects = action.payload.matchingprojects;
      state.matchingneeds = action.payload.matchingneeds;
      state.cpyneeds = action.payload.cpyneeds;
    },

    // GET POST
    getNeedMatchSuccess(state, action) {
      state.isLoading = false;
      state.matchingneedprojects = action.payload.matchingprojects;
    },
    // GET POST
    getContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload;
    },
    // GET NEEDS
    getNeedsSuccess(state, action) {
      state.isLoading = false;
      state.needs = action.payload;
    },
    // GET NEEDS
    getTurnoversSuccess(state, action) {
      state.isLoadingTurnovers = false;
      console.log('turn', action.payload);
      state.turnovers = action.payload.company.turnovers;
    },
    // GET NEEDS
    getOldNeedsSuccess(state, action) {
      state.isLoading = false;
      state.oldneeds = action.payload;
    },
    // GET NEEDS
    getNeedSuccess(state, action) {
      state.isLoading = false;
      state.need = action.payload;
    },
    // GET NEEDS
    getMatchesSuccess(state, action) {
      state.isLoading = false;
      state.matches = action.payload;
    },
    // GET NEEDS
    getTenantsSuccess(state, action) {
      state.isLoading = false;
      state.tenantsArea = action.payload;
    },
    // GET NEEDS
    createCompanySuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.newCompany = action.payload;
    },
    // GET NEEDS
    createContactSuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.contact = action.payload;
    },
    // GET NEEDS
    createNoteSuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.newNote = action.payload;
    },
    noHasMore(state) {
      state.hasMore = false;
    },
    hasMore(state, action) {
      state.after = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { getMoreCompanies, getMoreRetailCompanies } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCompanies() {
  return async (dispatch) => {
     dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/companies/index');
      dispatch(slice.actions.getCompaniesSuccess(response.data.companies));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTurnovers(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingTurnovers());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/turnovers/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getTurnoversSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getInternalCompanies() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/companies/internalindex');
      console.log('internalcompy', response);
      dispatch(slice.actions.getCompaniesInternalSuccess(response.data));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getLandlords() {
  return async (dispatch) => {

     dispatch(slice.actions.startLoadingLandlords());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/landlords'
      });
      console.log(response);
      dispatch(slice.actions.getLandlordsSuccess(response.data));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLandlord(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/landlord/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getLandlordSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getLandlordsLight() {
  return async (dispatch) => {

     dispatch(slice.actions.startLoadingLandlords());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/landlordslight'
      });
      console.log(response);
      dispatch(slice.actions.getLandlordsSuccess(response.data));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompanies(index, step) {
  return async (dispatch) => {
    
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/more',
        params: {
          idx: index,
          step,
        }
      });
      console.log(response);
      const results = index + response.data.companies.length;
      const { rowcount } = response.data;
      dispatch(slice.actions.getCompanies(response.data));
      if (results >= rowcount) {
        dispatch(slice.actions.noHasMore());
      } 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRetailCompanies(index, step) {
  return async (dispatch) => {
    
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/retailmore',
        params: {
          idx: index,
          step,
        }
      });
      console.log(response);
      const results = index + response.data.companies.length;
      const { rowcount } = response.data;
      dispatch(slice.actions.getCompanies(response.data));
      if (results >= rowcount) {
        dispatch(slice.actions.noHasMore());
      } 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getInternalTenants() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/companies/internaltenants');
      dispatch(slice.actions.getTenantsInternalSuccess(response.data));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompaniesInitial(index, step, after, tenant, neww) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/indexinit',
        params: {
          index,
          step,
          after,
          tenant
        }
      });
      // const responseai = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/openaitest');
      // console.log('ai');
      console.log(response);
      const results = response.data.companies.length;
      const { total } = response.data;
      const afterData = response.data.companies.paging?.next?.after;
      console.log(neww);
      console.log(tenant);
      if (neww) {
        dispatch(slice.actions.getCompaniesZero(response.data.companies));
      } else {
        dispatch(slice.actions.getCompaniesInitial(response.data.companies));
      }
      if (afterData) {
        console.log(afterData);
        dispatch(slice.actions.hasMore(afterData));
      } else {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/contact/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getContactSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/company/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanyActivities(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/companyactivities/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getCompanyActivitiesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanyMatch(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/match/${id}`, {
        params: { id }
      });
      console.log(response.data);
      dispatch(slice.actions.getCompanyMatchSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getNeedMatch(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/matchneed/${id}`, {
        params: { id }
      });
      console.log(response.data);
      dispatch(slice.actions.getNeedMatchSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNote(note, company) {
  console.log(company);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/createnote`, {
        params: { id: company, timestamp: Date.now(), body: note}
      });
      dispatch(slice.actions.createNoteSuccess(response.data));
      // console.log(response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createCompany(pro) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/creat`, {
        params: { domain: pro.domain, name: pro.name, nickname: pro.nickname, type: pro.type }
      });
      dispatch(slice.actions.createCompanySuccess(response.data));
      // console.log(response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAllNeeds() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/index`);
      dispatch(slice.actions.getNeedsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getOldNeeds() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/old`);
      dispatch(slice.actions.getOldNeedsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getNeeds(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/cpyneeds/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getNeedsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTenants(catchment) {
  // console.log('load tenants');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/needs/findtincatchment', {
        params: { catchment }
      });
      // console.log('Properties in area: ', response);
      dispatch(slice.actions.getTenantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMatches(catchment) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/getmatches`, {
        params: { catchment }
      });
      // console.log('Matches', response);
      dispatch(slice.actions.getMatchesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getNeed(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/need`, {
        params: { id }
      });
      // console.log('need', response.data);
      dispatch(slice.actions.getNeedSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateCompany(company) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/update`, {
        params: { ...company }
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateContact(contact) {
  const { id, firstname, lastname, role, phone, email } = contact;
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/updatecontact`, {
        params: { id, firstname, lastname, role, phone, email }
      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
