import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { LoadingButton } from '@mui/lab';
import { Alert, Card, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import * as Yup from 'yup';
import { geoConfig, mapConfig } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------
NewPlaceForm.propTypes = {
  onPlace: PropTypes.func
};
const CardMediaStyle = styled('div')({
  position: 'relative',
  top: 0,
  paddingTop: 'calc(100% * 3 / 4)'
});

const ScoreStyle = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  position: 'absolute',
  top: theme.spacing(1)
}));

const CoverImgStyle = styled('img')({
  top: 0,
  objectFit: 'cover',
  position: 'absolute',
  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
});
export default function NewPlaceForm({ onPlace }) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const { user } = useAuth();
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [zip, setZip] = useState('');
  const [countyname, setCountyname] = useState('');
  const [countryname, setCountryname] = useState('');

  const map = useRef(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      center: [18.06, 59.329],
      pitch: 60,
      zoom: 16
    });
  });

  useEffect(() => {
    console.log('map.current', map?.current);
    if (!map.current) return;
    map.current.on('style.load', () => {
      map.current.setConfigProperty('basemap', 'lightPreset', 'Night');
    });
  });
  useEffect(() => {
    if (
      lng !== 0 &&
      lat !== 0 &&
      map.current
    ) {

      const lngLatt = { lon: lng, lat };
      marker.remove();
      if (markers.length < 1) {
        marker.setLngLat(lngLatt).addTo(map.current);
      }
      const markerz = markers;
      markerz.push(marker);
      setMarkers(markerz);
      map.current.flyTo({
        center: [lng, lat],
        zoom: 14
      });
    }
  }, [lng, lat, markers]);

  const marker = new mapboxgl.Marker({
    color: '#AF452B',
    draggable: true
  });

  function onDragEnd() {
    const lngLat = marker.getLngLat();
    console.log(lngLat);
    handleMarkerDragged(lngLat);
  }

  marker.on('dragend', onDragEnd);


  const handleMarkerDragged = (lngat) => {
    setLng(lngat.lng);
    setLat(lngat.lat);
  };

  const handleName = (name, city) => {
    if (name.length === 0 || city.length === 0) return;
    Geocode.fromAddress(name + city)
      .then((response) => {
        console.log(response);
        const addcomp = response.results[0].address_components;
        const countrytrial = addcomp.filter((comp) => comp.types[0] === 'country');
        const zip = addcomp.filter((comp) => comp.types[0] === 'postal_code');
        const countytrial = addcomp.filter((comp) => comp.types[0] === 'administrative_area_level_1');
        setCountyname(countytrial[0].long_name);
        setZip(zip[0].long_name.replace(/ /g, ''));
        setCountryname(countrytrial[0].long_name);
        setAddress(response.results[0].formatted_address);
        const { lat, lng } = response.results[0].geometry.location;
        setLng(lng);
        setLat(lat);
      })
      .catch(() => setOpenSnack(true));
  };

  const NewPlaceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    city: Yup.string().required('City is required'),
    description: Yup.string().required('Description is required'),
    cover: Yup.mixed()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      city: '',
      cover: null,
      tags: ['Place'],
      publish: true,
      drawn: false,
      metaDescription: ''
    },
    validationSchema: NewPlaceSchema
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleBlur = (e) => {
    if (values.title && values.city.length > 2) {
      setName(values.title);
      handleName(values.title, values.city);
      if (e.keyCode === 13) {
        handleClick();
      }
    }
  };
  const handleClick = () => {
    const placc = {
      name: values.title,
      city_name: values.city,
      latitude: lat,
      longitude: lng,
      address,
      user_id: 1,
      county_id: 1,
      country_name: countryname,
      county_name: countyname,
      zip,
      mode: 'walking',
      minutes: 5,
      user_id: user.id
    };
    onPlace(placc);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <>
      <div id="map" style={{ width: '100%', height: '70vh' }} />
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
          {translate('noplace')}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Card
            sx={{
              display: 'flex',
              position: 'absolute',
              borderRadius: 1,
              zIndex: 9,
              top: 0,
              left: '30%',
              right: '30%',
              background: '#fff',
              justifyContent: 'center',
              'margin-top': 10
            }}
          >

            <Grid container spacing={2} sx={{ alignItems: 'center', p: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={translate('Plats/Adress')}
                  onKeyUp={handleBlur}
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={translate('city')}
                  onKeyUp={handleBlur}
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {name ? (
                  <Stack direction="row" spacing={3}>
                    <LoadingButton fullWidth onClick={handleClick} variant="contained" size="large" loading={isSubmitting} color='success'>
                      {translate('next')}
                    </LoadingButton>
                  </Stack>
                ) : (
                  <LoadingButton
                    fullWidth
                    onClick={handleBlur}
                    variant="contained"
                    size="large"
                    loading={isSubmitting}
                  >
                    {translate('search')}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
}
