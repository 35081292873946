import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import IntroPage from '../pages/dashboard/IntroPage';

// ----------------------------------------------------------------------

IntroGuard.propTypes = {
  children: PropTypes.node
};

export default function IntroGuard({ children }) {
  const { user } = useAuth();

  const isFirst = user?.isFirst;
  if (isFirst) {
    return (
      <IntroPage profile={user} />
    );
  }

  return <>{children}</>;
}
