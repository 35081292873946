import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  missions: [],
  fnoxmissions: [],
  fnoxmissionsuser: [],
  accounting: 0,
  followers: null,
  newFollowers: '',
  linkedin: null,
  page: '',
  shares: '',
  sharesRecent: '',
  pageRecents: '',
  mission: null,
  tkn: null,
  hasMore: false,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getMissionsSuccess(state, action) {
      state.isLoading = false;
      state.missions = action.payload.sort((a, b) => (b.id > a.id ? 1 : -1));
    },

    // GET POSTS
    getFnoxMissionsSuccess(state, action) {
      state.isLoading = false;
      state.fnoxmissions = action.payload;
    },

    // GET POSTS
    getFnoxAccountingSuccess(state, action) {
      state.isLoading = false;
      state.accounting = action.payload;
    },
    // GET TKBN
    getTknSuccess(state, action) {
      state.isLoading = false;
      state.tkn = action.payload;
    },
    // GET POST INFINITE
    getMissionsInitial(state, action) {
      state.isLoading = false;
      state.missions = action.payload;
    },

    getMoreMissions(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getMissionSuccess(state, action) {
      state.isLoading = false;
      state.mission = action.payload;
    },

    // GET POST
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.linkedin = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreMissions } = slice.actions;

// ----------------------------------------------------------------------

export function getFnoxAuth(code, user) {
  console.log('getting missions');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/fnoxvalidate', {
        params: { code, user }
      });
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getTknSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFnoxAuthUser(user) {
  console.log('getting missions');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/fnoxvalidateuser', {
        params: { user }
      });
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getTknSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAllMissions() {
  console.log('getting missions');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/index');
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getMissionsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAuthLinkedIn(code, user) {
  console.log('getting linkedin');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/authlinkedin', {
        params: { code, user }
      });
      console.log('Linkedin response', response);
      dispatch(slice.actions.getFollowersSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFnoxMissions(tkn, rfrsh) {
  console.log('getting missions');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/fnoxindex', {
        params: { tkn, rfrsh }
      });
      console.log('response fnox missions: ', response);
      dispatch(slice.actions.getFnoxMissionsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFnoxAccounting(tkn, rfrsh) {
  console.log('Fnox accounting');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/missions/fnoxaccounting', {
        params: { tkn, rfrsh }
      });
      console.log('Accounting response: ', response);
      console.log(response);
      dispatch(slice.actions.getFnoxAccountingSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMissionsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/missions/indexinit',
        params: {
          index,
          step
        }
      });

      const results = response.data.missions.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getMissionsInitial(response.data.missions));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMission(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/missions/mission/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getMissionSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
