import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  activities: [],
  activity: null,
  tkn: null,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getMoreActivities(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },


    // GET POSTS
    getActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.activities = action.payload;
    },

    // GET POST INFINITE
    getActivitiesInitial(state, action) {
      state.isLoading = false;
      state.activities = action.payload;
    },

    getMoreActivities(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getActivitySuccess(state, action) {
      state.isLoading = false;
      state.activity = action.payload;
    },

    // GET POST
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.linkedin = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreActivities } = slice.actions;

// ----------------------------------------------------------------------

export function getAllActivities() {
  console.log('getting activities');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/activities/index');
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getActivitiesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActivities(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/activities/indexinit',
        params: {
          index,
          step
        }
      });
      console.log(response);
      const results = response.data.activities.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getActivitiesInitial(response.data.activities));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActivity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/activities/activity/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getActivitySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
