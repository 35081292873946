import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  traffic: PropTypes.any.isRequired,
  catchment: PropTypes.any.isRequired
};

export default function PotentialMap({ place, traffic, catchment, onSquareClicked, trafficfigure }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;

  const cleanTraffic = traffic.map((pt) => {
    const geom = pt.Geometry.WGS84.replace('POINT (', '').replace(')', '');
    const splitgeom = geom.split(' ');
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        // These coordinates outline Maine.
        coordinates: splitgeom
      },
      properties: {
        speed: pt.AverageVehicleSpeed,
        flow: pt.VehicleFlowRate
      }
    };
    return feature;
  });

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'maproad', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
  });

  useEffect(() => {
    if (!map.current || !cleanTrafficNew || cleanTrafficNew.length === 0) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: cleanTrafficNew
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 12,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 12, 0, 12.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 12, 0, 12.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'circle',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'circle-color': {
              property: 'speed',
              stops: [
                [0, '#008f00'],
                [50, '#30D5C8'],
                [70, '#FFFF00'],
                [90, '#FFD700'],
                [10000, '#8b0000']
              ]
            },
            'circle-radius': {
              property: 'flow',
              stops: [
                [0, 4],
                [100, 5],
                [200, 6],
                [500, 7],
                [10000, 8]
              ]
            },
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }

      const layers = ['0 - 1000', '1000 - 2000', '2000 - 5000', '5000 - 10000', '10000+'];
      const colors = ['#008f00', '#30D5C8', '#FFFF00', '#FFD700', '#8b0000'];
      const legend = document.getElementById('legend');
      layers.forEach((layer, i) => {
        if (i < 5) {
          const color = colors[i];
          const item = document.createElement('div');
          const key = document.createElement('span');
          key.style = 'display:inline-block;border-radius:20%;width:10px;height:10px;margin-right:5px;';
          key.style.backgroundColor = color;
          const value = document.createElement('span');
          value.innerHTML = `${layer}`;
          item.appendChild(key);
          item.appendChild(value);
          legend.appendChild(item);
        }
      });
      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        document.getElementById('pd').innerHTML = states.length
          ? `<strong><em>${Math.round(states[0].properties.flow)}</strong> ${translate('Vehicles')}
          ${translate(' with speed ')}${Math.round(states[0].properties.speed)} km/h</em></p><p><i>${translate(
              'Average traffic'
            )}: ${trafficfigure}</i></p>`
          : `<p>${translate('roadSnack')}</p><p><i>${translate('Average Traffic')}: ${trafficfigure}</i></p>`;
      });
      map.current.on('click', 'maine', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        const clicked = states[0].properties;
        onSquareClicked(clicked);
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });

  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const cleanTrafficNew = cleanTraffic;

  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <Box>
        <div id="maproad" style={{ width: '100%', height: '90vh' }} />
        <div
          id="features"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            'padding-left': 25,
            background: '#fff',
            overflow: 'auto',
            top: 0,
            height: 120,
            width: 250
          }}
        >
          <h2>{translate('Road Traffic')}</h2>
          <div id="pd">
            <p>{translate('hover')}</p>
          </div>
        </div>
        <div
          id="legend"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            background: '#fff',
            'margin-right': 10,
            overflow: 'auto',
            'border-radius': 15,
            padding: 10,
            'line-height': 18,
            height: 120,
            'margin-bottom': 10,
            width: 140
          }}
        />
      </Box>
    </Card>
  );
}
