import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import chatReducer from './slices/chat';
import placeReducer from './slices/place';
import spaceReducer from './slices/space';
import spacetypeReducer from './slices/spacetype';
import propertyReducer from './slices/property';
import userReducer from './slices/user';
import prjctReducer from './slices/prjct';
import postReducer from './slices/post';
import missionReducer from './slices/mission';
import landlordReducer from './slices/landlord';
import companyReducer from './slices/company';
import dealReducer from './slices/deal';
import voucherReducer from './slices/voucher';
import estimateReducer from './slices/estimate';
import budgetReducer from './slices/budget';
import kanbanReducer from './slices/kanban';
import populationReducer from './slices/population';
import mindReducer from './slices/mind';
import tenantReducer from './slices/tenant';
import activityReducer from './slices/activity';
import contractReducer from './slices/contract';
import reviewReducer from './slices/review';
import clientReducer from './slices/client';
import contactReducer from './slices/contact';
import demandReducer from './slices/demand';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  chat: chatReducer,
  place: placeReducer,
  space: spaceReducer,
  tenant: tenantReducer,
  spacetype: spacetypeReducer,
  property: propertyReducer,
  prjct: prjctReducer,
  user: userReducer,
  post: postReducer,
  mission: missionReducer,
  landlord: landlordReducer,
  company: companyReducer,
  deal: dealReducer,
  voucher: voucherReducer,
  estimate: estimateReducer,
  budget: budgetReducer,
  kanban: kanbanReducer,
  population: populationReducer,
  mind: mindReducer,
  review: reviewReducer,
  activity: activityReducer,
  contract: contractReducer,
  client: clientReducer,
  demand: demandReducer,
  contact: contactReducer
});

export { rootPersistConfig, rootReducer };
