// material
import { Grid } from '@mui/material';

//
import { InsightBlock, InsightBlockText } from '.';
import { SkeletonPostItem } from '../../skeleton';
import LoadingScreen from '../../LoadingScreen';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function TruthInsights({ industrydata, supply, name }) {
  const { translate, currentLang } = useLocales();
  const filteredData = industrydata.industrydata.filter(
    (i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office'
  );
  if (!industrydata || industrydata.length < 1) return <LoadingScreen />;
  const supplyData = supply
    ? supply.map((i) => Math.round(i.turnover / 100000) / 10).sort((a, b) => (b > a ? 1 : -1))
    : filteredData.map((f) => 0);
  const potential = filteredData.map((i) => {
    const sup = supply
      ? supply.find((s) => s.name === i.name)
      : filteredData.map((f) => {
          const suppy = { supply: 0 };
          return suppy;
        });
    const sto = sup?.turnover || 0;
    const potential = Math.max(i.demandPhysical - sto, 0);
    const data = { ...i, potential };
    return data;
  });
  const totalTurnover = Math.round(potential.reduce((a, v) => (a += v.potential), 0) / 100000) / 10;
  function magnitude(turnover) {
    if (turnover > 1000) {
      return translate('enormous');
    }
    if (turnover > 500) {
      return translate('extensive');
    }
    if (turnover > 200) {
      return translate('substantial');
    }
    if (turnover > 50) {
      return translate('small');
    }
    if (turnover > 0) {
      return translate('very smalld');
    }
    return translate('none');
  }

  return (
    <Grid container spacing={3}>
      <Grid key="total" item xs={12}>
        <InsightBlockText
          heading={translate('Total potential')}
          index={0}
          text={`
            ${translate('thePotentialFor')}
            ${name}
            ${translate('is')}
            ${magnitude(totalTurnover)}
            ${translate('totaling')}
            ${totalTurnover} MSEK
            ${translate('focusPotential')}
          `}
          value={totalTurnover}
        />
      </Grid>
      {(!potential.length ? [...Array(4)] : potential)
        .sort((a, b) => (a.potential < b.potential ? 1 : -1))
        .slice(0, 2)
        .map((industry, index) =>
          industry ? (
            <Grid key={index} item xs={12} sm={6}>
              <InsightBlock
                heading={currentLang.value === 'se' ? industry.nameSe : industry.name}
                index={index + 1}
                text={index === 0 ? translate('industryOne') : translate('industryTwo')}
                value={Math.round(industry.potential / 100000) / 10}
              />
            </Grid>
          ) : (
            <SkeletonPostItem key={index} />
          )
        )}
    </Grid>
  );
}
