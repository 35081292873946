import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingll: false,
  error: false,
  properties: [],
  areaproperties: [],
  portfolio: [],
  matchesll: [],
  property: null,
  county: null,
  consumtion: 0,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startLoadingLl(state) {
      state.isLoadingll = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPropertiesSuccess(state, action) {
      state.isLoading = false;
      state.properties = action.payload;
    },

    // GET POSTS
    getPortfolioSuccess(state, action) {
      console.log(action.payload);
      state.isLoading = false;
      state.portfolio = action.payload;
    },
    // GET POST INFINITE
    getPropertiesInitial(state, action) {
      state.isLoading = false;
      state.properties = action.payload;
    },

    getMoreProperties(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    },

    // GET POST
    getCountySuccess(state, action) {
      state.isLoading = false;
      state.county = action.payload;
    },

    // GET POST
    getConsumtionSuccess(state, action) {
      state.isLoading = false;
      state.consumtion = action.payload;
    },
    // GET POST
    getSpaces(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },
    // GET POST
    getPropertiesAreaSuccess(state, action) {
      state.isLoading = false;
      state.areaproperties = action.payload;
    },
    // GET POST
    getMatchesllSuccess(state, action) {
      state.isLoadingll= false;
      state.matchesll = action.payload;
    },
    // GET POST
    getMatchesllnewSuccess(state, action) {
      state.isLoadingll= false;
      state.matchesll = action.payload.properties;
      state.niids = action.payload.niids;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreProperties } = slice.actions;
// ----------------------------------------------------------------------

export function getAllProperties() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/properties/index');
      dispatch(slice.actions.getPropertiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPortfolio(landlordId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/properties/portfolio', {
        params: { landlord_id: landlordId }
      });
      console.log('portfolio response: ', response);
      dispatch(slice.actions.getPortfolioSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMatchesLl(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingLl());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/properties/match', {
        params: { landlord_id: id }
      });
      console.log('Properties with matches: ', response);
      dispatch(slice.actions.getMatchesllnewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPropertiesArea(catchment) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/needs/findpincatchment', {
        params: { catchment }
      });
      console.log('Properties in area: ', response);
      dispatch(slice.actions.getPropertiesAreaSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPropertiesInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/properties/indexinit',
        params: {
          index,
          step
        }
      });

      const results = response.data.properties.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getPropertiesInitial(response.data.properties));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProperty(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/properties/property', {
        params: { id }
      });
      console.log('property', response.data);
      dispatch(slice.actions.getPropertySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCounty(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/properties/getcounty', {
        params: { id, id2, index: 2 }
      });
      dispatch(slice.actions.getCountySuccess(response.data.county));
      dispatch(slice.actions.getConsumtionSuccess(response.data.consumtion));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
