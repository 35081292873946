import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingc: false,
  isLoadingPopc: false,
  isLoadingFootfalli: false,
  isLoadingVisits: false,
  isLoadingTVisits: false,
  isLoadingTrips: false,
  isLoadingNewHourly: false,
  isLoadingAl1Footfall: false,
  isLoadingAl2Footfall: false,
  isLoadingAl3Footfall: false,
  isLoadingNewFootfall: false,
  isLoadingCovid: false,
  isLoadingMonthly: false,
  isLoadingMonthlyDwell: false,
  isLoadingHourly: false,
  isLoadingDaily: false,
  isLoadingDailyLong: false,
  isLoadingNewFootfallc: false,
  isLoadingMonthlyc: false,
  isLoadingMonthlyDwellc: false,
  isLoadingDailyc: false,
  error: false,
  populations: [],
  popi: [],
  popic: [],
  visits: [],
  tvisits: [],
  trips: [],
  workers: [],
  others: [],
  residents: [],
  totalArray: [],
  al1Footfall: '',
  al2Footfall: '',
  al3Footfall: '',
  newFootfall: '',
  newFootfallc: '',
  origins: [],
  footfalli: [],
  covidFootfall: [],
  monthlyFootfall: [],
  monthlyDwell: [],
  dailyFootfall: [],
  dailyFootfallLong: [],
  monthlyFootfallc: [],
  monthlyDwellc: [],
  dailyFootfallc: [],
  hourlyFootfall: [],
  footfallhourli: [],
  dwelli: [],
  agePopulations: [],
  agePopulationsCountry: [],
  agePopulationsCounty: [],
  futurePopulations: [],
  futurePopulation: '',
  averageAge: [],
  averageIncome: [],
  averageAgeCounty: [],
  averageIncomeCounty: [],
  averageAgeCountry: [],
  averageIncomeCountry: [],
  incomeCountry: [],
  incomeCounty: []
};

const slice = createSlice({
  name: 'population',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingMonthly(state) {
      state.isLoadingMonthly = true;
    },
    startLoadingCovid(state) {
      state.isLoadingCovid = true;
    },
    startLoadingMonthlyDwell(state) {
      state.isLoadingMonthlyDwell = true;
    },
    startLoadingDaily(state) {
      state.isLoadingDaily = true;
    },
    startLoadingDailyLong(state) {
      state.isLoadingDailyLong = true;
    },
    startLoadingc(state) {
      state.isLoadingc = true;
    },
    startLoadingMonthlyc(state) {
      state.isLoadingMonthlyc = true;
    },
    startLoadingMonthlyDwellc(state) {
      state.isLoadingMonthlyDwellc = true;
    },
    startLoadingDailyc(state) {
      state.isLoadingDailyc = true;
    },
    startLoadingNewFootfallc(state) {
      state.isLoadingNewFootfallc = true;
    },
    startLoadingHourly(state) {
      state.isLoadingHourly = true;
    },
    startLoadingPopc(state) {
      state.isLoadingPopc = true;
    },
    startLoadingAl1Footfall(state) {
      state.isLoadingAl3Footfall = true;
    },
    startLoadingAl2Footfall(state) {
      state.isLoadingAl3Footfall = true;
    },
    startLoadingAl3Footfall(state) {
      state.isLoadingAl3Footfall = true;
    },
    startLoadingNewFootfall(state) {
      state.isLoadingNewFootfall = true;
    },
    startLoadingVisits(state) {
      state.isLoadingVisits = true;
    },
    startLoadingTrips(state) {
      state.isLoadingTrips = true;
    },
    startLoadingTVisits(state) {
      state.isLoadingTVisits = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POPULATIONS
    getPopulationsSuccess(state, action) {
      state.isLoading = false;
      state.populations = action.payload;
    },

    // GET TRIPS
    getTripsSuccess(state, action) {
      state.isLoading = false;
      state.trips = action.payload;
      state.isLoadingTrips = false;
    },


    // GET TVISITS
    getMockAohSuccess(state, action) {
      state.isLoading = false;
      state.tvisits = action.payload.data;
      state.isLoadingTVisits = false;
    },
    // GET TVISITS
    getTVisitsSuccess(state, action) {
      state.isLoading = false;
      state.tvisits = action.payload;
      state.isLoadingTVisits = false;
    },

    // GTE MOCK MONTHLY FOOTFALL
    getMockMonthlyFootfallSuccess(state, action) {
      state.isLoadingMonthly = false;
      const { data, place } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.monthlyFootfall = monthAcc;
    },
    // GTE MOCK MONTHLY FOOTFALL
    getMockCovidFootfallSuccess(state, action) {
      state.isLoadingCovid = false;
      const { data, place } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.covidFootfall = monthAcc;
    },
    //GET COVID FOOTFALL
    getCovidFootfallSuccess(state, action) {
      state.isLoadingCovid = false;
      const { data } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.covidFootfall = monthAcc;
    },
    //GET MONTHLY FOOTFALL
    getMonthlyFootfallSuccess(state, action) {
      state.isLoadingMonthly = false;
      const { data } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.monthlyFootfall = monthAcc;
    },



    // GTE MOCK MONTHLY FOOTFALL C
    getMockMonthlyFootfallcSuccess(state, action) {
      state.isLoadingMonthlyc = false;
      const { data, place } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.monthlyFootfallc = monthAcc;
    },
    //GET MONTHLY FOOTFALL C
    getMonthlyFootfallcSuccess(state, action) {
      state.isLoadingMonthlyc = false;
      const { data } = action.payload;
      const asc = arra => arra.sort((a, b) => a - b);
      const quantile = (arra, q) => {
        const sorted = asc(arra);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
          return sorted[base];
        }
      };
      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const months = uniqueArray(data.map((d) => d.year_month));
      const monthAcc = months.map((m) => {
        const filteredVisits = data.filter((d) => d.year_month === m);
        const dat = new Date(m);
        const arra = filteredVisits.map((a) => a.visits);

        const q90 = (arra) => quantile(arra, .90);
        return { month: dat.getMonth(), year: dat.getFullYear(), value: q90(arra) };
      })
      state.monthlyFootfallc = monthAcc;
    },

    // GET FOOTFALL
    getAl1FootfallSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al1Footfall = { activities, totalArray };
      state.isLoadingAl1Footfall = false;
    },
    // GET FOOTFALL
    getAl2FootfallSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al2Footfall = { activities, totalArray };
      state.isLoadingAl2Footfall = false;
    },
    // GET FOOTFALL
    getAl3FootfallSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al3Footfall = { activities, totalArray };
      state.isLoadingAl3Footfall = false;
    },
    // GET FOOTFALL
    getNewFootfallSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.newFootfall = { activities, totalArray };
      state.isLoadingNewFootfall = false;
    },
    // GET MOCK
    getMockFootfallSuccess(state, action) {
      state.isLoading = false;
      const { data } = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.newFootfall = { activities, totalArray };

      state.isLoadingNewFootfall = false;
    },

    getMockAl1FootfallSuccess(state, action) {
      state.isLoadingAl1Footfall = false;
      const { data } = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al1Footfall = { activities, totalArray };

      state.isLoadingAl1Footfall = false;
    },
    getMockAl2FootfallSuccess(state, action) {
      state.isLoadingAl2Footfall = false;
      const { data } = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al2Footfall = { activities, totalArray };

      state.isLoadingAl2Footfall = false;
    },
    getMockAl3FootfallSuccess(state, action) {
      state.isLoadingAl3Footfall = false;
      const { data } = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.al3Footfall = { activities, totalArray };

      state.isLoadingAl3Footfall = false;
    },
    // GET FOOTFALL C
    getNewFootfallcSuccess(state, action) {
      state.isLoadingc = false;
      const data = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.newFootfallc = { activities, totalArray };
      state.isLoadingNewFootfallc = false;
    },
    // GET MOCK C
    getMockFootfallcSuccess(state, action) {
      state.isLoadingc = false;
      const { data } = action.payload;
      const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      const activities = { home, other, work, total };
      state.newFootfallc = { activities, totalArray };

      state.isLoadingNewFootfallc = false;
    },
    // GET FOOTFALL HOURLY
    getFootfallHourlyNewSuccess(state, action) {
      //state.isLoading = false;
      //  const { data } = action.payload;
      //  const home = Math.round(data.filter((g) => g.activity_type === "HOME").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "HOME").length);
      //  const other = Math.round(data.filter((g) => g.activity_type === "OTHER").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "OTHER").length);
      //  const work = Math.round(data.filter((g) => g.activity_type === "WORK").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "WORK").length);
      //  const total = Math.round(data.filter((g) => g.activity_type === "TOTAL").reduce((a, v) => a += v.visits, 0) / data.filter((g) => g.activity_type === "TOTAL").length);
      //  const totalArray = data.filter((g) => g.activity_type === "TOTAL");
      //  const activities = { home, other, work, total };
      //  state.newFootfall = { activities, totalArray };
      state.isLoadingNewHourly = false;
    },
    // GET POPULATIONS
    getPopSuccess(state, action) {
      state.isLoading = false;
      state.popi = action.payload.population;
    },

    // GET POPULATION COMPARE
    getPopcSuccess(state, action) {
      state.isLoadingPopc = false;
      state.popic = action.payload.population;
    },
    // GET Footfall
    getVisitsssSuccess(state, action) {
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item
          ],
          []
        );
      const extendVisits = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.visits.filter((x) => x.activity_type === 'TOTAL').map((v) => ({ area_code: item.grid.area_code, geometry: item.grid.geometry, ...v }))
          ],
          []
        );

      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const extendedVisits = extendVisits(action.payload.data);
      const originArray = action.payload.origins;
      const deepvisits = action.payload.data.map((x) => x.visits);
      const visits = flatten(deepvisits);
      const grids = action.payload.data.map((x) => x.grid);
      const deeptrips = action.payload.data.map((x) => x.trips);
      const trips = flatten(deeptrips);
      const origins = trips.map((t) => t.origin_area_code);
      const uniqueOrigins = uniqueArray(origins);

      const originsData = uniqueOrigins.map((u) => {
        const filteredTrips = trips.filter((t) => t.origin_area_code === u);
        const totalOrigins = filteredTrips.reduce((a, v) => (a += v.people), 0);
        const grid = originArray.find((g) => g.area_code === u);
        return { areaCode: u, people: totalOrigins, trips: filteredTrips, geometry: grid?.geo };
      })

      const workers = visits.filter((x) => x.activity_type === 'WORK');
      const homes = visits.filter((x) => x.activity_type === 'HOME');
      const other = visits.filter((x) => x.activity_type === 'OTHER');
      const total = visits.filter((x) => x.activity_type === 'TOTAL');
      const totalArray = extendedVisits;
      const workercount = workers.reduce((a, v) => (a += v.people), 0)

      state.visits = visits;
      state.trips = trips;
      state.workers = workers;
      state.residents = homes;
      state.others = other;
      state.totalArray = totalArray;
      state.origins = originsData;
      state.isLoadingVisits = false;

    },
    getVisitsSuccess(state, action) {
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item
          ],
          []
        );
      const extendVisits = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.visits.filter((x) => x.activity_type === 'TOTAL').map((v) => ({ area_code: item.grid.area_code, geometry: item.grid.geometry, ...v }))
          ],
          []
        );

      function uniqueArray(a) {
        return [...new Set(a)];
      }
      const extendedVisits = extendVisits(action.payload.data);
      const originArray = action.payload.origins;
      const deepvisits = action.payload.data.map((x) => x.visits);
      const visits = flatten(deepvisits);
      const grids = action.payload.data.map((x) => x.grid);
      const deeptrips = action.payload.data.map((x) => x.trips);
      const trips = flatten(deeptrips);

      const workers = visits.filter((x) => x.activity_type === 'WORK');
      const homes = visits.filter((x) => x.activity_type === 'HOME');
      const other = visits.filter((x) => x.activity_type === 'OTHER');
      const total = visits.filter((x) => x.activity_type === 'TOTAL');
      const totalArray = extendedVisits;
      const workercount = workers.reduce((a, v) => (a += v.people), 0)

      state.visits = visits;
      state.workers = workers;
      state.residents = homes;
      state.others = other;
      state.totalArray = totalArray;
      state.isLoadingVisits = false;

    },
    // GET  OLD Footfall
    getFootfallSuccess(state, action) {
      state.isLoadingFootfalli = false;
      state.footfalli = action.payload.footfall;
    },
    // GET OLD HOURLY
    getFootfallHourlySuccess(state, action) {
      state.isLoading = false;
      state.footfallhourli = action.payload;
    },
    // GET hourly Footfall
    getHourlyFootfallSuccess(state, action) {
      const arra = action.payload.data.map((a) => {
        const obj = { people: a.people, time: a.local_hour.slice(11, 13) };
        return obj;
      });
      console.log('arra', arra);
      const arra2 = arra.sort((a, b) => a.time - b.time);
      console.log('arra2', arra2);
      state.isLoadingHourly = false;
      state.hourlyFootfall = arra2;
    },
    // GET monthly dwell 
    getMonthlyDwellSuccess(state, action) {
      state.isLoadingMonthlyDwell = false;
      state.monthlyDwell = action.payload.data;
    },
    // GET MOCK monthly dwell 
    getMockMonthlyDwellSuccess(state, action) {
      state.isLoadingMonthlyDwell = false;
      state.monthlyDwell = action.payload.data;
    },
    // GET daily Footfall
    getDailyFootfallSuccess(state, action) {
      state.isLoadingDaily = false;
      state.dailyFootfall = action.payload.data;
    },
    // GET daily Footfall
    getDailyFootfallLongSuccess(state, action) {
      state.isLoadingDailyLong = false;
      state.dailyFootfallLong = action.payload.data;
    },
    // GET daily mock Footfall
    getMockDailyFootfallSuccess(state, action) {
      state.isLoadingDaily = false;
      state.dailyFootfall = action.payload.data;
    },
    // GET daily mock Footfall
    getMockDailyLongFootfallSuccess(state, action) {
      state.isLoadingDailyLong = false;
      state.dailyFootfallLong = action.payload.data;
    },


    // GET monthly dwell  C
    getMonthlyDwellcSuccess(state, action) {
      state.isLoadingMonthlyDwellc = false;
      state.monthlyDwellc = action.payload.data;
    },
    // GET MOCK monthly dwell  C
    getMockMonthlyDwellcSuccess(state, action) {
      state.isLoadingMonthlyDwellc = false;
      state.monthlyDwellc = action.payload.data;
    },
    // GET daily Footfall C
    getDailyFootfallcSuccess(state, action) {
      state.isLoadingDailyc = false;
      state.dailyFootfallc = action.payload.data;
    },
    // GET daily mock Footfall C
    getMockDailyFootfallcSuccess(state, action) {
      state.isLoadingDailyc = false;
      state.dailyFootfallc = action.payload.data;
    },


    // GET DWELL
    getDwellSuccess(state, action) {
      state.isLoading = false;
      state.dwelli = action.payload.dwell;
    },
    // GET FUTURE POPULATION
    getFuturePopulationSuccess(state, action) {
      state.isLoading = false;
      state.futurepopulation = action.payload;
    },
    // GET POSTS
    getAgePopulationsSuccess(state, action) {
      state.isLoading = false;
      state.agePopulations = action.payload.ageGroups;
      state.agePopulationsCounty = action.payload.ageGroupsCounty;
      state.agePopulationsCountry = action.payload.ageGroupsCountry;
      state.averageAge = action.payload.average;
      state.averageAgeCountry = action.payload.countryAverage;
      state.averageAgeCounty = action.payload.countyAverage;
    },

    // GET POSTS
    getIncomeSuccess(state, action) {
      state.isLoading = false;
      state.income = action.payload.income;
      state.incomeCountry = action.payload.incomeCountry;
      state.incomeCounty = action.payload.incomeCounty;
      state.averageIncome = action.payload.average;
      state.averageIncomeCountry = action.payload.countryAverage;
      state.averageIncomeCounty = action.payload.countyAverage;
    },

    // GET POSTS
    getFuturePopulationsSuccess(state, action) {
      state.isLoading = false;
      state.futurePopulations = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
function updatePlace(obj, id) {
  // if (!outputCompetition.length) return;
  console.log('update placedata', obj, id);
  const url = `https://plejsapp-api.herokuapp.com/api/v1/places/update/${id}`;

  let dates = new Date()
  let month = dates.getUTCMonth() + 1
  let day = dates.getUTCDate()
  let year = dates.getUTCFullYear()
  let currentDate = year + "-" + month + "-" + day;
  axios.put(url, {
    monthly_footfall: JSON.stringify(obj.monthly_footfall),
    deepfootfallupdated: currentDate
  })

};
// ----------------------------------------------------------------------

export function getAllPopulations() {
  console.log('all populations load');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/populations/index');
      console.log('All populations loaded');
      dispatch(slice.actions.getPopulationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPopc(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPopc());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getpop', {
        catchment: body
      });
      dispatch(slice.actions.getPopcSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPop(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getpop', {
        catchment: body
      });
      dispatch(slice.actions.getPopSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFootfall(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingFootfalli());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getfootfall', {
        catchment: body
      });
      dispatch(slice.actions.getFootfallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAl1Footfall(body, place) {
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.al1footfallupdated);
  const expired = updatedat < expirationDate || !place.al1footfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingAl1Footfall());
    console.log('Al1 footfall loaded from cache');
    const response = JSON.parse(place.al1_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockAl1FootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingAl1Footfall());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/newfootfallal1', {
        catchment: body,
        id: place.id
      });
      console.log('Al1 Footfall loaded');
      dispatch(slice.actions.getAl1FootfallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAl2Footfall(body, place) {
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.al2footfallupdated);
  const expired = updatedat < expirationDate || !place.al2footfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingAl2Footfall());
    console.log('Al2 footfall loaded from cache');
    const response = JSON.parse(place.al2_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockAl2FootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingAl2Footfall());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/newfootfallal2', {
        catchment: body,
        id: place.id
      });
      console.log('Al2 Footfall loaded');
      dispatch(slice.actions.getAl2FootfallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAl3Footfall(body, place) {
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.al3footfallupdated);
  const expired = updatedat < expirationDate || !place.al3footfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingAl3Footfall());
    console.log('Al3 footfall loaded from cache');
    const response = JSON.parse(place.al3_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockAl3FootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingAl3Footfall());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/newfootfallal3', {
        catchment: body,
        id: place.id
      });
      console.log('Al3 Footfall loaded');
      dispatch(slice.actions.getAl3FootfallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getNewFootfall(body, isTest, place) {
  console.log('newfootfall load');
  if (isTest) return (dispatch) => {
    dispatch(slice.actions.startLoadingNewFootfall());
    console.log('Mock Footfall loaded');
    const response = {
      "activities": {
        "home": 432,
        "other": 460,
        "work": 617,
        "total": 1256
      },
      "totalArray": [
        {
          "activity_type": "TOTAL",
          "visits": 802,
          "area_code": 32034234124,
          "geo": "POLYGON((17.6147000786935 59.8446359859706, 17.6143481956279 59.8401509215574, 17.623260042898 59.8399735535394, 17.623613122502 59.8444585870795, 17.6147000786935 59.8446359859706))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 937,
          "area_code": 32034234214,
          "geo": "POLYGON((17.6325259860386 59.8442805821344, 17.6321717100515 59.8397955796024, 17.6410832817684 59.8396170035435, 17.641438764298 59.8441019736223, 17.6325259860386 59.8442805821344))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 128,
          "area_code": 320342324334,
          "geo": "POLYGON((17.628600294179 59.8510971767182, 17.6284233444098 59.8488546763193, 17.6328803677128 59.8487655823781, 17.6330576155212 59.8510080748738, 17.628600294179 59.8510971767182))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 2232,
          "area_code": 320342324322,
          "geo": "POLYGON((17.6380477047115 59.8576462764225, 17.6378700814532 59.85540379139, 17.6423279536057 59.8553143728017, 17.6425058718846 59.8575568490083, 17.6380477047115 59.8576462764225))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 650,
          "area_code": 32034234122,
          "geo": "POLYGON((17.6150520680032 59.8491210517769, 17.6147000786935 59.8446359859706, 17.623613122502 59.8444585870795, 17.6239662972642 59.8489436196743, 17.6150520680032 59.8491210517769))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 423,
          "area_code": 320342324331,
          "geo": "POLYGON((17.6243196308388 59.853428637729, 17.6241429521466 59.85118612882, 17.628600294179 59.8510971767182, 17.6287772709562 59.8533396777365, 17.6243196308388 59.853428637729))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1025,
          "area_code": 320342324311,
          "geo": "POLYGON((17.6246730596741 59.8579136548372, 17.6244963333462 59.8556711464015, 17.62895427165 59.8555821785697, 17.6291312985662 59.8578246793383, 17.6246730596741 59.8579136548372))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1012,
          "area_code": 320342324431,
          "geo": "POLYGON((17.6421500593074 59.8530718963552, 17.6419721889841 59.8508294196689, 17.6464294385969 59.8507398660971, 17.6466076093814 59.8529823350347, 17.6421500593074 59.8530718963552))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1495,
          "area_code": 320342324324,
          "geo": "POLYGON((17.6378700814532 59.85540379139, 17.6376924852304 59.8531613070485, 17.6421500593074 59.8530718963552, 17.6423279536057 59.8553143728017, 17.6378700814532 59.85540379139))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 765,
          "area_code": 320342323444,
          "geo": "POLYGON((17.6196855461097 59.8512749287145, 17.6195091945148 59.8490324110211, 17.6239662972642 59.8489436196743, 17.6241429521466 59.85118612882, 17.6196855461097 59.8512749287145))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 434,
          "area_code": 320342323423,
          "geo": "POLYGON((17.6155802979563 59.8558486262117, 17.6154041942526 59.853606102065, 17.6198619244327 59.8535174452062, 17.6200383262056 59.8557599614795, 17.6155802979563 59.8558486262117))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 128,
          "area_code": 320342323424,
          "geo": "POLYGON((17.6200383262056 59.8557599614795, 17.6198619244327 59.8535174452062, 17.6243196308388 59.853428637729, 17.6244963333462 59.8556711464015, 17.6200383262056 59.8557599614795))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 4148,
          "area_code": 320342342123,
          "geo": "POLYGON((17.6327031649377 59.8465230823752, 17.6325259860386 59.8442805821344, 17.6369823871238 59.8441913531566, 17.6371598639758 59.8464338455127, 17.6327031649377 59.8465230823752))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1772,
          "area_code": 320342342124,
          "geo": "POLYGON((17.6371598639758 59.8464338455127, 17.6369823871238 59.8441913531566, 17.641438764298 59.8441019736223, 17.6416165414862 59.8463444583023, 17.6371598639758 59.8464338455127))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1155,
          "area_code": 320342324343,
          "geo": "POLYGON((17.6330576155212 59.8510080748738, 17.6328803677128 59.8487655823781, 17.6373373671331 59.8486763378522, 17.6375149130666 59.8509188225229, 17.6330576155212 59.8510080748738))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 2736,
          "area_code": 320342324323,
          "geo": "POLYGON((17.6334121884551 59.8554930602846, 17.633234887219 59.8532505671313, 17.6376924852304 59.8531613070485, 17.6378700814532 59.85540379139, 17.6334121884551 59.8554930602846))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 92,
          "area_code": 320342323441,
          "geo": "POLYGON((17.6154041942526 59.853606102065, 17.6152281192635 59.8513635770384, 17.6196855461097 59.8512749287145, 17.6198619244327 59.8535174452062, 17.6154041942526 59.853606102065))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 3396,
          "area_code": 320342324321,
          "geo": "POLYGON((17.633589513592 59.8577355531996, 17.6334121884551 59.8554930602846, 17.6378700814532 59.85540379139, 17.6380477047115 59.8576462764225, 17.633589513592 59.8577355531996))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 2568,
          "area_code": 320342324342,
          "geo": "POLYGON((17.6376924852304 59.8531613070485, 17.6375149130666 59.8509188225229, 17.6419721889841 59.8508294196689, 17.6421500593074 59.8530718963552, 17.6376924852304 59.8531613070485))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 2239,
          "area_code": 32034234211,
          "geo": "POLYGON((17.6239662972642 59.8489436196743, 17.623613122502 59.8444585870795, 17.6325259860386 59.8442805821344, 17.6328803677128 59.8487655823781, 17.6239662972642 59.8489436196743))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 566,
          "area_code": 32034234213,
          "geo": "POLYGON((17.623613122502 59.8444585870795, 17.623260042898 59.8399735535394, 17.6321717100515 59.8397955796024, 17.6325259860386 59.8442805821344, 17.623613122502 59.8444585870795))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 80,
          "area_code": 320342324314,
          "geo": "POLYGON((17.62895427165 59.8555821785697, 17.6287772709562 59.8533396777365, 17.633234887219 59.8532505671313, 17.6334121884551 59.8554930602846, 17.62895427165 59.8555821785697))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 217,
          "area_code": 320342323442,
          "geo": "POLYGON((17.6198619244327 59.8535174452062, 17.6196855461097 59.8512749287145, 17.6241429521466 59.85118612882, 17.6243196308388 59.853428637729, 17.6198619244327 59.8535174452062))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1228,
          "area_code": 320342324312,
          "geo": "POLYGON((17.6291312985662 59.8578246793383, 17.62895427165 59.8555821785697, 17.6334121884551 59.8554930602846, 17.633589513592 59.8577355531996, 17.6291312985662 59.8578246793383))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 328,
          "area_code": 320342323443,
          "geo": "POLYGON((17.6152281192635 59.8513635770384, 17.6150520680032 59.8491210517769, 17.6195091945148 59.8490324110211, 17.6196855461097 59.8512749287145, 17.6152281192635 59.8513635770384))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 4083,
          "area_code": 320342342121,
          "geo": "POLYGON((17.6328803677128 59.8487655823781, 17.6327031649377 59.8465230823752, 17.6371598639758 59.8464338455127, 17.6373373671331 59.8486763378522, 17.6328803677128 59.8487655823781))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 471,
          "area_code": 320342324341,
          "geo": "POLYGON((17.633234887219 59.8532505671313, 17.6330576155212 59.8510080748738, 17.6375149130666 59.8509188225229, 17.6376924852304 59.8531613070485, 17.633234887219 59.8532505671313))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 128,
          "area_code": 320342324332,
          "geo": "POLYGON((17.6287772709562 59.8533396777365, 17.628600294179 59.8510971767182, 17.6330576155212 59.8510080748738, 17.633234887219 59.8532505671313, 17.6287772709562 59.8533396777365))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 3020,
          "area_code": 320342342122,
          "geo": "POLYGON((17.6373373671331 59.8486763378522, 17.6371598639758 59.8464338455127, 17.6416165414862 59.8463444583023, 17.6417943426306 59.8485869427428, 17.6373373671331 59.8486763378522))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 601,
          "area_code": 320342324333,
          "geo": "POLYGON((17.6241429521466 59.85118612882, 17.6239662972642 59.8489436196743, 17.6284233444098 59.8488546763193, 17.628600294179 59.8510971767182, 17.6241429521466 59.85118612882))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 1682,
          "area_code": 320342324413,
          "geo": "POLYGON((17.6423279536057 59.8553143728017, 17.6421500593074 59.8530718963552, 17.6466076093814 59.8529823350347, 17.6467858042726 59.8552248037617, 17.6423279536057 59.8553143728017))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 718,
          "area_code": 320342324344,
          "geo": "POLYGON((17.6375149130666 59.8509188225229, 17.6373373671331 59.8486763378522, 17.6417943426306 59.8485869427428, 17.6419721889841 59.8508294196689, 17.6375149130666 59.8509188225229))"
        },
        {
          "activity_type": "TOTAL",
          "visits": 188,
          "area_code": 320342324313,
          "geo": "POLYGON((17.6244963333462 59.8556711464015, 17.6243196308388 59.853428637729, 17.6287772709562 59.8533396777365, 17.62895427165 59.8555821785697, 17.6244963333462 59.8556711464015))"
        }
      ]
    };
    dispatch(slice.actions.getMockFootfallSuccess(response));
  }

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.deepfootfallupdated);
  const expired = updatedat < expirationDate || !place.deepfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingNewFootfall());
    console.log('Existing footfall loaded from cache');
    const response = JSON.parse(place.new_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockFootfallSuccess(obj));

  }

  return async (dispatch) => {
    dispatch(slice.actions.startLoadingNewFootfall());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/newfootfall', {
        catchment: body,
        id: place.id
      });
      console.log('New Footfall loaded');
      dispatch(slice.actions.getNewFootfallSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNewFootfallc(body, place) {
  console.log('newfootfall load');
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.deepfootfallupdated);
  const expired = updatedat < expirationDate || !place.deepfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingNewFootfallc());
    console.log('Existing footfall loaded c from cache');
    const response = JSON.parse(place.new_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockFootfallcSuccess(obj));

  }

  return async (dispatch) => {
    dispatch(slice.actions.startLoadingNewFootfallc());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/newfootfall', {
        catchment: body,
        id: place.id
      });
      console.log('New Footfall loaded c');
      dispatch(slice.actions.getNewFootfallcSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCovidFootfall(body, isTest, place) {

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.covidfootfallupdated);
  const expired = updatedat < expirationDate || !place.covidfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingCovid());
    console.log('COvid footfall loaded from cache');
    const response = JSON.parse(place.covid_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockCovidFootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCovid());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/covidfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Covid footfall loaded');
      dispatch(slice.actions.getCovidFootfallSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getMonthlyFootfall(body, isTest, place) {
  if (isTest) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthly());
    console.log('Mock Footfall loaded');
    const response = [
      {
        "year_month": "2023-10-01T00:00:00.000+00:00",
        "visits": 2511
      },
      {
        "year_month": "2023-03-01T00:00:00.000+00:00",
        "visits": 3072
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 2716
      },
      {
        "year_month": "2023-11-01T00:00:00.000+00:00",
        "visits": 2465
      },
      {
        "year_month": "2022-08-01T00:00:00.000+00:00",
        "visits": 2131
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 2444
      },
      {
        "year_month": "2022-12-01T00:00:00.000+00:00",
        "visits": 2480
      },
      {
        "year_month": "2023-04-01T00:00:00.000+00:00",
        "visits": 3352
      },
      {
        "year_month": "2022-07-01T00:00:00.000+00:00",
        "visits": 1867
      },
      {
        "year_month": "2022-09-01T00:00:00.000+00:00",
        "visits": 2521
      },
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 2930
      },
      {
        "year_month": "2022-04-01T00:00:00.000+00:00",
        "visits": 1544
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 3003
      },
      {
        "year_month": "2022-05-01T00:00:00.000+00:00",
        "visits": 1520
      },
      {
        "year_month": "2023-07-01T00:00:00.000+00:00",
        "visits": 1891
      },
      {
        "year_month": "2023-09-01T00:00:00.000+00:00",
        "visits": 2432
      },
      {
        "year_month": "2022-11-01T00:00:00.000+00:00",
        "visits": 2666
      },
      {
        "year_month": "2023-01-01T00:00:00.000+00:00",
        "visits": 2671
      },
      {
        "year_month": "2022-10-01T00:00:00.000+00:00",
        "visits": 2511
      },
      {
        "year_month": "2023-08-01T00:00:00.000+00:00",
        "visits": 2169
      },
      {
        "year_month": "2022-03-01T00:00:00.000+00:00",
        "visits": 493
      },
      {
        "year_month": "2023-06-01T00:00:00.000+00:00",
        "visits": 2535
      },
      {
        "year_month": "2023-05-01T00:00:00.000+00:00",
        "visits": 3652
      },
      {
        "year_month": "2022-06-01T00:00:00.000+00:00",
        "visits": 1919
      },
      {
        "year_month": "2023-02-01T00:00:00.000+00:00",
        "visits": 2787
      }
    ];
    const obj = { data: response, place }
    dispatch(slice.actions.getMockMonthlyFootfallSuccess(obj));
  }

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.monthlyfootfallupdated);
  const expired = updatedat < expirationDate || !place.monthlyfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthly());
    console.log('Monthly  footfall loaded from cache');
    const response = JSON.parse(place.monthly_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockMonthlyFootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMonthly());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/monthlyfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Monthly footfall loaded');
      dispatch(slice.actions.getMonthlyFootfallSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// MONTHLY C
export function getMonthlyFootfallc(body, place) {

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.monthlyfootfallupdated);
  const expired = updatedat < expirationDate || !place.monthlyfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyc());
    console.log('Monthly footfall c loaded from cache');
    const response = JSON.parse(place.monthly_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockMonthlyFootfallcSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyc());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/monthlyfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Monthly footfall c loaded');
      dispatch(slice.actions.getMonthlyFootfallcSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMonthlyDwell(body, isTest, place) {
  if (isTest) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyDwell());
    console.log('Mock Dwell loaded');
    const response = [
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 406,
        "duration": "20-60"
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 1906,
        "duration": "300+"
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 397,
        "duration": "20-60"
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 571,
        "duration": "5-20"
      },
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 1996,
        "duration": "300+"
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 263,
        "duration": "60-120"
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 207,
        "duration": "60-120"
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 667,
        "duration": "5-20"
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 219,
        "duration": "20-60"
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 1663,
        "duration": "300+"
      },
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 253,
        "duration": "60-120"
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 137,
        "duration": "60-120"
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 298,
        "duration": "5-20"
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 304,
        "duration": "120-300"
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 361,
        "duration": "20-60"
      },
      {
        "year_month": "2023-12-01T00:00:00.000+00:00",
        "visits": 167,
        "duration": "120-300"
      },
      {
        "year_month": "2024-01-01T00:00:00.000+00:00",
        "visits": 260,
        "duration": "120-300"
      },
      {
        "year_month": "2024-03-01T00:00:00.000+00:00",
        "visits": 2058,
        "duration": "300+"
      },
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 295,
        "duration": "120-300"
      },
      {
        "year_month": "2024-02-01T00:00:00.000+00:00",
        "visits": 800,
        "duration": "5-20"
      }
    ];
    dispatch(slice.actions.getMockMonthlyDwellSuccess(response));
  }
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.monthlydwellupdated);
  const expired = updatedat < expirationDate || !place.monthlydwellupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyDwell());
    console.log('monthly dwell loaded from cache');
    const response = JSON.parse(place.monthly_dwell_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockMonthlyDwellSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyDwell());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/monthlydwell', {
        area_code: body,
        id: place.id
      });
      console.log('Monthly dwell loaded');
      dispatch(slice.actions.getMonthlyDwellSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMonthlyDwellc(body, place) {

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 2);
  const updatedat = Date.parse(place.monthlydwellupdated);
  const expired = updatedat < expirationDate || !place.monthlydwellupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyDwellc());
    console.log('Existing dwell c loaded from cache');
    const response = JSON.parse(place.monthly_dwell_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockMonthlyDwellcSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMonthlyDwellc());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/monthlydwell', {
        area_code: body,
        id: place.id
      });
      console.log('Monthly dwell c loaded');
      dispatch(slice.actions.getMonthlyDwellcSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getHourlyFootfall(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingHourly());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/hourlyfootfall', {
        area_code: body
      });
      console.log('Hourly footfall loaded');
      dispatch(slice.actions.getHourlyFootfallSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDailyFootfall(body, isTest, place) {
  if (isTest) return (dispatch) => {
    dispatch(slice.actions.startLoadingDaily());
    console.log('Mock Daily loaded');
    const response = [
      {
        "day": "2024-02-06T00:00:00.000+00:00",
        "visits": 3109
      },
      {
        "day": "2024-02-17T00:00:00.000+00:00",
        "visits": 2354
      },
      {
        "day": "2024-02-18T00:00:00.000+00:00",
        "visits": 2260
      },
      {
        "day": "2024-01-04T00:00:00.000+00:00",
        "visits": 2529
      },
      {
        "day": "2024-02-28T00:00:00.000+00:00",
        "visits": 3554
      },
      {
        "day": "2024-01-28T00:00:00.000+00:00",
        "visits": 2278
      },
      {
        "day": "2024-01-05T00:00:00.000+00:00",
        "visits": 2394
      },
      {
        "day": "2024-01-20T00:00:00.000+00:00",
        "visits": 2271
      },
      {
        "day": "2024-01-12T00:00:00.000+00:00",
        "visits": 2942
      },
      {
        "day": "2024-02-10T00:00:00.000+00:00",
        "visits": 2378
      },
      {
        "day": "2024-03-03T00:00:00.000+00:00",
        "visits": 2344
      },
      {
        "day": "2024-02-04T00:00:00.000+00:00",
        "visits": 2259
      },
      {
        "day": "2023-12-24T00:00:00.000+00:00",
        "visits": 1849
      },
      {
        "day": "2024-01-02T00:00:00.000+00:00",
        "visits": 2293
      },
      {
        "day": "2023-12-17T00:00:00.000+00:00",
        "visits": 2022
      },
      {
        "day": "2024-03-02T00:00:00.000+00:00",
        "visits": 2315
      },
      {
        "day": "2024-01-06T00:00:00.000+00:00",
        "visits": 2081
      },
      {
        "day": "2024-01-22T00:00:00.000+00:00",
        "visits": 2885
      },
      {
        "day": "2024-01-16T00:00:00.000+00:00",
        "visits": 3172
      },
      {
        "day": "2024-03-05T00:00:00.000+00:00",
        "visits": 3606
      },
      {
        "day": "2024-02-27T00:00:00.000+00:00",
        "visits": 3590
      },
      {
        "day": "2024-01-07T00:00:00.000+00:00",
        "visits": 2113
      },
      {
        "day": "2024-02-25T00:00:00.000+00:00",
        "visits": 2233
      },
      {
        "day": "2024-01-18T00:00:00.000+00:00",
        "visits": 3181
      },
      {
        "day": "2024-03-07T00:00:00.000+00:00",
        "visits": 3230
      },
      {
        "day": "2023-12-18T00:00:00.000+00:00",
        "visits": 2504
      },
      {
        "day": "2023-12-16T00:00:00.000+00:00",
        "visits": 2041
      },
      {
        "day": "2024-02-03T00:00:00.000+00:00",
        "visits": 2335
      },
      {
        "day": "2023-12-31T00:00:00.000+00:00",
        "visits": 1900
      },
      {
        "day": "2024-03-13T00:00:00.000+00:00",
        "visits": 3370
      },
      {
        "day": "2023-12-27T00:00:00.000+00:00",
        "visits": 2124
      },
      {
        "day": "2024-01-25T00:00:00.000+00:00",
        "visits": 3030
      },
      {
        "day": "2023-12-29T00:00:00.000+00:00",
        "visits": 2076
      },
      {
        "day": "2023-12-26T00:00:00.000+00:00",
        "visits": 1812
      },
      {
        "day": "2024-02-23T00:00:00.000+00:00",
        "visits": 3339
      },
      {
        "day": "2024-01-27T00:00:00.000+00:00",
        "visits": 2348
      },
      {
        "day": "2023-12-22T00:00:00.000+00:00",
        "visits": 2288
      },
      {
        "day": "2024-02-21T00:00:00.000+00:00",
        "visits": 3488
      },
      {
        "day": "2024-01-23T00:00:00.000+00:00",
        "visits": 3260
      },
      {
        "day": "2024-02-14T00:00:00.000+00:00",
        "visits": 2919
      },
      {
        "day": "2023-12-25T00:00:00.000+00:00",
        "visits": 1791
      },
      {
        "day": "2024-01-14T00:00:00.000+00:00",
        "visits": 2244
      },
      {
        "day": "2024-02-19T00:00:00.000+00:00",
        "visits": 2821
      },
      {
        "day": "2024-01-11T00:00:00.000+00:00",
        "visits": 3091
      },
      {
        "day": "2024-01-26T00:00:00.000+00:00",
        "visits": 2937
      },
      {
        "day": "2024-02-12T00:00:00.000+00:00",
        "visits": 2905
      },
      {
        "day": "2024-02-13T00:00:00.000+00:00",
        "visits": 3071
      },
      {
        "day": "2024-01-24T00:00:00.000+00:00",
        "visits": 2768
      },
      {
        "day": "2024-02-02T00:00:00.000+00:00",
        "visits": 2806
      },
      {
        "day": "2024-01-19T00:00:00.000+00:00",
        "visits": 2951
      },
      {
        "day": "2024-01-15T00:00:00.000+00:00",
        "visits": 3021
      },
      {
        "day": "2024-01-03T00:00:00.000+00:00",
        "visits": 2516
      },
      {
        "day": "2024-03-06T00:00:00.000+00:00",
        "visits": 3022
      },
      {
        "day": "2024-01-31T00:00:00.000+00:00",
        "visits": 3074
      },
      {
        "day": "2024-02-26T00:00:00.000+00:00",
        "visits": 3282
      },
      {
        "day": "2024-03-08T00:00:00.000+00:00",
        "visits": 3251
      },
      {
        "day": "2024-01-29T00:00:00.000+00:00",
        "visits": 3052
      },
      {
        "day": "2024-03-01T00:00:00.000+00:00",
        "visits": 3401
      },
      {
        "day": "2024-01-30T00:00:00.000+00:00",
        "visits": 3088
      },
      {
        "day": "2024-01-08T00:00:00.000+00:00",
        "visits": 3028
      },
      {
        "day": "2024-02-22T00:00:00.000+00:00",
        "visits": 3493
      },
      {
        "day": "2024-02-09T00:00:00.000+00:00",
        "visits": 2987
      },
      {
        "day": "2024-03-04T00:00:00.000+00:00",
        "visits": 3453
      },
      {
        "day": "2023-12-19T00:00:00.000+00:00",
        "visits": 2725
      },
      {
        "day": "2024-01-13T00:00:00.000+00:00",
        "visits": 2310
      },
      {
        "day": "2024-03-10T00:00:00.000+00:00",
        "visits": 2286
      },
      {
        "day": "2024-03-09T00:00:00.000+00:00",
        "visits": 2332
      },
      {
        "day": "2024-02-29T00:00:00.000+00:00",
        "visits": 3487
      },
      {
        "day": "2024-02-15T00:00:00.000+00:00",
        "visits": 3167
      },
      {
        "day": "2024-03-11T00:00:00.000+00:00",
        "visits": 3096
      },
      {
        "day": "2024-02-08T00:00:00.000+00:00",
        "visits": 3292
      },
      {
        "day": "2024-01-01T00:00:00.000+00:00",
        "visits": 1857
      },
      {
        "day": "2024-02-20T00:00:00.000+00:00",
        "visits": 3158
      },
      {
        "day": "2024-02-07T00:00:00.000+00:00",
        "visits": 3028
      },
      {
        "day": "2024-02-11T00:00:00.000+00:00",
        "visits": 2203
      },
      {
        "day": "2024-01-09T00:00:00.000+00:00",
        "visits": 2913
      },
      {
        "day": "2023-12-21T00:00:00.000+00:00",
        "visits": 2707
      },
      {
        "day": "2024-03-12T00:00:00.000+00:00",
        "visits": 3327
      },
      {
        "day": "2024-02-01T00:00:00.000+00:00",
        "visits": 3067
      },
      {
        "day": "2023-12-20T00:00:00.000+00:00",
        "visits": 2554
      },
      {
        "day": "2024-02-16T00:00:00.000+00:00",
        "visits": 2939
      },
      {
        "day": "2023-12-15T00:00:00.000+00:00",
        "visits": 2635
      },
      {
        "day": "2024-02-24T00:00:00.000+00:00",
        "visits": 2362
      },
      {
        "day": "2023-12-28T00:00:00.000+00:00",
        "visits": 2176
      },
      {
        "day": "2024-01-10T00:00:00.000+00:00",
        "visits": 3139
      },
      {
        "day": "2023-12-23T00:00:00.000+00:00",
        "visits": 1880
      },
      {
        "day": "2024-01-17T00:00:00.000+00:00",
        "visits": 3195
      },
      {
        "day": "2024-01-21T00:00:00.000+00:00",
        "visits": 2230
      },
      {
        "day": "2024-02-05T00:00:00.000+00:00",
        "visits": 3089
      },
      {
        "day": "2023-12-30T00:00:00.000+00:00",
        "visits": 1911
      }
    ];
    dispatch(slice.actions.getMockDailyFootfallSuccess(response));
  }

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 1);
  const updatedat = Date.parse(place.dailyfootfallupdated);
  const expired = updatedat < expirationDate || !place.dailyfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingDaily());
    console.log('Existing daily loaded from cache');
    const response = JSON.parse(place.daily_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockDailyFootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDaily());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/dailyfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Daily footfall loaded');
      dispatch(slice.actions.getDailyFootfallSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDailyLongFootfall(body, isTest, place) {

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 1);
  const updatedat = Date.parse(place.dailylongfootfallupdated);
  const expired = updatedat < expirationDate || !place.dailylongfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingDailyLong());
    console.log('Existing daily long loaded from cache');
    const response = JSON.parse(place.daily_long_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockDailyLongFootfallSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDailyLong());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/dailylongfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Daily long footfall loaded');
      dispatch(slice.actions.getDailyFootfallLongSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDailyFootfallc(body, place) {

  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 1);
  const updatedat = Date.parse(place.dailyfootfallupdated);
  const expired = updatedat < expirationDate || !place.dailyfootfallupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingDailyc());
    console.log('Existing daily c loaded from cache');
    const response = JSON.parse(place.daily_footfall_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockDailyFootfallcSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDailyc());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/dailyfootfall', {
        area_code: body,
        id: place.id
      });
      console.log('Daily footfall loaded');
      dispatch(slice.actions.getDailyFootfallcSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getTVisits(body, isTest, place) {
  const today = new Date();
  const expirationDate = today.setMonth(today.getMonth() - 1);
  const updatedat = Date.parse(place.aohupdated);
  const expired = updatedat < expirationDate || !place.aohupdated;
  if (!expired) return (dispatch) => {
    dispatch(slice.actions.startLoadingTVisits());
    console.log('Existing AOH loaded from cache');
    const response = JSON.parse(place.aoh_string);
    const obj = { data: response, place }
    dispatch(slice.actions.getMockAohSuccess(obj));

  }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingTVisits());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/visits', {
        timeout: 50000,
        catchment: body,
        id: place.id
      });
      dispatch(slice.actions.getTVisitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getTrips(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingTrips());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/trips', {
        catchment: body
      });
      dispatch(slice.actions.getTripsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getVisits(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingVisits());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/visits/finddata', {
        catchment: body
      });
      dispatch(slice.actions.getVisitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFootfallHourly(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getfoothourly', {
        catchment: body
      });
      dispatch(slice.actions.getFootfallHourlySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGrids(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getgrids', {
        catchment: body
      });
      dispatch(slice.actions.getFootfallHourlyNewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFootfallHourlyNew(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/hourlynew', {
        catchment: body
      });
      dispatch(slice.actions.getFootfallHourlyNewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDwell(body) {
  console.log('dwell load');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/places/getdwell', {
        catchment: body
      });
      dispatch(slice.actions.getDwellSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAgePopulations(place) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/populations/getage', {
        params: { zip: place.zip }
      });
      console.log('loaded ages');
      dispatch(slice.actions.getAgePopulationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getIncome(place) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/populations/getincome', {
        params: { zip: place.zip }
      });
      console.log('loaded income');
      dispatch(slice.actions.getIncomeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFuturePopulations(place) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/populations/getfuture', {
        params: { zip: place.zip }
      });
      console.log('loaded futurepopulations');

      dispatch(slice.actions.getFuturePopulationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}


// ----------------------------------------------------------------------

export function getFuturePopulation(zip) {
  console.log('futurepopulation load', zip);
  return async (dispatch) => {
    if (zip) {

      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/places/getfuturepopulations', {
          params: {
            zip
          }
        });
        console.log('Future populations loaded');
        dispatch(slice.actions.getFuturePopulationSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.log(error);
      }
    }
  };
}
