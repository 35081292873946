import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Typography, Box } from '@mui/material';
//
import { BigChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 800;
const LEGEND_HEIGHT = 30;

// ----------------------------------------------------------------------

export default function IndustryShares({ industrydata }) {
  const theme = useTheme();
  const { translate } = useLocales();
  if (!industrydata) return null;
  const demandarray = industrydata
    .sort((a, b) => (b.baseShare > a.baseShare ? 1 : -1))
    .map((i) => Math.round(i.baseShare * 100));
  const categories = industrydata.map((i) => translate(i.name));
  const newchartdata = [{ name: 'Share', data: demandarray }];
  const chartOptions = merge(BigChart(), {
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px'
    },
    labels: categories
  });

  return <ReactApexChart type="pie" series={demandarray} options={chartOptions} height={CHART_HEIGHT} />;
}
