import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import logo from './logo.png';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 40, height: '100%', ...sx }}>
      <img src={logo} alt="Logo" />
    </Box>
  );
}
