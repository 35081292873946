import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import { Link as RouterLink } from 'react-router-dom';
import { geoConfig } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../SvgIconStyle';
import {
  PlaceCardMap
} from '../prjcts';

Geocode.setApiKey(geoConfig);
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 9)',
  display: 'flex',
  justifyContent: 'center'
});

const TitleStyle = styled(RouterLink)(({ theme }) => ({
  ...theme.typography.h4,
  height: 32,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const CoverImgStyle = styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  objectFit: 'cover',
  position: 'absolute'
}));


// ----------------------------------------------------------------------

PlaceCard.propTypes = {
  place: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function PlaceCard({ place, onDelete, isEdit }) {
  const { id, name, zip, city_name } = place;
  const linkTo = `${PATH_DASHBOARD.places.root}/${id}`;
  const { user } = useAuth();

  const updatePlace = (latitude, longitude) => {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/places/update/${place.id}`;
    axios.put(url, {
      latitude,
      longitude
    })
      .then((response) => {
        console.log('UPDATED');
      })
      .catch((error) => console.log(error.message));

  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ position: 'relative', borderRadius: 1 }}>
        <CardMediaStyle>

          <CoverImgStyle alt={name} src="/static/home/places.jpg" />
        </CardMediaStyle>

        <CardContent sx={{ pt: 1 }}>
          <Box>
            <TitleStyle to={linkTo}>{name}</TitleStyle>
            <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
              {zip} {city_name ? city_name : 'Sverige'}
            </Typography>
            {isEdit && (
              <IconButton
                sx={{ display: 'flex', position: 'absolute', bottom: 3, right: 3 }}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
