import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';

import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingPremises: false,
  error: false,
  dealsOne: [],
  dealsTwo: [],
  deals: [],
  premises: [],
  destinations: [],
  journey: null,
  form: '',
  answers: [],
  vkpipeline: [],
  journeys: [],
  vkdeals: [],
  vkdeal: [],
  pipeline: [],
  details: [],
  createdlm: [],
  createdlw: [],
  engagements: [],
  wonlm: [],
  wonlw: [],
  deal: null
};

const slice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingPremises(state) {
      state.isLoadingPremises = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVkDealsSuccess(state, action) {
      state.isLoading = false;
      state.vkdeals = action.payload;
    },
    getJourneysSuccess(state, action) {
      state.isLoading = false;
      state.journeys = action.payload;
      if (!state.journey) {
        state.journey = action.payload[0];
      }
    },
    getJourneySuccess(state, action) {
      state.isLoading = false;
      state.journey = action.payload;
    },

    onSetJourney(state, action) {
      const journeyNew = action.payload;
      state.journey = journeyNew;
    },
    getPremisesSuccess(state, action) {
      state.isLoadingPremises = false;
      state.premises = action.payload;
    },

    setFloorsSuccess(state, action) {
      state.premises = action.payload;
      state.isLoadingPremises = false;
    },
    setPremisesSuccess(state, action) {
      state.premises = action.payload;
      state.isLoadingPremises = false;
    },
    getFormSuccess(state, action) {
      state.isLoading = false;
      state.form = action.payload;
    },
    getAnswersSuccess(state, action) {
      state.isLoading = false;
      state.answers = action.payload;
    },
    getDestinationsSuccess(state, action) {
      state.isLoading = false;
      state.destinations = action.payload;
    },
    getVkDealSuccess(state, action) {
      state.isLoading = false;
      state.vkdeal = action.payload;
    },
    getDealsOSuccess(state, action) {
      state.isLoading = false;
      const { one, two } = action.payload;
      state.dealsTwo = two;
      state.dealsOne = one;
    },
    getDealsSuccess(state, action) {
      state.isLoading = false;
      state.deals = action.payload;
    },

    // GET POST
    getDealSuccess(state, action) {
      state.isLoading = false;
      state.deal = action.payload;
    },
    // GET POST
    getVkPipelineSuccess(state, action) {
      state.isLoading = false;
      const pipe = action.payload[0];
      const repipe = pipe.dealstages.map((p) => {
        const r = { ...p, cardIds: p.deals?.map((d) => `${d.id}`) };
        return r;
      });
      state.vkpipeline = repipe.sort((a, b) => (b.displayOrder < a.displayOrder ? 1 : -1));
    },
    // GET POST
    getPipelineSuccess(state, action) {
      const yr = new Date().getFullYear()
      state.isLoading = false;
      const pipe = action.payload[0];
      const repipe = pipe.dealstages.map((p) => {
        const r = { ...p, cardIds: p.deals?.filter((d) => d.goal === `${yr}`).map((d) => `${d.id}`) };
        return r;
      });
      state.pipeline = repipe.sort((a, b) => (b.displayOrder < a.displayOrder ? 1 : -1));
    },

    addDeal(state, action) {
      const dealCurrent = action.payload;
      const newDeals = state.dealsTwo.push(dealCurrent);
      state.dealsTwo = newDeals;
    },

    updateDeals(state, action) {
      const { dealId, dealstage } = action.payload;
      const updateDeals = map(state.dealsTwo, (deal) => {
        if (deal.id === dealId) {
          return {
            ...deal,
            dealstage_id: dealstage.id
          }
        };
        return deal;
      });

      state.vkdeals = updateDeals;
    }
  }
});

// Reducer
export default slice.reducer;

export const { addDeal, updateDeals, onSetJourney } = slice.actions;
// ----------------------------------------------------------------------

export function getAllDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/deals/index');
      dispatch(slice.actions.getDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/deals/mindindex');
      dispatch(slice.actions.getDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneys() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/journeys/index');
      dispatch(slice.actions.getJourneysSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAnswers(id, fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/journeys/answers/${id}`,
        { params: { fields: fields } });
      dispatch(slice.actions.getAnswersSuccess(response.data.items));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getForm(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/journeys/form/${id}`);
      dispatch(slice.actions.getFormSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPremises(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPremises());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/premises/index/`);
      dispatch(slice.actions.getPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setPremises(journey, format, sqm) {
  return async (dispatch) => {
    try {

      const properties = { sqm, journey_id: journey?.id, format };
      const url = `https://plejsapp-api.herokuapp.com/api/v1/premises/create`;
      const response = await axios.post(url, {
        premise: properties
      });
      console.log('response prmeise', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}

// ----------------------------------------------------------------------

export function removeFloors(id) {
  return async (dispatch) => {
    try {

      const url = `https://plejsapp-api.herokuapp.com/api/v1/floors/delete/${id}`;
      const response = await axios.delete(url, {
        id
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setFloorsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}

// ----------------------------------------------------------------------

export function removePremise(id) {
  return async (dispatch) => {
    try {

      const url = `https://plejsapp-api.herokuapp.com/api/v1/premises/delete/${id}`;
      const response = await axios.delete(url, {
        id
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setPremisesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}
// ----------------------------------------------------------------------

export function setFloors(journey, premise, sqm) {
  return async (dispatch) => {
    try {

      const properties = { sqm, journey_id: journey?.id, premise_id: premise };
      const url = `https://plejsapp-api.herokuapp.com/api/v1/floors/create`;
      const response = await axios.post(url, {
        floor: properties
      });
      console.log('response floor', response.data);
      //dispatch(slice.actions.startLoadingLoc());
      dispatch(slice.actions.setFloorsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }

  };
}
// ----------------------------------------------------------------------

export function getVkDeals() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/deals/vkindex');
      dispatch(slice.actions.getVkDealsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDestinations() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/destinations/index');
      dispatch(slice.actions.getDestinationsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getJourney(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/journeys/journey/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getJourneySuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getVkDeal(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/deals/vkdeal/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getVkDealSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getDeal(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/deals/deal/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getDealSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDealsHub(newStage, deal) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/deals/update/${deal.id}`, {
        params: {
          id: deal.id,
          dealname: deal.dealname,
          dealstage_id: newStage,
          amount: deal.amount
        }
      });
      dispatch(slice.actions.updateDeals({ dealId: deal.id, dealstage: newStage }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getPipeline() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/deals/mindpipeline');
      dispatch(slice.actions.getPipelineSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVkPipeline() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/deals/vkpipeline');
      dispatch(slice.actions.getVkPipelineSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}