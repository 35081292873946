import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  prjcts: [],
  prjct: null,
  hasMore: true,
  index: 0,
  step: 11,
  spaces: [],
  consumtion: null
};

const slice = createSlice({
  name: 'prjct',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPrjctsSuccess(state, action) {
      state.isLoading = false;
      state.prjcts = action.payload;
    },

    // GET POST INFINITE
    getPrjctsInitial(state, action) {
      state.isLoading = false;
      state.prjcts = action.payload;
    },

    getMorePrjcts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    getPrjctSpaces(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPrjctSuccess(state, action) {
      state.isLoading = false;
      state.prjct = action.payload;
    },

    // GET POST
    getConsumtionSuccess(state, action) {
      state.isLoading = false;
      state.consumtion = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePrjcts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPrjcts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/prjcts/index');
      dispatch(slice.actions.getPrjctsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPrjctsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/prjcts/indexinit',
        params: {
          index,
          step
        }
      });

      const results = response.data.prjcts.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getPjctsInitial(response.data.prjcts));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPrjct(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/prjcts/prjct', {
        params: { id, id2 }
      });
      dispatch(slice.actions.getPrjctSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getPrjctSpaces(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/prjcts/findspaces', {
        params: { id }
      });
      dispatch(slice.actions.getPrjctSpaces(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
