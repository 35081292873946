// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 18}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const FONT_PRIMARY = 'DMSans, Dubai-Light, Roboto, sans-serif'; // Google Font
const FONT_SECONDARY = 'DMSans, Dubai-Light, loraitalic, serif'; // Local Font
const FONT_ALT = 'DMSansBold, Dubai, Roboto-Bold, sans-serif'; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: FONT_SECONDARY,
    lineHeight: 80 / 64,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 32, md: 42, lg: 42 })
  },
  h2: {
    fontFamily: FONT_ALT,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h3: {
    fontFamily: FONT_ALT,
    lineHeight: 1.3,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1
  },
  h11: {
    fontFamily: FONT_ALT,
    lineHeight: 80 / 64,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 42, md: 82, lg: 82 })
  },
  subtitle1: {
    fontFamily: FONT_ALT,
    fontWeight: 300,
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(16),
    color: 'text.secondary'
  },
  body3: {
    fontWeight: 500,
    lineHeight: 18 / 14,
    fontSize: pxToRem(16),
    color: 'text.secondary'
  },
  body1: {
    fontWeight: 300,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(16)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  caption2: {
    lineHeight: 1.2,
    fontSize: pxToRem(14),
    fontStyle: 'italic'
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    letterSpacing: 1.1
    // textTransform: 'uppercase'
  },
  overline2: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    letterSpacing: 1.1
    // textTransform: 'uppercase'
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(16)
    // remove capitalization TODO
  }
};

export default typography;
