// routes
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('people'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  home: <Icon icon="ic:baseline-business-center" width={'100%'} height={'100%'} />,
  build: <Icon icon="ic:baseline-handyman" width={'100%'} height={'100%'} />,
  place: getIcon('stop-circle-outline'),
  space: getIcon('square-outline'),
  property: getIcon('grid-outline'),
  layers: getIcon('layers-outline'),
  info: getIcon('info-outline')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      { title: 'home', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'mind admin',
    items: [
      // MANAGEMENT : USER

     // { title: 'overview', path: PATH_DASHBOARD.general.mindoverview, icon: ICONS.analytics },
      { title: 'sales', path: PATH_DASHBOARD.general.mindsales, icon: ICONS.ecommerce },
      { title: 'projects', path: PATH_DASHBOARD.general.mindprojects, icon: ICONS.property },
      // { title: 'utility', path: PATH_DASHBOARD.general.mindutility, icon: ICONS.calendar },
      // { title: 'liquidity', path: PATH_DASHBOARD.general.mindliquidity, icon: ICONS.banking },
      // { title: 'costs', path: PATH_DASHBOARD.general.mindcosts, icon: ICONS.layers },
      {
        title: 'retail trends',
        path: PATH_DASHBOARD.general.forecasts,
        icon: ICONS.analytics,
        ide: 'forecast'
      }
    ]
  },

  // VASAKRONAN
  // ----------------------------------------------------------------------
  {
    subheader: 'Vasakronan',
    items: [
      // MANAGEMENT : VASAKRONAN
      { title: 'pipeline', path: PATH_DASHBOARD.vasakronan.sales, icon: ICONS.kanban },
      { title: 'projekt', path: PATH_DASHBOARD.vasakronan.projects, icon: ICONS.property },
      { title: 'företag', path: PATH_DASHBOARD.vasakronan.companies, icon: ICONS.team },
      { title: 'platser', path: PATH_DASHBOARD.vasakronan.portfolio, icon: ICONS.place },
      { title: 'kontakta Mind', path: PATH_DASHBOARD.vasakronan.contact, icon: ICONS.mail }
    ]
  }
];

export default sidebarConfig;
