import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import editOutline from '@iconify/icons-eva/edit-outline';
import { Icon } from '@iconify/react';
// material
import { LoadingButton } from '@mui/lab';
import closeFill from '@iconify/icons-eva/close-fill';
import saveOutline from '@iconify/icons-eva/save-outline';
import {
  FormLabel,
  FormControl,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Card,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import $ from 'jquery';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import LoadingScreenSmall from '../../LoadingScreenSmall';

import useLocales from '../../../hooks/useLocales';
import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  spaces: PropTypes.any.isRequired
};

export default function PotentialMap(props) {
  const { translate } = useLocales();
  const { place, onSave, modeprev, minutesprev, loading, button, onSwitch, catchment } = props;
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!catchment) return;
    map.current.getSource('iso').setData(catchment);
  }, [catchment]);

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': '#53758E',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
        );
      }

      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#53758E',
            'line-width': 2
          }
        });
      }

      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [polyg]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }

      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // marker.setLngLat(lngLat).addTo(map.current);
      // Make the API call
      getIso();
    });
  });

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  console.log(mode, minuter);
  let profile = mode;
  let minutes = minuter;
  let colors = '53758E';
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });

  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      console.log(query, data);
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
      const coordinatesArray = data.features[0].geometry.coordinates[0];
      props.onCatchment(coordinatesArray, profile, minutes);
      props.onLoad();
      // onCatchment(coordinatesArray);
    });
  }

  const onChange = (e) => {
    profile = e.target.value;
    setMode(e.target.value);
    getIso();
  };

  const onChangeDuration = (e) => {
    minutes = e.target.value;
    setMinuter(e.target.value);
    if (e.target.value === '5%2C10%2C15%2C20') {
      colors = '08519c%2C3182bd%2C6baed6%2Cbdd7e7';
    } else if (e.target.value === '5') {
      colors = 'B7C8D5';
    } else if (e.target.value === '10') {
      colors = 'B7C8D5';
    } else {
      colors = 'B7C8D5';
    }
    getIso();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mx: 3, mb: 5 }}>
        <Stack direction="row" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <FormControl
            component="fieldset"
            justifyContent="center"
            sx={{ width: '40%', display: 'flex', justifyContent: 'center' }}
          >
            <FormLabel component="legend">{translate('travelMode')}</FormLabel>
            <RadioGroup row aria-label="mode" name="mode-radio-group" value={mode} onChange={onChange}>
              <FormControlLabel value="walking" control={<Radio />} label={translate('walking')} />
              <FormControlLabel value="cycling" control={<Radio />} label={translate('cycling')} />
              <FormControlLabel value="driving" control={<Radio />} label={translate('driving')} />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" sx={{ width: '40%' }}>
            <FormLabel component="legend">{translate('minutes')}:</FormLabel>
            <RadioGroup row aria-label="minutes" name="minutes-radio-group" value={minuter} onChange={onChangeDuration}>
              <FormControlLabel value="5" control={<Radio />} label="5" />
              <FormControlLabel value="10" control={<Radio />} label="10" />
              <FormControlLabel value="15" control={<Radio />} label="15" />
            </RadioGroup>
          </FormControl>
          {button !== 'icon' ? (
            <LoadingButton onClick={onSave} variant="contained" size="large">
              {translate('next')}
            </LoadingButton>
          ) : (
            <>
              {loading ? (
                <LoadingScreenSmall />
              ) : (
                <Stack spacing={2} direction="row">
                  <IconButton>
                    <Icon icon={saveOutline} onClick={onSave} color="#008f00" />
                  </IconButton>
                  <IconButton>
                    <Icon icon={closeFill} color="#AF452B" />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Box>
      <Card sx={{ weight: '100%' }}>
        <Stack>
          <Box>
            <div id="map" style={{ width: '100%', height: '86vh' }} />
            <Tooltip title={translate('drawPolygon')}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  background: '#fff',
                  'margin-left': 10,
                  'margin-top': 10,
                  overflow: 'auto'
                }}
              >
                <Icon icon={editOutline} onClick={onSwitch} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Card>
    </>
  );
}
