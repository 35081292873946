import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// config
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

const GeoJSON = require('geojson');
const classifyPoint = require('robust-point-in-polygon');

// ----------------------------------------------------------------------

MarketMap.propTypes = {
  properties: PropTypes.any.isRequired,
  onChangedPoly: PropTypes.any
};

export default function MarketMap({ prjct, properties, onChangedPoly, onDrawn }) {
  const map = useRef(null);
  const lng = properties.length > 0 ? properties[0].longitude : 18;
  const lat = properties.length > 0 ? properties[0].latitude : 59.2;
  const [poly, setPoly] = useState([]);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: 8
    });

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'draw_polygon'
    });
    map.current.addControl(draw);
    map.current.on('draw.create', updateArea);
    map.current.on('draw.delete', updateArea);
    map.current.on('draw.update', updateArea);
    function updateArea(e) {
      const data = draw.getAll();
      if (data.features.length > 0) {
        onDrawn(data.features[0].geometry.coordinates[0]);
        setPoly(data.features[0].geometry.coordinates[0]);
      } else {
        onChangedPoly([]);
        if (e.type !== 'draw.delete') alert('Use the draw tools to draw a polygon!');
      }
    }
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      // When the map loads, add the source and layer
      if (!map.current.getSource('properties-s')) {
        map.current.addSource('properties-s', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('properties')) {
        map.current.addLayer({
          id: 'properties',
          type: 'circle',
          source: 'properties-s',
          paint: {
            'circle-color': '#AE452B',
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }

      if (!map.current.getSource('geom-s')) {
        map.current.addSource('geom-s', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [poly]
            }
          }
        });
      }

      if (!map.current.getLayer('geom')) {
        map.current.addLayer({
          id: 'geom',
          type: 'fill',
          source: 'geom-s', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }
    });
  });

  useEffect(() => {
    const polyProperties = properties.filter(
      (property) => classifyPoint(poly, [property.longitude, property.latitude]) < 1
    );
    onChangedPoly(polyProperties);
    if (map.current && map.current.getSource('properties-s')) {
      map.current
        .getSource('properties-s')
        .setData(GeoJSON.parse(polyProperties, { Point: ['latitude', 'longitude'] }));
    }
  }, [poly]);

  if (prjct && prjct.poly_str) {
    setPoly(polyfunction(JSON.parse(prjct.poly_str)));
  }

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };

  return <div id="map" style={{ width: '100%', height: '100%' }} />;
}
