import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// material
import {
  Box,
  Card
} from '@mui/material';
import $ from 'jquery';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

CatchmentMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function CatchmentMap( {place, onSave, modeprev, minutesprev, loading, button, onSwitch, catchment, mapCh, onGrid}) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!catchment) return;
    // map.current.getSource('iso').setData(catchment);
  }, [catchment]);

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': '#53758E',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
        );
      }

      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#53758E',
            'line-width': 2
          }
        });
      }

      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }

      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
      getIso();
    });
  });

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  let profile = mode;
  let minutes = minuter;
  let colors = '53758E';
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });

  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
      const coordinatesArray = data.features[0].geometry.coordinates[0];
    });
  }


  return (
    <Card sx={{ weight: '100%', borderRadius: 1 }}>
      <Box>
        <div id="map" style={{ width: '100%', height: '50vh' }} />
      </Box>
    </Card>
  );
}
