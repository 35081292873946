// material
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
//
import { fShortenNumberone } from '../../../utils/formatNumber';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({}));

// ----------------------------------------------------------------------

export default function LandingMinimalHelps({ consumtion, traffic, population, isLoadingPop }) {
  const { translate } = useLocales();
  return (
    <RootStyle>
      <Box
        sx={{
          position: 'relative',
          height: 120
        }}
      >
        <Box
          sx={{
            bottom: { xs: 24, md: 20 },
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center'
          }}
        >
          {isLoadingPop ? (
            <LoadingScreen />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={3} sx={{ alignItems: 'center' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography sx={{ color: 'text.primary' }} variant="h5" paragraph>
                    {fShortenNumberone(consumtion)}tSEK
                  </Typography>
                  <Typography sx={{ color: 'text.primary' }}> {translate('purchasingPower')} </Typography>
                </Box>
              </Grid>

              <Grid item xs={3} sx={{ alignItems: 'center' }}>
                <Box sx={{ height: '50%', textAlign: 'center' }}>
                  <Typography sx={{ color: 'text.primary' }} variant="h5" paragraph>
                    {population}
                  </Typography>
                  <Typography sx={{ color: 'text.primary' }}> {translate('populationT')} </Typography>
                </Box>
              </Grid>

              <Grid item xs={3} sx={{ alignItems: 'center' }}>
                <Box sx={{ height: '50%', textAlign: 'center' }}>
                  <Typography sx={{ color: 'text.primary' }} variant="h5" paragraph>
                    {Math.round(traffic)}
                  </Typography>
                  <Typography sx={{ color: 'text.primary' }}> {translate('footfallT')} </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} sx={{ alignItems: 'center' }}>
                <Box sx={{ height: '50%', textAlign: 'center' }}>
                  <Typography sx={{ color: 'text.primary' }} variant="h5" paragraph>
                    {Math.round((consumtion * 0.6 * (population + traffic)) / 2 / 1000)}mSEK
                  </Typography>
                  <Typography sx={{ color: 'text.primary' }}> {translate('demand')} </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </RootStyle>
  );
}
