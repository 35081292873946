import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField, Stack } from '@mui/material';
//
import { fPercent } from '../../../utils/formatNumber';
import { AreaOptionChart } from '../../charts';
import LoadingScreen from '../../LoadingScreen';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const YEAR = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029'];

export default function GoalLine({ prognosis }) {
  const [seriesData, setSeriesData] = useState(2022);
  const { translate } = useLocales();
  const [graphType, setGraphType] = useState('Grocery store');
  const today = new Date();
  const year = today.getFullYear();
  if (!prognosis || prognosis.length < 1) return <LoadingScreen />;

  const spacetypeData = prognosis.filter((spacetype) => spacetype.space_type === graphType)[0];

  const fc = spacetypeData.forecasts;
  const physicalValue = YEAR.map((month) => fc.find((f) => f.year === month).physical_share);
  const digitalValue = YEAR.map((month) => fc.find((f) => f.year === month).digital_share);
  const data = [
    { name: translate('physical_share'), data: physicalValue },
    { name: translate('digital_share'), data: digitalValue }
  ];
  const physicalData = data.map((sp) => sp.physical_share);
  const digitalData = data.map((sp) => sp.digital_share);
  const chartData = [
    {
      type: spacetypeData.space_type,
      data
    }
  ];

  const handleChangeGraphType = (event) => {
    setGraphType(event.target.value);
  };
  const chartOptions = merge(AreaOptionChart(), {
    xaxis: { categories: YEAR }
  });
  return (
    <Card>
      <CardHeader
        title={translate('Digital shares')}
        action={
          <Stack direction="row" spacing={0}>
            <TextField
              select
              fullWidth
              value={graphType}
              SelectProps={{ native: true }}
              onChange={handleChangeGraphType}
              sx={{
                '& fieldset': { border: '0 !important' },
                '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
              }}
            >
              {prognosis
                .filter((s) => s.space_type !== 'Not service')
                .map((option) => (
                  <option key={option.space_type} value={option.space_type}>
                    {translate(option.space_type)}
                  </option>
                ))}
            </TextField>
          </Stack>
        }
      />

      {chartData.map((item) => (
        <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.type === graphType && (
            <ReactApexChart type="area" series={item.data} options={chartOptions} height={565} />
          )}
        </Box>
      ))}
    </Card>
  );
}
