import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Typography, Box, Card } from '@mui/material';
//
import { PieOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 500;
const LEGEND_HEIGHT = 30;

// ----------------------------------------------------------------------

export default function TruthBar({ workers, residents, others, height }) {
  const theme = useTheme();
  const { translate } = useLocales();
  if (!workers) return null;
  const dwellAverage = workers + residents + others;
  const demandarray = [Math.round(residents / dwellAverage * 100, 1),   Math.round(workers / dwellAverage * 100, 1), Math.round(others / dwellAverage * 100, 1)];
  const categories = ['Living', 'Work', 'Others'];
  const newchartdata = [{ name: 'Activities', data: demandarray }];
  const midLabel = `Total: ${dwellAverage}`;
  const totalLabel = {
    show: true,
    offsetY: 8,
    label: `Total: ${dwellAverage}`,
    color: theme.palette.text.primary,
    ...theme.typography.h3
  };

  const valueLabel = {
    show: false,
    label: dwellAverage,
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3
  };
  const chartOptions = merge(PieOptionChart(), {
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px'
    },
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            value: valueLabel,
            total: totalLabel
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: true,
            position: 'center'
          }
        }
      }
    ]
  });

  return (
    <>
      <Card sx={{ borderRadius: 1, p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
            Activity per type
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ReactApexChart type="pie" series={demandarray} legend={false} options={chartOptions} width='100%' height={height || CHART_HEIGHT} />
        </Box>
      </Card>
    </>
  );
}
