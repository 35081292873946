import { keyframes } from '@emotion/react';
import { Icon } from '@iconify/react';
import { Box, Button, CardActionArea, Drawer, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { TourProvider, useTour } from '@reactour/tour';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DocIllustration } from '../../assets';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import LogoHoriPlejs from '../../components/LogoHoriPlejs';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import SvgIconStyle from '../../components/SvgIconStyle';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useLocales from '../../hooks/useLocales';
import { PATH_DASHBOARD } from '../../routes/paths';
import sidebarConfigSe from './SidebarConfigSe';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

const getIcon = (name) => (

  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  cart: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  chat: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  mail: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  user: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  team: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  kanban: getIcon('ic_kanban'),
  banking: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  calendar: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: <Icon icon="ic:baseline-analytics" width={'100%'} height={'100%'} />,
  dashboard: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  home: <Icon icon="ic:baseline-business-center" width={'100%'} height={'100%'} />,
  supply: <Icon icon="ic:baseline-place" width={'100%'} height={'100%'} />,
  leasing: <Icon icon="ic:baseline-key" width={'100%'} height={'100%'} />,
  demand: <Icon icon="ic:baseline-person-search" width={'100%'} height={'100%'} />,
  search: <Icon icon="ic:baseline-crisis-alert" width={'100%'} height={'100%'} />,
  property: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  layers: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  info: <Icon icon="ic:baseline-keyboard-double-arrow-down" width={'100%'} height={'100%'} />,
  mail: getIcon('ic_mail'),
  match: <Icon icon="ic:baseline-auto-fix-high" width={'100%'} height={'100%'} />
};
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

MiddleTour.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
const GOOGLE = { title: 'Google Keywords', path: PATH_DASHBOARD.google.keywords, icon: ICONS.ecommerce };
const DISTANCE = { title: 'Commute Tool', path: PATH_DASHBOARD.distance.wiz, icon: ICONS.kanban };
const PREMISE = { title: 'space finder', path: PATH_DASHBOARD.logistics.root, icon: ICONS.search };
const AREAPM = { title: 'Area PM', path: PATH_DASHBOARD.needs.match, icon: ICONS.space };
const LEASEPM = { title: 'Matches', path: PATH_DASHBOARD.needs.matchll, icon: ICONS.match };
const LEASING = {
  title: 'Leasing',
  path: PATH_DASHBOARD.missions.root,
  icon: ICONS.leasing,
  children: [
    { title: 'missions', path: PATH_DASHBOARD.missions.root },
    { title: 'matches', path: PATH_DASHBOARD.needs.matchll, icon: ICONS.banking }
  ]
};
const ADVISORY = {
  title: 'NEOW',
  path: PATH_DASHBOARD.spaceopt.potential,
  icon: ICONS.kanban,
  children: [
    { title: 'översikt', path: PATH_DASHBOARD.spaceopt.descriptive },
  //  { title: 'notifications', path: PATH_DASHBOARD.spaceopt.notifications},
    { title: 'journeys', path: PATH_DASHBOARD.spaceopt.tr },
    { title: 'rekommendationer', path: PATH_DASHBOARD.spaceopt.prescriptive },
    { title: 'f-up', path: PATH_DASHBOARD.spaceopt.fup, icon: ICONS.banking },
  //  { title: 'settings', path: PATH_DASHBOARD.spaceopt.settings, icon: ICONS.info }
  ]
};


const SUPPLY = {
  title: 'places',
  path: PATH_DASHBOARD.places.root,
  icon: ICONS.supply,
  children: [
    { title: 'places', path: PATH_DASHBOARD.places.root, icon: ICONS.place },
    { title: 'properties', path: PATH_DASHBOARD.properties.root, icon: ICONS.place },
    { title: 'big fat map', path: PATH_DASHBOARD.prjcts.bfm, icon: ICONS.space }
  ]
};


const RETAIL = {
  title: 'retail',
  path: PATH_DASHBOARD.companiesretail.root,
  icon: ICONS.ecommerce,
  children: [
    { title: 'missions', path: PATH_DASHBOARD.missions.root },
    { title: 'companies', path: PATH_DASHBOARD.companiesretail.root, icon: ICONS.team },
  //  { title: 'decisions', path: PATH_DASHBOARD.places.root, icon: ICONS.place },
    { title: 'contacts', path: PATH_DASHBOARD.contacts.root, icon: ICONS.user },
    { title: 'needs', path: PATH_DASHBOARD.needs.root, icon: ICONS.property },
  //  { title: 'contracts', path: PATH_DASHBOARD.contracts.root, icon: ICONS.banking }
  ]
};
const DEMAND = {
  title: 'CRM',
  path: PATH_DASHBOARD.companies.root,
  icon: ICONS.demand,
  children: [
    { title: 'companies', path: PATH_DASHBOARD.companies.root, icon: ICONS.team },
  //  { title: 'decisions', path: PATH_DASHBOARD.places.root, icon: ICONS.place },
    { title: 'contacts', path: PATH_DASHBOARD.contacts.root, icon: ICONS.user },
    { title: 'needs', path: PATH_DASHBOARD.needs.root, icon: ICONS.property },
  //  { title: 'contracts', path: PATH_DASHBOARD.contracts.root, icon: ICONS.banking }
  ]
};

const ANALYSES = { title: 'analyses', path: PATH_DASHBOARD.prjcts.customer, icon: ICONS.analytics };

const CONTACT = { title: 'kontakta Mind', path: PATH_DASHBOARD.vasakronan.contact, icon: ICONS.mail }

export default function MiddleTour({ isOpenSidebar, onCloseSidebar, guided, empty }) {
  const { setIsOpen } = useTour();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [guidez, setGuidez] = useState(guided);
  const { email } = user;
  const { currentLang, translate } = useLocales();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    setGuidez(guided);
  }, [guided]);

  // const internal = email.includes('gmail');
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // esslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) return <LoadingScreen />;
  const internal = user?.internal;
  const sidebarHome = sidebarConfigSe[0];
  const sidebarHomeItems = sidebarConfigSe[0].items;
  const sidebarAdvisory = user?.hasAdvisory ? [...sidebarHomeItems, ADVISORY] : sidebarHomeItems;
  const sidebarSupply = user?.hasSupply ? [...sidebarAdvisory, SUPPLY] : sidebarAdvisory;
  const sidebarDemand = user?.hasDemand ? [...sidebarSupply, DEMAND] : sidebarSupply;
  const sidebarLeasing = internal ? [...sidebarDemand, RETAIL] : sidebarDemand;
  const sidebarAnalyses = user?.hasAnalyses ? [...sidebarLeasing, ANALYSES] : sidebarLeasing;
  const sidebarPremise = user?.hasPremise ? [...sidebarAnalyses, PREMISE] : sidebarAnalyses;
  const sidebarDistance = user?.hasDistance ? [...sidebarPremise, DISTANCE] : sidebarPremise;
  const sidebarGoogle = user?.hasGoogle ? [...sidebarDistance, GOOGLE] : sidebarDistance;
  const sidebarLease = user?.hasLease ? [...sidebarGoogle, LEASEPM] : sidebarGoogle;
  const sidebarArea = user?.hasArea ? [...sidebarLease, AREAPM] : sidebarLease;
  const sidebarContact = [...sidebarArea, CONTACT];
  const sidebarModHome = { ...sidebarHome, items: sidebarContact };
  const sidebarMod = user?.internal && user?.vasakronan ? [sidebarModHome, sidebarConfigSe[1], sidebarConfigSe[2]] : user?.internal ? [sidebarModHome, sidebarConfigSe[1]] : user?.vasakronan ? [sidebarModHome, sidebarConfigSe[2]] : [sidebarModHome];

  const stepzInternal = [
    {
      selector: '.platsdatabas-step',
      content:
        'Platser innehåller fastigheter som innehåller lokaler. Du kan utforska och samla information om specifikt innehåll genom vår platsdatabas. Här ligger den information som våra användare har genererat.'
    },
    {
      selector: '.analysis-step',
      content:
        'Det är också möjligt att skapa analyser direkt i Mind NEO. Du guidas genom processen och resultatet blir ett material som direkt kan delas med kund.'
    },
  ];
  const radius = 5;
  const keyframesRotate = keyframes`
    50% {
      transform: translateY(-5px  );
    }
  }`;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {isCollapse ? <Logo /> : <LogoHoriPlejs />}
          </Box>

          <MHidden width="mdDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>
      </Stack>
      {!empty && (
        <NavSection key="se" navConfig={sidebarMod} isShow={!isCollapse} user={user} guided={guidez} />
      )}
      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 5, width: 1, textAlign: 'center' }}>
          <DocIllustration sx={{ width: 1 }} />
          <div>
            <Typography gutterBottom variant="subtitle1">
              {translate('hiSidebar')} {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {internal ? translate('Hoppas du mår bra :)') : translate('Behöver du hjälp?')}
            </Typography>
          </div>
          {!internal && (
          <Button href="mailto:jesper.cronsioe@mindrealestate.se" target="_blank" rel="noreferrer" variant="contained">
            {translate('contactButton')}
          </Button>
        )}
        </Stack>
      )}
    </Scrollbar>
  );
  return (
    <TourProvider
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#417E9F',
          borderRadius: radius
        }),
        maskArea: (base) => ({ ...base, rx: radius }),
        maskWrapper: (base) => ({ ...base, color: '#293B47' }),
        badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
        controls: (base) => ({ ...base, marginTop: 10 }),
        close: (base) => ({ ...base, right: 'auto', left: 10, top: 10 }),
        dot: (base) => ({
          ...base,
          animationDuration: '1s',
          animationName: keyframesRotate,
          animationIterationCount: 'infinite',
          '&:nth-of-type(1)': {
            animationDelay: '.3s'
          },
          '&:nth-of-type(2)': {
            animationDelay: '.6s'
          }
        })
      }}
      steps={stepzInternal}
      onClickClose={({ setCurrentStep, currentStep, setIsOpen }) => {
        setIsOpen(false);
        setGuidez(true);
        const response = axios({
          method: 'put',
          url: `https://plejsapp-api.herokuapp.com/api/v1/users/update/${user.id}`,
          params: {
            id: user.id,
            guided: 'true'
          }
        });
      }}
    >
      <RootStyle
        sx={{
          width: {
            md: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
          },
          ...(collapseClick && {
            position: 'absolute'
          })
        }}
      >
        <MHidden width="mdUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="mdDown">
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                ...(isCollapse && {
                  width: COLLAPSE_WIDTH
                }),
                ...(collapseHover && {
                  borderRight: 0,
                  backdropFilter: 'blur(6px)',
                  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                  boxShadow: (theme) => theme.customShadows.z20,
                  bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
                })
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </TourProvider>
  );
}
