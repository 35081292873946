import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// util
import demandmap from '../../demandmap_demand.png';
import Label from '../../Label';
import { fNumber, fPercent } from '../../../utils/formatNumber';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------

export default function Method() {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={2} sx={{ px: 3 }}>
          <Typography variant="h3">{translate('Demand')}</Typography>
          <Typography variant="subtitle2">{translate('methodDescription')}</Typography>
          <Typography variant="subtitle2">{translate('methodTwo')}</Typography>
          <Typography variant="subtitle2">{translate('methodThree')}</Typography>
          <Typography variant="subtitle2">{translate('methodFour')}</Typography>
          <Typography variant="subtitle2">{translate('methodFive')}</Typography>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', m: 3, px: 5, py: 3 }}>
          <img src={demandmap} alt="Demand map" />
        </Box>
      </Box>
    </Card>
  );
}
