import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';
import logo from './logo.png';
import logoneg from './logoneg.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const { themeMode } = useSettings();
  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 40, height: '100%', ...sx }}>
      {themeMode === 'light' ? <img src={logo} alt="Logo" /> : <img src={logoneg} alt="Logo" />}
    </Box>
  );
}
