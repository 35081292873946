import React, { useRef, useEffect, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;
const MapboxMap = (props) => {
  const map = useRef(null);
  const lng = 18.0;
  const lat = 59.0;
  const [indx, setIndx] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'mapddd', // container id
      style: props.mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      center: [lng, lat],
      zoom: 4
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      // When the map loads, add the source and layer
      const isot = map.current.getSource('isot');
      if (!isot) {
        map.current.addSource('isot', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }

      if (!map.current.getLayer('isotLayer')) {
        map.current.addLayer(
          {
            id: 'isotLayer',
            type: 'fill',
            source: 'isot',
            layout: {},
            paint: {
              'fill-color': '#5a3fc0',
              'fill-opacity': 0.3
            }
          },
          'poi-label'
        );
      }
      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'isot',
          layout: {},
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
    });
  });

  useEffect(() => {
    if (
      props.geojsonpoly &&
      props.coords &&
      props.coords[0] !== 0 &&
      props.coords[1] !== 0 &&
      map.current &&
      map.current.getSource('isot')
    ) {
      map.current.getSource('isot').setData(props.geojsonpoly);

      const lngLatt = { lon: props.coords[0], lat: props.coords[1] };
      marker.remove();
      if (markers.length < 1) {
        marker.setLngLat(lngLatt).addTo(map.current);
      }
      const markerz = markers;
      markerz.push(marker);
      setMarkers(markerz);
      map.current.flyTo({
        center: props.coords,
        zoom: 14
      });
    }
  }, [props.geojsonpoly, props.coords, markers]);

  useEffect(() => {
    setEdit(props.drawn);
    if (props.drawn) {
      map.current.removeLayer('isotLayer');
      map.current.addControl(draw);
      map.current.on('draw.create', updateArea);
      map.current.on('draw.delete', updateArea);
      map.current.on('draw.update', updateArea);
    } else {
      //  map.current.removeControl(draw);
    }
  }, [props.drawn]);

  // Create variables to use in getIso()

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true
    },
    defaultMode: 'draw_polygon'
  });

  function updateArea() {
    const data = draw.getAll();
    if (data.features.length > 0) {
      const datap = data.features[0].geometry.coordinates[0];
      const lat = datap.map((p) => p[0]);
      const lng = datap.map((p) => p[1]);
      const minCoords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng)
      };
      const maxCoords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng)
      };
      const boundsDrawn = {
        maxlat: maxCoords.lat,
        minlat: minCoords.lat,
        maxlon: maxCoords.lng,
        minlon: minCoords.lng
      };
      props.onPolygonSaved(true, data.features[0].geometry.coordinates, boundsDrawn);
    } else {
      alert('Use the draw tools to draw a polygon!');
    }
  }

  const handleClickFw = () => {
    if (indx === props.polygons.length) return;
    const indxplus = indx + 1;
    setIndx(indxplus);
  };
  const handleClickRw = () => {
    if (indx === 0) return;
    const indxneg = indx - 1;
    setIndx(indxneg);
  };

  const marker = new mapboxgl.Marker({
    color: '#AF452B',
    draggable: true
  });

  function onDragEnd() {
    const lngLat = marker.getLngLat();
    console.log(lngLat);
    props.onMarkerDragged(lngLat);
  }

  marker.on('dragend', onDragEnd);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id="mapddd" style={{ width: '100%', height: '100%' }} />
      <div>
        {props.polygons && props.polygons.length > 1 && !edit ? (
          <div className="relative top left right align-center my12 mx12 py12 px12 bg-gray-faint round">
            <h4 className="txt-m txt-bold mb6">Select polygon shape {edit}</h4>
            <IconButton aria-label="left" onClick={handleClickRw}>
              <ChevronLeft />
            </IconButton>
            {indx + 1} ( {props.polygons.length} )
            <IconButton aria-label="right" color="primary" onClick={handleClickFw}>
              <ChevronRight />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MapboxMap;
