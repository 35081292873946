import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  landlords: [],
  landlord: null
};

const slice = createSlice({
  name: 'landlord',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getLandlordsSuccess(state, action) {
      state.isLoading = false;
      state.landlords = action.payload;
    },

    // GET POST
    getLandlordSuccess(state, action) {
      state.isLoading = false;
      state.landlord = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllLandlords() {
  console.log('getting landlords');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/landlords/index');
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getLandlordsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLandlord(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/landlords/landlord/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getLandlordSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
