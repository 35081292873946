import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Typography, Box } from '@mui/material';
//
import { PieOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';
import { PieChart } from '@mui/x-charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 300;
const LEGEND_HEIGHT = 30;

// ----------------------------------------------------------------------

export default function TruthBar({ dwell, dwellAverage, height }) {
  const theme = useTheme();
  const { translate } = useLocales();
  console.log(dwell);
  if (!dwell) return null;
  const demandarray = dwell.map((i) => Math.round(i.percentage));
  const categories = dwell.map((i) => `${i.dwell[0]} - ${i.dwell[1]}`);
  const newchartdata = [{ name: 'Dwell', data: demandarray }];
  console.log(demandarray);
  console.log(categories);
  console.log(dwellAverage);
  const midLabel = `Avg ${dwellAverage}`;
  const totalLabel = {
    show: true,
    offsetY: 8,
    label: `Avg: ${dwellAverage}`,
    color: theme.palette.text.primary,
    ...theme.typography.h3
  };

  const valueLabel = {
    show: false,
    label: dwellAverage,
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3
  };
  const chartOptions = merge(PieOptionChart(), {
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px'
    },
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            value: valueLabel,
            total: totalLabel
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: true,
            position: 'center'
          }
        }
      }
    ]
  });

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
          Dwell
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        
        <ReactApexChart type="pie" series={demandarray} options={chartOptions} height={height || CHART_HEIGHT} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
          {translate('avg')}: {dwellAverage} {translate('minutesL')}
        </Typography>
      </Box>
    </>
  );
}
