// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_API = 'https://plejsapp-api.herokuapp.com/api/v1';

// ----------------------------------------------------------------------

export const PATH_API = {
  root: ROOTS_API,
  spaces: path(ROOTS_API, '/spaces/index'),
  places: path(ROOTS_API, '/places/index'),
  placesrecent: path(ROOTS_API, '/places/recent')
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  home: '/',
  offer: '/offer',
  plejs: '/plejs',
  landing: '/landing',
  howfar: '/howfar',
  consumtionsimulator: '/consumtionsimulator',
  login: '/login',
  interest: '/interest/new',
  references: '/references',
  about: '/about-us',
  contact: '/contact-us',
  page404: '/404',
  page500: '/500',
  comingsoon: '/comingsoon',
  blog: {
    root: '/blog/posts',
    posts: '/blog/posts',
    post: '/blog/post/',
    newPost: '/blog/new-post'
  }
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/app'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    mind: path(ROOTS_DASHBOARD, '/mind'),
    forecasts: path(ROOTS_DASHBOARD, '/mind/forecasts'),
    turnovers: path(ROOTS_DASHBOARD, '/mind/turnovers'),
    mindoverview: path(ROOTS_DASHBOARD, '/mind/overview'),
    mindsales: path(ROOTS_DASHBOARD, '/mind/sales'),
    mindprojects: path(ROOTS_DASHBOARD, '/mind/projects'),
    mindutility: path(ROOTS_DASHBOARD, '/mind/utility'),
    mindliquidity: path(ROOTS_DASHBOARD, '/mind/liquidity'),
    mindcosts: path(ROOTS_DASHBOARD, '/mind/costs'),
    mindemployee: path(ROOTS_DASHBOARD, '/mind/employee'),
    minduserreview: path(ROOTS_DASHBOARD, '/mind/userreview'),
    linkedin: path(ROOTS_DASHBOARD, '/auth/linkedin/callback')
  },
  places: {
    root: path(ROOTS_DASHBOARD, '/places/places'),
    place: path(ROOTS_DASHBOARD, '/places/places/:id'),
    all: path(ROOTS_DASHBOARD, '/places/all'),
    newPlace: path(ROOTS_DASHBOARD, '/places/new-place'),
    population: path(ROOTS_DASHBOARD, '/places/population'),
    traffic: path(ROOTS_DASHBOARD, '/places/traffic'),
    aoh: path(ROOTS_DASHBOARD, '/places/aoh'),
    supply: path(ROOTS_DASHBOARD, '/places/supply'),
    catchment: path(ROOTS_DASHBOARD, '/places/catchment'),
    overview: path(ROOTS_DASHBOARD, '/places/overview'),
    consumtion: path(ROOTS_DASHBOARD, '/places/consumtion'),
    road: path(ROOTS_DASHBOARD, '/places/road'),
    dwell: path(ROOTS_DASHBOARD, '/places/dwell'),
    aoh: path(ROOTS_DASHBOARD, '/places/aoh'),
    weather: path(ROOTS_DASHBOARD, '/places/weather'),
    assistant: path(ROOTS_DASHBOARD, '/places/assistant'),
    selfserve: path(ROOTS_DASHBOARD, '/places/selfserve'),
  },
  vkplaces: {
    root: path(ROOTS_DASHBOARD, '/vkplaces/vkplaces'),
    vkplace: path(ROOTS_DASHBOARD, '/vkplaces/vkplaces/:id'),
    all: path(ROOTS_DASHBOARD, '/vkplaces/all'),
    newPlace: path(ROOTS_DASHBOARD, '/vkplaces/new-place')
  },
  spaces: {
    root: path(ROOTS_DASHBOARD, '/spaces/spaces'),
    space: path(ROOTS_DASHBOARD, '/spaces/spaces/:id'),
    site: path(ROOTS_DASHBOARD, '/spaces/sites'),
    sitecompany: path(ROOTS_DASHBOARD, '/spaces/ll'),
    sitelandlord: path(ROOTS_DASHBOARD, '/spaces/cpy'),
    all: path(ROOTS_DASHBOARD, '/spaces/all'),
    newSpace: path(ROOTS_DASHBOARD, '/spaces/new-space')
  },
  tenantable: {
    root: path(ROOTS_DASHBOARD, '/tenantable/dashboard'),
    dashboard: path(ROOTS_DASHBOARD, '/tenantable/dashboard'),
  },
  properties: {
    root: path(ROOTS_DASHBOARD, '/properties/properties'),
    property: path(ROOTS_DASHBOARD, '/properties/:id'),
    all: path(ROOTS_DASHBOARD, '/properties/all'),
    newProperty: path(ROOTS_DASHBOARD, '/properties/new-property')
  },
  companies: {
    root: path(ROOTS_DASHBOARD, '/companies/companies'),
    company: path(ROOTS_DASHBOARD, '/companies/company/:id')
  },
  companiesretail: {
    root: path(ROOTS_DASHBOARD, '/companies/companiesretail'),
    company: path(ROOTS_DASHBOARD, '/companies/company/:id')
  },
  contacts: {
    root: path(ROOTS_DASHBOARD, '/contacts/contacts'),
    contact: path(ROOTS_DASHBOARD, '/contacts/contact/:id')
  },
  needs: {
    root: path(ROOTS_DASHBOARD, '/needs/needs'),
    need: path(ROOTS_DASHBOARD, '/needs/:id'),
    oldneeds: path(ROOTS_DASHBOARD, '/needs/oldneeds'),
    match: path(ROOTS_DASHBOARD, '/needs/match'),
    matchll: path(ROOTS_DASHBOARD, '/needs/matchll'),
    new: path(ROOTS_DASHBOARD, '/needs/new'),
    newretail: path(ROOTS_DASHBOARD, '/needs/newoffice'),
    newoffice: path(ROOTS_DASHBOARD, '/needs/newretail')
  },
  logistics: {
    root: path(ROOTS_DASHBOARD, '/logistics/spaces')
  },
  actic: {
    root: path(ROOTS_DASHBOARD, '/actic/dashboard')
  },
  prjcts: {
    root: path(ROOTS_DASHBOARD, '/prjcts/prjcts'),
    prjct: path(ROOTS_DASHBOARD, '/prjcts/prjcts/:id'),
    all: path(ROOTS_DASHBOARD, '/prjcts/all'),
    market: path(ROOTS_DASHBOARD, '/prjcts/market'),
    customer: path(ROOTS_DASHBOARD, '/prjcts/customer'),
    marketnew: path(ROOTS_DASHBOARD, '/prjcts/marketnew'),
    targetgroup: path(ROOTS_DASHBOARD, '/prjcts/targetgroup'),
    supplydemand: path(ROOTS_DASHBOARD, '/prjcts/supplydemand'),
    supplydemandnew: path(ROOTS_DASHBOARD, '/prjcts/supplydemandnew'),
    truth: path(ROOTS_DASHBOARD, '/prjcts/truth'),
    bfm: path(ROOTS_DASHBOARD, '/prjcts/bfm'),
    supply: path(ROOTS_DASHBOARD, '/prjcts/supply'),
    demand: path(ROOTS_DASHBOARD, '/prjcts/demand'),
    newplace: path(ROOTS_DASHBOARD, '/prjcts/newplace'),
    population: path(ROOTS_DASHBOARD, '/prjcts/population'),
    footfall: path(ROOTS_DASHBOARD, '/prjcts/footfall'),
    footfallcomparison: path(ROOTS_DASHBOARD, '/prjcts/footfallcomparison'),
    isochrone: path(ROOTS_DASHBOARD, '/prjcts/isochrone'),
    missions: path(ROOTS_DASHBOARD, '/prjcts/missions'),
    newmission: path(ROOTS_DASHBOARD, '/prjcts/newmission'),
    mission: path(ROOTS_DASHBOARD, '/prjcts/mission/:id')
  },
  missions: {
    root: path(ROOTS_DASHBOARD, '/missions/missions'),
    newmission: path(ROOTS_DASHBOARD, '/missions/newmission'),
    mission: path(ROOTS_DASHBOARD, '/missions/mission/:id'),
    guided: path(ROOTS_DASHBOARD, '/missions/guided')
  },
  activities: {
    root: path(ROOTS_DASHBOARD, '/activities/activities'),
    newactivity: path(ROOTS_DASHBOARD, '/activities/newactivity'),
    activity: path(ROOTS_DASHBOARD, '/activities/activity/:id')
  },
  contracts: {
    root: path(ROOTS_DASHBOARD, '/contracts/contracts'),
    newcontract: path(ROOTS_DASHBOARD, '/contracts/newcontract'),
    activity: path(ROOTS_DASHBOARD, '/contracts/contract/:id')
  },
  vasakronan: {
    root: path(ROOTS_DASHBOARD, '/vasakronan'),
    overview: path(ROOTS_DASHBOARD, '/vasakronan/overview'),
    sales: path(ROOTS_DASHBOARD, '/vasakronan/sales'),
    projects: path(ROOTS_DASHBOARD, '/vasakronan/projects'),
    project: path(ROOTS_DASHBOARD, '/vasakronan/projects/:id'),
    companies: path(ROOTS_DASHBOARD, '/vasakronan/companies'),
    portfolio: path(ROOTS_DASHBOARD, '/vasakronan/portfolio'),
    contact: path(ROOTS_DASHBOARD, '/vasakronan/contact'),
    analysis: path(ROOTS_DASHBOARD, '/vasakronan/analysis'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  neo: {
    root: path(ROOTS_DASHBOARD, '/neo/overview'),
    overview: path(ROOTS_DASHBOARD, '/neo/overview'),
    inspiration: path(ROOTS_DASHBOARD, '/neo/inspiration'),

    prestudy: path(ROOTS_DASHBOARD, '/neo/prestudy'),
    employees: path(ROOTS_DASHBOARD, '/neo/prestudy/employees'),
    management: path(ROOTS_DASHBOARD, '/neo/prestudy/management'),
    currentworkplace: path(ROOTS_DASHBOARD, '/neo/prestudy/currentworkplace'),
    area: path(ROOTS_DASHBOARD, '/neo/prestudy/area'),
    market: path(ROOTS_DASHBOARD, '/neo/prestudy/market'),
    scenarios: path(ROOTS_DASHBOARD, '/neo/prestudy/scenarios'),

    concretization: path(ROOTS_DASHBOARD, '/neo/concretization'),
    directives: path(ROOTS_DASHBOARD, '/neo/concretization/directives'),
    workplacestrategy: path(ROOTS_DASHBOARD, '/neo/concretization/workplacestrategy'),
    masterplan: path(ROOTS_DASHBOARD, '/neo/concretization/masterplan'),
    program: path(ROOTS_DASHBOARD, '/neo/concretization/program'),
    requirements: path(ROOTS_DASHBOARD, '/neo/concretization/requirements'),

    solution: path(ROOTS_DASHBOARD, '/neo/solution'),
    layout: path(ROOTS_DASHBOARD, '/neo/solution/layout'),
    contracts: path(ROOTS_DASHBOARD, '/neo/solution/contracts'),
    establishment: path(ROOTS_DASHBOARD, '/neo/solution/establishment'),
    fup: path(ROOTS_DASHBOARD, '/neo/solution/fup'),
  },
  spaceopt: {
    root: path(ROOTS_DASHBOARD, '/spaceopt/overview'),
    overview: path(ROOTS_DASHBOARD, '/spaceopt/overview'),
    settings: path(ROOTS_DASHBOARD, '/spaceopt/settings'),
    descriptive: path(ROOTS_DASHBOARD, '/spaceopt/descriptive'),
    notifications: path(ROOTS_DASHBOARD, '/spaceopt/notifications'),
    prescriptive: path(ROOTS_DASHBOARD, '/spaceopt/prescriptive'),
    tr: path(ROOTS_DASHBOARD, '/spaceopt/tr'),
    segmentation: path(ROOTS_DASHBOARD, '/spaceopt/segmentation'),
    potential: path(ROOTS_DASHBOARD, '/spaceopt/potential'),
    fup: path(ROOTS_DASHBOARD, '/spaceopt/fup'),
    reviewForm: path(ROOTS_DASHBOARD, '/prjcts/tr/:id')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  google: {
    root: path(ROOTS_DASHBOARD, '/google/keywords'),
    keywords: path(ROOTS_DASHBOARD, '/google/keywords')
  },
  distance: {
    root: path(ROOTS_DASHBOARD, '/distance/wiz'),
    wiz: path(ROOTS_DASHBOARD, '/distance/wiz')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
