import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { Add, Download, IosShare, Lock, LockOpen } from '@mui/icons-material';
import { AppBar, Autocomplete, Box, Button, Chip, Dialog, DialogContent, FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
import { SelfServeForm } from '../../components/_dashboard/places';
import SettingModeMenu from '../../components/settings/SettingModeMenu';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useOffSetTop from '../../hooks/useOffSetTop';
import { getCompany } from '../../redux/slices/company';
import { getPlace, setCplace } from '../../redux/slices/place';
import { PATH_DASHBOARD } from '../../routes/paths';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 0;
/* const COLLAPSE_WIDTH = 102;
 */
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const RootStyle = styled(Stack)(({ theme }) => ({
  zIndex: 100,
  boxShadow: 'none',/* 
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile */
  backgroundColor: alpha(theme.palette.background.default, 0),
  right: 0,
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
/*     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
 */  }
}));
// minHeight: APPBAR_DESKTOP,
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  zIndex: 101,
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: 0,
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const [isExport, setIsExport] = useState(false);
  const isHome = pathname === '/';
  const { places, place, cplace } = useSelector((state) => state.place);
  const [pl, setPl] = useState(place || '')
  const [text, setText] = useState(null);
  const [readMode, setReadMode] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  console.log('read', readMode);
  const { company } = useSelector((state) => state.company);
  useEffect(() => {
    if (!user) return
    dispatch(getCompany(user.companyHubspot));
  }, [dispatch, user]);
  const handlePopoverOpenE = (event) => {
    setAnchorEl(event.currentTarget);
    setText('Exportera');
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  async function createPlace(place) {
    console.log('place, ', place);
    const url = 'https://plejsapp-api.herokuapp.com/api/v1/places/create';
    const body = JSON.stringify(place)
    if (place.name.length === 0 || place.city_name.length === 0) return;
    await axios.post(url, place)
      .then((response) => {
        console.log(response);
        if (response.statusText === 'OK') {
          return response;
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        console.log(`${PATH_DASHBOARD.places.root}/${response.data.id}`);
        dispatch(getPlace(response.data.id));

        enqueueSnackbar('Uttag registrerat', { variant: 'success' });
        setOpenDialog(false);
        updateCompany()
      })
      .catch((error) => console.log(error.message));
  };
  function updateCompany() {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/update/${company.id}`;
    const counterNew = company.counter_traffic + 1;
    if (place.is_subscribed) return;
    axios.put(url, {
      id: company.id,
      counter_traffic: counterNew
    })
      .then(() => {
        console.log('UPDATED');
        setOpenDialog(false);
      })
      .catch((error) => console.log(error.message));
  }
  const openn = Boolean(anchorEl);
   const handlread = () => {
    const newread = !readMode;
    console.log('f');
    setReadMode(newread)
   };
  return (
    <RootStyle
    >
      <ToolbarStyle>

        {
          //<LogoHori />
        }

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>

          <LanguagePopover />
          <SettingModeMenu isOffset={isOffset} isHome={isHome} />
        
          <AccountPopover company={company} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
//<JourneyPopover />