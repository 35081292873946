import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { Icon } from '@iconify/react';
import Geocode from 'react-geocode';
// material

import searchOutline from '@iconify/icons-eva/search-outline';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Chip,
  Stack,
  IconButton,
  Box,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
// utils
import fakeRequest from '../../../utils/fakeRequest';
//
import { geoConfig } from '../../../config';

import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { UploadSingleFile } from '../../upload';
//
import NewPlaceMap from './NewPlaceMap';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
const TAGS_OPTION = [
  'Retail',
  'Office',
  'Residential',
  'Urban',
  'Logistics',
  'Rural',
  'Small',
  'Large',
  'Medium',
  'XL'
];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

export default function NewPlaceForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [lng, setLng] = useState(0.0);
  const [lat, setLat] = useState(0.0);
  const [polygons, setPolygons] = useState([]);
  const [geojson, setGeojson] = useState([]);
  const [polygon, setPolygon] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [drawn, setDrawn] = useState(false);
  const [indx, setIndx] = useState(0);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [countyname, setCountyname] = useState('');
  const [countryname, setCountryname] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (polygons.length < 1) return;
    const array = [];
    for (let t = 0; t < polygons[indx].length; t += 1) {
      const polygon = polygons[indx];
      const coordi = polygon[t];
      const giveValue = function (mykey) {
        return coordi[mykey];
      };
      const lat = parseFloat(giveValue('lat'));
      const lon = parseFloat(giveValue('lon'));
      array.push([lon, lat]);
    }
    const polt = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [array]
          }
        }
      ]
    };
    setGeojson(polt);
  }, [polygons, indx]);

  function polygonToDb(polygon, drawn) {
    const array = [];
    let geomString = '';
    let arrayString = '{';
    for (let t = 0; t < polygon[0].length; t += 1) {
      const coordi = polygon[0][t];

      let latarr = coordi[0];
      let lonarr = coordi[1];
      const lon = coordi[0];
      const lat = coordi[1];
      if (!drawn) {
        const giveValue = function (mykey) {
          return coordi[mykey];
        };
        latarr = parseFloat(giveValue('lon'));
        lonarr = parseFloat(giveValue('lat'));
      }
      if (t === polygon[0].length - 1) {
        geomString += latarr;
        geomString += '	';
        geomString += lonarr;
        arrayString += '{';
        arrayString += lat;
        arrayString += ',';
        arrayString += lon;
        arrayString += '}';
        arrayString += '}';
        array.push([latarr, lonarr]);
      } else {
        geomString += latarr;
        geomString += ' ';
        geomString += lonarr;
        geomString += ',';
        arrayString += '{';
        arrayString += lat;
        arrayString += ',';
        arrayString += lon;
        arrayString += '}';
        array.push([latarr, lonarr]);
      }
    }
    const output = { poly_array: arrayString, geom: geomString, poly_str: array };
    console.log(output);
    console.log(drawn);
    return output;
  }

  const handlePolygonSaved = (drawn, polygon, bounds) => {
    setDrawn(drawn);
    setPolygon(polygon);
    setBounds(bounds);
  };
  const handleMarkerDragged = (lngat) => {
    console.log(lngat);
    setLng(lngat.lng);
    setLat(lngat.lat);
    console.log(lngat.lng);
    console.log(lngat.lat);
  };
  console.log(lng);
  const createPlace = () => {
    const url = 'https://plejsapp-api.herokuapp.com/api/v1/places/create';
    if (name.length === 0 || city.length === 0) return;
    console.log('Saving latitude');
    console.log(lat);
    console.log(lng);
    const polyData = polygonToDb(polygon, drawn);
    const body = {
      name: values.title,
      city_name: values.city,
      latitude: lat,
      longitude: lng,
      poly_array: polyData.poly_array,
      poly_str: JSON.stringify(polyData.poly_str),
      geom: polyData.geom,
      user_id: 1,
      county_id: 1,
      minlat: bounds.minlat,
      maxlat: bounds.maxlat,
      minlng: bounds.minlon,
      maxlng: bounds.maxlon,
      country_name: countryname,
      county_name: countyname
    };
    console.log(body);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        navigate(`${PATH_DASHBOARD.root}/places/place/${response.id}`);
        //  props.history.push(`/places/${response.id}`);
        //  setComplete(true);
      })
      .catch((error) => console.log(error.message));
  };

  const handleName = (name, city) => {
    if (name.length === 0 || city.length === 0) return;
    Geocode.fromAddress(name + city).then((response) => {
      const addcomp = response.results[0].address_components;
      console.log(addcomp);
      const countrytrial = addcomp.filter((comp) => comp.types[0] === 'country');
      const countytrial = addcomp.filter((comp) => comp.types[0] === 'administrative_area_level_1');
      console.log(countrytrial[0].long_name);
      console.log(countytrial[0].long_name);
      setCountyname(countytrial[0].long_name);
      setCountryname(countrytrial[0].long_name);
      const { lat, lng } = response.results[0].geometry.location;
      setLng(lng);
      setLat(lat);
      console.log('WARMING');
      console.log(lng);
      console.log(lat);
      getShapes(name, city, lat, lng);
    });
  };

  function getShapes(name, city, lat, lng) {
    const body = { name, city, lat, lng };
    const query = `https://plejsapp-api.herokuapp.com/api/v1/places/findshapes`;

    fetch(query, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setPolygons(data.geometry);
        setPolygon(data.geometry);
        setBounds(data.bounds);
        const array = [];
        for (let t = 0; t < data.geometry[indx].length; t += 1) {
          const polygon = data.geometry[indx];
          const coordi = polygon[t];
          const giveValue = function (mykey) {
            return coordi[mykey];
          };
          const lat = parseFloat(giveValue('lat'));
          const lon = parseFloat(giveValue('lon'));
          array.push([lon, lat]);
        }
        const polt = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: [array]
              }
            }
          ]
        };
        setGeojson(polt);
      });
  }

  const NewPlaceSchema = Yup.object().shape({
    name: Yup.string().required(translate('Name is required')),
    city: Yup.string().required(translate('City is required')),
    description: Yup.string(),
    cover: Yup.mixed()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      city: '',
      cover: null,
      publish: true,
      drawn: false,
      metaDescription: ''
    },
    validationSchema: NewPlaceSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await fakeRequest(500);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleBlur = () => {
    setName(values.title);
    setCity(values.city);
    handleName(values.title, values.city);
  };
  const handleClickFw = () => {
    if (indx + 1 === polygons.length) return;
    const indxplus = indx + 1;
    setIndx(indxplus);
  };
  const handleClickRw = () => {
    if (indx === 0) return;
    const indxneg = indx - 1;
    setIndx(indxneg);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('cover', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );
  console.log(polygons);
  console.log(values.drawn);
  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      fullWidth
                      label={translate('Name')}
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                    <TextField
                      fullWidth
                      label={translate('City')}
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                    <IconButton onClick={handleBlur}>
                      <Icon icon={searchOutline} color="primary.main" />
                    </IconButton>
                  </Stack>
                  <div>
                    {polygons && polygons.length > 1 && !values.drawn ? (
                      <Box sx={{ height: '20vh' }}>
                        <div className="relative top left right align-center my12 mx12 py12 px12 bg-gray-faint round">
                          <h4 className="txt-m txt-bold mb6">Select polygon shape</h4>
                          <IconButton aria-label="left" onClick={handleClickRw}>
                            <ChevronLeft />
                          </IconButton>
                          {indx + 1} ( {polygons.length} )
                          <IconButton aria-label="right" color="primary" onClick={handleClickFw}>
                            <ChevronRight />
                          </IconButton>
                        </div>
                      </Box>
                    ) : null}
                    <Box sx={{ height: '40vh' }}>
                      <NewPlaceMap
                        coords={[lng, lat]}
                        drawn={values.drawn}
                        geojsonpoly={geojson}
                        onPolygonSaved={handlePolygonSaved}
                        onMarkerDragged={handleMarkerDragged}
                      />
                    </Box>
                  </div>
                  <div>
                    <LabelStyle>{translate('Image')}</LabelStyle>
                    <UploadSingleFile
                      maxSize={3145728}
                      accept="image/*"
                      file={values.cover}
                      onDrop={handleDrop}
                      error={Boolean(touched.cover && errors.cover)}
                    />
                    {touched.cover && errors.cover && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.cover && errors.cover}
                      </FormHelperText>
                    )}
                  </div>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <div>
                    <FormControlLabel
                      control={<Switch {...getFieldProps('publish')} checked={values.publish} />}
                      label={translate('Publish')}
                      labelPlacement="start"
                      sx={{ mb: 1, mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />
                    <FormControlLabel
                      control={<Switch {...getFieldProps('drawn')} checked={values.drawn} />}
                      label={translate('Draw shape')}
                      labelPlacement="start"
                      sx={{ mb: 1, mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />
                  </div>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={5}
                    label={translate('Description')}
                    {...getFieldProps('metaDescription')}
                  />
                </Stack>
              </Card>

              <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                <LoadingButton fullWidth onClick={createPlace} variant="contained" size="large" loading={isSubmitting}>
                  {translate('Create Place')}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
