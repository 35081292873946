import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { alpha, styled } from '@mui/material/styles';

import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card, Avatar, Typography, CardContent } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import SvgIconStyle from '../../SvgIconStyle';

// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  width: '20vw',
  height: '23vh',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 24,
  color: 'inherit',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-1)
}));

const ScoreStyle = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  position: 'absolute',
  top: theme.spacing(1)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '20vw',
  height: '23vh',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  spaces: PropTypes.any.isRequired
};

export default function PotentialMap({
  place,
  population,
  catchment,
  popfigure,
  mapCh,
  onEdit,
  onSwitch,
  municipality,
  populationSwe,
  populationMuni,
  populationCounty
}) {
  const { translate } = useLocales();
  const map = useRef(null);
  const [surrounding, setSurrounding] = useState(0);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const cleanPopulation = population.map((square) => {
    const geom = square.geo.replace('POLYGON ((', '').replace('))', '');
    const splitgeom = geom.split(', ');
    const splittwicegeom = splitgeom.map((coordinates) => {
      const stringarray = coordinates.split(' ');
      const float0 = parseFloat(stringarray[0]);
      const float1 = parseFloat(stringarray[1]);
      const floatarray = [float0, float1];
      return floatarray;
    });
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline Maine.
        coordinates: [splittwicegeom]
      },
      properties: {
        population: square.DN
      }
    };
    return feature;
  });

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      // style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 14
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!map.current || !cleanPopulationNew || cleanPopulationNew.length === 0) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: cleanPopulationNew
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': {
              property: 'population',
              stops: [
                [0, '#008f00'],
                [10, '#30D5C8'],
                [20, '#FFFF00'],
                [50, '#FFD700'],
                [100, '#8b0000']
              ]
            }, // blue color fill
            'fill-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }

      const layers = ['0 - 10', '10 - 20', '20 - 50', '50 - 100', '100+'];
      const colors = ['#008f00', '#30D5C8', '#FFFF00', '#FFD700', '#8b0000'];
      const legend = document.getElementById('legend');
      layers.forEach((layer, i) => {
        if (i < 6) {
          const color = colors[i];
          const item = document.createElement('div');
          const key = document.createElement('span');
          key.style = 'display:inline-block;border-radius:20%;width:10px;height:10px;margin-right:5px;';
          key.style.backgroundColor = color;
          const value = document.createElement('span');
          value.innerHTML = `${layer}`;
          item.appendChild(key);
          item.appendChild(value);
          legend.appendChild(item);
        }
      });
      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        setSurrounding(Math.round(states[0].properties.population));
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });

  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const cleanPopulationNew = cleanPopulation;

  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Card sx={{ height: '100%', weight: '100%', borderRadius: 1 }}>
      <div id="map" style={{ width: '100%', height: '60vh' }} />
      <div
        id="legend"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          background: '#fff',
          'margin-right': 10,
          overflow: 'auto',
          'border-radius': 15,
          padding: 10,
          'line-height': 18,
          height: 120,
          'margin-bottom': 15,
          width: 140
        }}
      />
    </Card>
  );
}
