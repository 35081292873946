import React, { useRef, useEffect } from 'react';
import './map.css';
// material
import { Card } from '@mui/material';

// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

const GeoJSON = require('geojson');
// ----------------------------------------------------------------------

const PlaceMap = (props) => {
  const { place, spaces } = props;
  console.log(spaces);
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
  });

  useEffect(() => {
    let polyg = [];
    if (place.poly_str) {
      polyg = polyfunction(JSON.parse(place.poly_str));
    }
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [polyg]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.5
          }
        });
      }

      if (!map.current.getLayer('spaces')) {
        map.current.addLayer({
          id: 'spaces',
          type: 'circle',
          source: {
            type: 'geojson',
            data: storezgeojson
          },
          paint: {
            'circle-color': '#AE452B',
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
      // Initialize the marker at the query coordinates
      const el = document.createElement('div');
      el.className = 'marker';
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
      // Initialize the marker at the query coordinates
      const popup = new mapboxgl.Popup();
      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['spaces'] });
        if (!features.length) {
          popup.remove();
          return;
        }
        const feature = features[0];
        popup
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="/properties">
              ${feature.properties.name}
              </a></h5><p>
              ${feature.properties.city_name}
              </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });
  });

  useEffect(() => {
    if (map.current && map.current.getSource('spaces')) {
      map.current.getSource('spaces').setData(GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] }));
    }
  }, [spaces]);

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const storezgeojson = GeoJSON.parse(spaces, { Point: ['latitude', 'longitude'] });
  if (map.current && map.current.getSource('store')) {
    map.current.getSource('spaces').setData(storezgeojson);
  }
  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });
  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <div id="map" style={{ width: '100%', height: '70vh' }} />
    </Card>
  );
};
export default PlaceMap;
