import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useState } from 'react';
import Page from '../../components/Page';
import {
  IntroColors,
  IntroFonts,
  IntroWelcome
} from '../../components/_dashboard/intro';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------
const RootStyle = styled(Page)({
  height: '100vh'
});

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  height: '100%',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

const ChevronStyle = styled(motion.div)(() => ({
  bottom: 20,
  width: '100%',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center'
}));

const SkipStyle = styled(motion.div)(() => ({
  top: 40,
  right: 40,
  display: 'flex',
  position: 'fixed',
  justifyContent: 'flex-end'
}));
export default function IntroPage() {
  const { user } = useAuth();
  const { translate } = useLocales();
  const [step, setStep] = useState(0);
  const steps = 3;

  const handleScroll = () => {
    const anchor = document.querySelector('#main');
    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const handleFw = () => {
    console.log('click');
    if (step === steps) return;
    const newstep = step + 1;
    setStep(step + 1);
    console.log('stepup');
  };
  const handleBack = () => {
    if (step === 0) return;
    setStep(step - 1);
    console.log('stepback');
  };

  const handleSkip = () => {
    console.log('SKIP');
   //TODO: WRITE TO AUTH0
  };
  const tab = [
    <IntroWelcome displayName={user.displayName} onFw={handleFw} />,
    <IntroColors displayName={user.displayName} />,
    <IntroWelcome displayName={user.displayName} />,
    <IntroFonts displayName={user.displayName} onComplete={handleSkip}/>
  ]
  return (
    <>
      <RootStyle title="Mind NEO" id="move_top">
        <div id="main">
          <ContentStyle>
            <Grid container sx={{ p: 3, height: '100vh', my: 0 }}>
              <Grid item xs={12} md={12}>
                {tab[step]}
              </Grid>
            </Grid>
          </ContentStyle>
        </div>
      </RootStyle>
      <SkipStyle>
        <Button autoFocus onClick={handleSkip}>
          {translate('Skip')}
        </Button>
      </SkipStyle>
      <ChevronStyle>
        {step > 0 && step !== steps ? (
          <Stack direction="row" spacing={4}>
            <IconButton
            sx={{
              bottom: '5vh',
              display: 'flex',
              backgroundColor: 'white'
            }}
            aria-label="left"
            onClick={handleBack}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            sx={{
              bottom: '5vh',
              display: 'flex',
              backgroundColor: 'white'
            }}
            aria-label="left"
            onClick={handleFw}
          >
            <ChevronRight />
          </IconButton>
        </Stack>
      ) : (
        <>
          {step === steps ? (
            <IconButton
              sx={{
                bottom: '5vh',
                display: 'flex',
                backgroundColor: 'grey'
              }}
              aria-label="left"
              onClick={handleBack}
            >
              <ChevronLeft />
          </IconButton>
          ) : (
            <IconButton
              sx={{
                bottom: '5vh',
                display: 'flex',
                backgroundColor: 'white'
              }}
              aria-label="left"
              onClick={handleFw}
            >
              <ChevronRight />
            </IconButton>
          )}
        </>
      )}
      </ChevronStyle>
    </>
  );
}
