import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contact: '',
  contacts: [],
  hasMore: true,
  index: 0,
  step: 25,
  maxLength: ''
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    getAllContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload.sort((a, b) => (b.lastname < a.lastname ? 1 : -1));
    },
    getInternalContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload.sort((a, b) => (b.lastname < a.lastname ? 1 : -1));
    },
    // GET POST INFINITE
    getContacts(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.contacts = [...state.contacts, ...action.payload.contacts];
      state.maxLength = action.payload.rowcount;
    },
    // GET POST INFINITE
    getContactsZero(state, action) {
      state.isLoading = false;
      console.log('zero');
      console.log(action.payload);
      state.companies = action.payload.results;
    },
    getMoreContacts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    noHasMore(state) {
      state.hasMore = false;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { getMoreContacts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllContacts() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/contacts/index');
      dispatch(slice.actions.getAllContactsSuccess(response.data));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchContacts(query) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/contacts/searching',
        params: {
          query,
        }
      });
      dispatch(slice.actions.getInternalContactsSuccess(response));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getInternalContacts() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/contacts/internalindex');
      dispatch(slice.actions.getInternalContactsSuccess(response));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContacts(index, step) {
  return async (dispatch) => {
    
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/contacts/more',
        params: {
          index,
          step,
        }
      });
      console.log(response);
      const results = index + response.data.contacts.length;
      const { rowcount } = response.data;
      dispatch(slice.actions.getContacts(response.data));
      if (results >= rowcount) {
        dispatch(slice.actions.noHasMore());
      } 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/contacts/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getContactSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}