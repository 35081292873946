import { Box, Card, Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const xoldLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function DashedLineChart({ data, rainyDays, rainyPercentage, averageTemp }) {
  if (!data) return;
  console.log('trend data', data);
  const values = data.temperature2mMean;
  const percvalues = data.precipitationHours;
  const xLabels = data.time.map((d) => new Date(d));
  const zeroes = data.time.map((d) => 0);

  return (
    <Stack>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant='h4'>
                {Math.round(averageTemp * 10) / 10 || 0} C
              </Typography>

              <Typography variant='subtitle2'>
                Genomsnittlig temperatur
              </Typography>
            </Box>
          </Stack>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant='h4'>
                {Math.round(rainyDays) || 0} ({Math.round(rainyPercentage) * 100}%)
              </Typography>

              <Typography variant='subtitle2'>
                Dagar med nederbörd
              </Typography>

            </Box>

          </Stack>
        </Card>
      </Stack>
      <LineChart
        height={400}
        series={[
          {
            data: values, label: 'Temperatur (C)', id: 'pvId',
            showMark: false,
            connectNulls: false, yAxisKey: 'leftAxisId'
          },
          {
            data: percvalues, label: 'Nederbörd (mm)', id: 'uvIdc',
            showMark: false,
            connectNulls: false, yAxisKey: 'rightAxisId',
          },
          {
            data: zeroes, label: '0', id: 'uvId',
            showMark: false,
            connectNulls: false, yAxisKey: 'leftAxisId'
          },
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels, valueFormatter: (date) => `${date.toString().slice(0, 2)} ${date.toLocaleDateString().slice(0, -5)} - ${date.toLocaleTimeString().slice(0, -3)}`, }]}
        yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
        rightAxis="rightAxisId"
        sx={{
          '.MuiLineElement-root, .MuiMarkElement-root': {
            strokeWidtharray: '1 4',
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-uvIdc': {
            strokeDasharray: '4 4 4 1',
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '4 1',
          },
          '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
            fill: '#fff',
          },
          '& .MuiMarkElement-highlighted': {
            stroke: 'none',
          },
        }}
      />
    </Stack>
  );
}