import { Box, Button, Divider, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import MyAvatar from '../../components/MyAvatar';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({company}) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        <Box sx={{ my: 1.5, px: 2.5 }}>
        <Typography variant="subtitle1" noWrap>
          Uttag
          </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Flöden: {(
                company?.counter_traffic || 0
              )} 
            </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Folkmängd: {(
                company?.counter_population || 0
              )} 
            </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Dwell: {(
                company?.counter_dwell || 0
              )} 
            </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Konsumtion: {(
                company?.counter_consumtion || 0
              )} 
            </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Upptagning: {(
                company?.counter_catchment || 0
              )} 
            </Typography>
            <Typography gutterBottom variant="caption" sx={{ display: 'block' }}>
              Väder: {(
                company?.counter_population || 0
              )} 
            </Typography>
          </Box>
        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
