import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { styled } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

const CHART_DATA = [67, 84, 97, 61];

export default function ThreesixtyRadial() {
  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      height: 280,
      type: 'radialBar'
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: true,
            label: 'SCORE'
          }
        }
      }
    },
    labels: ['Users', 'Owners', 'Tenants', 'Mind']
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="radialBar" series={CHART_DATA} options={chartOptions} height={340} />
    </ChartWrapperStyle>
  );
}
