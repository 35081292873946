import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card, Avatar, Typography, CardContent } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import SvgIconStyle from '../../SvgIconStyle';

import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

const GeoJSON = require('geojson');
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  width: '15vw',
  height: '15vh',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 24,
  color: 'inherit',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const ScoreStyle = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '15vw',
  height: '15vh',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  traffic: PropTypes.any.isRequired,
  catchment: PropTypes.any.isRequired
};

export default function PotentialMap(props) {
  const { translate } = useLocales();
  const { place, traffic, catchment, onSquareClicked, trafficfigure, newArray } = props;
  const map = useRef(null);
  const theme = useTheme();
  const [surroundingVisitors, setSurroundingVisitors] = useState(0);
  const [counter, setCounter] = useState(0);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;

  const COLORS = [
    theme.palette.success.main,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.success.dark,
    theme.palette.primary.dark,
    theme.palette.secondary.dark,
    theme.palette.error.dark,
    theme.palette.warning.dark,
    theme.palette.success.light,
    theme.palette.primary.light,
    theme.palette.secondary.light,
    theme.palette.error.light,
    theme.palette.warning.light
  ];

  const cleanTraffic = traffic.map((square) => {
    const geom = square.geom.replace('POLYGON((', '').replace('))', '');
    const splitgeom = geom.split(', ');
    const splittwicegeom = splitgeom.map((coordinates) => {
      const stringarray = coordinates.split(' ');
      const float0 = parseFloat(stringarray[0]);
      const float1 = parseFloat(stringarray[1]);
      const floatarray = [float0, float1];
      return floatarray;
    });
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline Maine.
        coordinates: [splittwicegeom]
      },
      properties: {
        visitors: square.visitors,
        geoid: square.geoid
      }
    };
    return feature;
  });

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat],
      zoom: 13
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!map.current || !cleanTrafficNew || cleanTrafficNew.length === 0) return; // wait for map to initialize
    map.current.on('load', () => {
      // Add an image to use as a custom marker
      map.current.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error, image) => {
        if (error) throw error;
        map.current.addImage('custom-marker', image);
        // Add a GeoJSON source with 2 points
        map.current.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        // Add a symbol layer
        map.current.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'points',
          layout: {
            'icon-image': 'custom-marker'
          }
        });
      });
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: cleanTrafficNew
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }
      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 12,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 12, 0, 12.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 12, 0, 12.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': {
              property: 'visitors',
              stops: [
                [0, '#008f00'],
                [1000, '#30D5C8'],
                [2000, '#FFFF00'],
                [5000, '#FFD700'],
                [10000, '#8b0000']
              ]
            }, // blue color fill
            'fill-opacity': 0.6
          }
        });
        map.current.addLayer({
          id: 'trafficline',
          type: 'line',
          source: 'maine', // reference the data source
          layout: { visibility: 'none' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!map.current.getLayer('markers')) {
        map.current.addLayer({
          id: 'markers',
          type: 'point',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        setSurroundingVisitors(Math.round(states[0].properties.visitors));
      });
      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['points']
        });
        console.log(states);
      });
      map.current.on('click', (event) => {
        setCounter(counter + 1);
        console.log(event.lngLat);
        const count = counter + 1;
        const intr = Math.floor(Math.random() * 7);
        const markerNew = new mapboxgl.Marker({
          color: COLORS[intr],
          symbol: intr
        });
        const coordsz = event.lngLat;
        console.log('Lng:', coordsz.lng, 'Lat:', coordsz.lat);
        // markerNew.setLngLat(coordsz).addTo(map.current);
        const coordinates = event.lngLat;
        const location = { lng: coordinates.lng, lat: coordinates.lat, color: intr };
        const data = [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [coordinates.lng, coordinates.lat]
            }
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [coordinates.lat, coordinates.lng]
            }
          }
        ];
        const datatwo = GeoJSON.parse(coordinates, { Point: ['lat', 'lng'] });
        map.current.getSource('points').setData(data);
        console.log(counter);
        setCounter(counter + 1);
        onSquareClicked(location);
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const cleanTrafficNew = cleanTraffic;
  console.log(counter);
  if (map.current) {
    const mapOutcome = newArray.map((f, index) => {
      const markerN = new mapboxgl.Marker({
        color: COLORS[index]
      });
      console.log(f);
      markerN.setLngLat(f.lngLat);
      console.log(markerN);
      markerN.addTo(map.current);
      return markerN;
    });
  }

  const intr = Math.floor(Math.random() * 7);
  const marker = new mapboxgl.Marker({
    color: COLORS[intr]
  });
  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <Box>
        <div id="map" style={{ width: '100%', height: '90vh' }} />
        <Card
          sx={{
            position: 'absolute',
            zIndex: 9,
            top: 0,
            left: 0,
            width: '15vw',
            height: '15vh',
            background: '#fff',
            'margin-left': 10,
            'margin-top': 10,
            ...(!trafficfigure && {
              zIndex: -1
            })
          }}
        >
          <CardMediaStyle
            sx={{
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '15vw',
                height: '15vh',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }}
          >
            <ScoreStyle variant="h2" sx={{ color: 'common.white', zIndex: 9, top: 24, left: 24 }}>
              {surroundingVisitors}
            </ScoreStyle>
            <CoverImgStyle alt="Background" src="/static/mock-images/feeds/feed_bg.png" />
          </CardMediaStyle>

          <CardContent
            sx={{
              pt: 4,
              bottom: 0,
              width: '100%',
              position: 'absolute'
            }}
          >
            <TitleStyle sx={{ color: 'common.white' }}>{translate('Footfall (per day)')}</TitleStyle>
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
}
