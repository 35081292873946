import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Typography, Box, TextField, Stack } from '@mui/material';
//
import { fPercent } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../charts';
import LoadingScreen from '../../LoadingScreen';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const GRAPH_TYP = ['index', 'population'];

export default function PopulationIdxGraph({ series, muni, height }) {
  const { translate } = useLocales();
  const [graphType, setGraphType] = useState('index');
  const categories = series.map((s) => s.year);
  const placeIndex = series.map((s) => s.index);
  const placePop = series.map((s) => s.population);
  const muniIndex = series.map((s) => s.index);
  const muniPop = series.map((s) => s.population);
  const countyIndex = series.map((s) => s.indexC);
  const countyPop = series.map((s) => s.populationC);
  const countryIndex = series.map((s) => s.indexCc);
  const countryPop = series.map((s) => s.populationCc);
  const chartData = [
    {
      type: 'index',
      data: [
        { name: translate('Place'), data: placeIndex },
        { name: translate(muni), data: muniIndex },
        { name: translate('County'), data: countyIndex },
        { name: translate('Sweden'), data: countryIndex }
      ]
    },
    {
      type: 'population',
      data: [
        { name: translate('Place'), data: placePop },
        { name: translate(muni), data: muniPop },
        { name: translate('County'), data: countyPop },
        { name: translate('Sweden'), data: countryPop }
      ]
    }
  ];

  const handleChangeGraphType = (event) => {
    setGraphType(event.target.value);
  };

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories
    }
  });
  return (
    <Card>
      <Typography variant="h5"> {translate('Population Growth')} </Typography>

      <CardHeader
        action={
          <TextField
            select
            fullWidth
            value={graphType}
            SelectProps={{ native: true }}
            onChange={handleChangeGraphType}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
              '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
              '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
            }}
          >
            {GRAPH_TYP.map((option) => (
              <option key={option} value={option}>
                {translate(option)}
              </option>
            ))}
          </TextField>
        }
      />

      {chartData.map((item) => (
        <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.type === graphType && (
            <ReactApexChart type="line" series={item.data} options={chartOptions} height={height} />
          )}
        </Box>
      ))}
    </Card>
  );
}
