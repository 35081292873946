import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import { Link as RouterLink } from 'react-router-dom';
import { geoConfig } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../SvgIconStyle';
import {
  PlaceCardMap
} from '../prjcts';

Geocode.setApiKey(geoConfig);
// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
});

const TitleStyle = styled(RouterLink)(({ theme }) => ({
  ...theme.typography.h5,
  height: 24,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));


const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

// ----------------------------------------------------------------------

PlaceCard.propTypes = {
  place: PropTypes.object.isRequired,
  index: PropTypes.number
};

export default function PlaceCard({ place, onDelete, isEdit }) {
  const { id, property_name: propertyName, municipality, county, address, city } = place;
  const linkTo = `${PATH_DASHBOARD.vkplaces.root}/${id}`;
  const { user } = useAuth();
  const updatePlace = (latitude, longitude) => {
    console.log('latitude', latitude);
    const url = `https://plejsapp-api.herokuapp.com/api/v1/vkplaces/update/${place.id}`;
    axios.put(url, {
      id: parseInt(id),
      latitude,
      longitude
    })
      .then((response) => {
        console.log('UPDATED');
      })
      .catch((error) => console.log(error.message));

  };
   /* {
  if (!place.latitude || !place.longitude) {
    console.log('geocode', place.address);
    Geocode.fromAddress(address + '%20' + city)
    .then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      updatePlace(lat, lng);
    })
  }
 } */
  

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ position: 'relative', borderRadius: 1 }}>
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute'
            }}
          />
          <AvatarStyle alt={user.displayName} src={user.avatarUrl} />
          <PlaceCardMap
              place={place}
            />
        </CardMediaStyle>

        <CardContent sx={{ pt: 4 }}>
          <Box>
            <TitleStyle to={linkTo}>{propertyName}</TitleStyle>
            <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
              {city} {municipality}
            </Typography>
            {isEdit && (
              <IconButton 
                sx={{ display: 'flex', position: 'absolute', bottom: 3, right: 3 }}
                onClick={onDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
