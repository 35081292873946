import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Card} from '@mui/material';
import $ from 'jquery';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PlaceCardMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PlaceCardMap(props) {
  const { place, modeprev, minutesprev, catchment } = props;
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [minuter, setMinuter] = useState(minutesprev || '10');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: `${place.id}`, // container id
      style: 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 12
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });

  useEffect(() => {
    if (!catchment) return;
    // map.current.getSource('iso').setData(catchment);
  }, [catchment]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {

      const lngLat = { lng, lat };
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
    });
  });

  const polyfunction = (poly) => {
    if (poly.length < 2) {
      return;
    }

    const result = [];
    for (let i = 0; i < poly.length; i += 1) {
      const lon = parseFloat(poly[i][0]);
      const lat = parseFloat(poly[i][1]);

      result.push([lon, lat]);
    }

    return result;
  };
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  let profile = mode;
  let minutes = minuter;
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });


  return (
    <Card sx={{ weight: '100%', borderRadius: 1 }}>
        <div id={`${place.id}`} style={{ width: '100%', height: '25vh' }} />     
    </Card>
  );
}
