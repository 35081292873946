import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  needs: [],
  need: null
};

const slice = createSlice({
  name: 'demand',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getNeedsSuccess(state, action) {
      state.isLoading = false;
      state.needs = action.payload;
    },

    // GET POST
    getNeedSuccess(state, action) {
      state.isLoading = false;
      state.need = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreNeeds } = slice.actions;

// ----------------------------------------------------------------------

export function getAllNeeds() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/needs/index');
      dispatch(slice.actions.getNeedsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addNeed(need) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://plejsapp-api.herokuapp.com/api/v1/needs/create', need);
      console.log('Need created');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateNeed(need) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/needs/update/${need.id}`, {
        body: { need }
      });
      console.log('Updated need: ', response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNeed(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/needs/need', {
        params: { id }
      });
      console.log('Needs fetched: ', response.data);
      dispatch(slice.actions.getNeedSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
