import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { alpha, styled, useTheme } from '@mui/material/styles';

import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card, Avatar, Typography, CardContent } from '@mui/material';
// mapbox
import SvgIconStyle from '../../SvgIconStyle';
import { PieOptionChart } from '../../charts';

// routes
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  width: '100%',
  height: '28vh',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 24,
  color: 'inherit',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const ScoreStyle = styled(Typography)(({ theme }) => ({
  zIndex: 9,
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '28vh',
  objectFit: 'cover',
  position: 'absolute'
});
// ----------------------------------------------------------------------

const CHART_HEIGHT = 130;
const LEGEND_HEIGHT = 30;

MapCard.propTypes = {
  location: PropTypes.object.isRequired
};

export default function MapCard({ location, index }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { number, address, dwell, dwellPercentage } = location;
  const COLORS = [
    theme.palette.success.main,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.success.dark,
    theme.palette.primary.dark,
    theme.palette.secondary.dark,
    theme.palette.error.dark,
    theme.palette.warning.dark,
    theme.palette.success.light,
    theme.palette.primary.light,
    theme.palette.secondary.light,
    theme.palette.error.light,
    theme.palette.warning.light
  ];
  const demandarray = dwellPercentage.map((i) => Math.round(i.percentage));
  const categories = dwellPercentage.map((i) => `${i.dwell[0]} - ${i.dwell[1]}`);
  const newchartdata = [{ name: 'Dwell', data: demandarray }];
  console.log(demandarray);
  console.log(categories);
  console.log(dwell);
  const midLabel = `Avg ${dwell}`;
  const totalLabel = {
    show: true,
    offsetY: 8,
    label: `Avg: ${dwell}`,
    color: COLORS[index],
    ...theme.typography.h3
  };

  const valueLabel = {
    show: false,
    label: dwell,
    offsetY: 8,
    color: COLORS[index],
    ...theme.typography.h3
  };
  const chartOptions = merge(PieOptionChart(), {
    legend: {
      show: false,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px'
    },
    labels: categories,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            value: valueLabel,
            total: totalLabel
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: true,
            position: 'center'
          }
        }
      }
    ]
  });

  console.log(location);
  return (
    <Card
      sx={{
        zIndex: 9,
        width: '100%',
        height: '28vh',
        background: '#fff'
      }}
    >
      <ReactApexChart type="pie" series={demandarray} options={chartOptions} height={CHART_HEIGHT} />
      <ScoreStyle variant="h2" sx={{ color: COLORS[index], zIndex: 9, top: CHART_HEIGHT - 20, left: 24, pb: 1 }}>
        {number}
      </ScoreStyle>

      <CardContent
        sx={{
          pt: 4,
          bottom: 0,
          width: '100%',
          position: 'absolute'
        }}
      >
        <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
          {translate('Average dwell')} {dwell || 0}
        </Typography>
        <TitleStyle sx={{ color: COLORS[index] }}>
          {index + 1}. {address}
        </TitleStyle>
      </CardContent>
    </Card>
  );
}
