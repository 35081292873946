import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import saveOutline from '@iconify/icons-eva/save-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select
} from '@mui/material';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import LoadingScreenSmall from '../../LoadingScreenSmall';
const GeoJSON = require('geojson');

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PlaceCompetitionMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PlaceCompetitionMap({ place, competition, onSave, modeprev, minutesprev, loading }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const array = [];
  const [minuter, setMinuter] = useState(minutesprev || '5');
  const [mode, setMode] = useState(modeprev || 'walking');

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'supplymap', // container id
      // style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();
  });


  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on('style.load', () => {
      map.current.setConfigProperty('basemap', 'lightPreset', 'dawn');
      map.setConfigProperty('basemap', 'showTransitLabels', true);
    });

    map.current.on('load', () => {

      const sourceIdIso = 'iso';
      const sourceiso = map.current.getSource(sourceIdIso);
      if (!sourceiso) {
        map.current.addSource('iso', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }
      if (!map.current.getLayer('isoLayer')) {
        map.current.addLayer(
          {
            id: 'isoLayer',
            type: 'fill',
            source: 'iso',
            layout: {},
            paint: {
              'fill-color': ['get', 'fillColor']
              ,
              'fill-opacity': 0.3
            }
          }
        );
      }
      if (!map.current.getLayer('isoOutline')) {
        map.current.addLayer({
          id: 'isoOutline',
          type: 'line',
          source: 'iso',
          layout: { visibility: 'visible' },
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 2
          }
        });
      }


      // When the map loads, add the source and layer
      if (!map.current.getSource('origins')) {
        map.current.addSource('origins', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }
      map.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      /*
      map.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': {
            property: 'rating',
            stops: [
              [1, 'red'],
              [2, 'orange'],
              [3, 'yellow'],
              [4, 'blue'],
              [5, 'green']
            ]
          },
          'line-width': 3
        }
      });
      */

      // When the map loads, add the source and layer
      if (!map.current.getLayer('origins')) {
        map.current.addLayer({
          id: 'origins',
          type: 'circle',
          source: 'origins',
          paint: {
            'circle-color': {
              property: 'rating',
              stops: [
                [1, 'red'],
                [2, 'orange'],
                [3, 'yellow'],
                [4, 'blue'],
                [5, 'green']
              ]
            },
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }

      const lngLat = { lng, lat };
      const popup = new mapboxgl.Popup();
      marker.setLngLat(lngLat).addTo(map.current);
      // make a marker for each feature and add to the map
      // Make the API call
      getIso();


      map.current.on('mousemove', (event) => {
        const features = map.current.queryRenderedFeatures(event.point, {
          layers: ['origins']
        });

        if (!features.length) {
          popup.remove();
          return;
        }
        popup
          .setLngLat(features[0].geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="https://maps.app.goo.gl/${features[0].properties.place_id}">
            ${features[0].properties.name}
            </a></h5><p>
            Rating: ${features[0].properties.rating} (${features[0].properties.user_ratings_total} omdömen)
            </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });

  });

  useEffect(() => {
    if (!map.current || !map.current.getSource('origins')) return;
    const points = GeoJSON.parse(competition, { Point: ['lat', 'lng'], include: ['rating', 'name', 'user_ratings_total', 'place_id'] });
    map.current.getSource('origins').setData(points);
    if (!place) return;
    const coords = [place.longitude, place.latitude];
    let lineArr = [];
    const linesz = competition.map((o) => {
      const lineCoords = { geo: { type: 'LineString', coordinates: [coords, [o.lng, o.lat]] }, ...o };
      return lineCoords;
    })

    const lines = GeoJSON.parse(linesz, { GeoJSON: 'geo', include: ['rating'] });
    map.current.getSource('route').setData(lines);

  }, [competition]);
  const urlBase = 'https://api.mapbox.com/isochrone/v1/mapbox/';
  let profile = mode;
  let minutes = minuter;
  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#AF452B'
  });
  function getIso() {
    const query = `${urlBase}${profile}/${lng},${lat}?contours_minutes=${minutes}&generalize=100&polygons=true&access_token=${mapboxgl.accessToken}`;
    $.ajax({
      method: 'GET',
      url: query
    }).done((data) => {
      // Set the 'iso' source's data to what's returned by the API query
      map.current.getSource('iso').setData(data);
    });
  }

  const onChange = (e) => {
    profile = e.target.value;
    setMode(e.target.value);
    getIso();
  };

  const onChangeDuration = (e) => {
    minutes = e.target.value;
    setMinuter(e.target.value);
    getIso();
  };

  const handleSave = () => {
    onSave(mode, minuter);
  };
  return (
    <Card sx={{ borderRadius: 1 }}>
      <div id="supplymap" style={{ width: '100%', height: '70vh' }} />
      <Card
        sx={{
          display: 'flex',
          position: 'absolute',
          borderRadius: 1,
          zIndex: 9,
          top: 0,
          left: '30%',
          right: '30%',
          background: '#fff',
          justifyContent: 'center',
          'margin-top': 10
        }}
      >

        {loading && (
          <LoadingScreenSmall />
        )}
      </Card>
    </Card>
  );
}
