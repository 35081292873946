import { Box, Card, Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

export default function TrendTempFootfall({ data, footfall, weatherRobust }) {
  if (!data) return;
  console.log('TempFootfall data', data, footfall);
  const values = data.temperature2mMean;
  const averageTemp = data.temperature2mMean.reduce((a, v) => (a += v), 0) / data.temperature2mMean.length;
  const percvalues = data.precipitationHours;
  const footfallvalues = footfall.map((f) => f.visits);
  const xLabels = data.time.map((d) => new Date(d));
  const zeroes = data.time.map((d) => 0);

  return (
    <Stack>

      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
              <Typography variant='h4'>
                {Math.round(weatherRobust) || 0} 
              </Typography>

              <Typography variant='subtitle2'>
                Väderfaktor
              </Typography>

            </Box>

          </Stack>
        </Card>
      </Stack>
      <LineChart
        height={400}
        series={[
          {
            data: values, label: 'Temperatur (C)', id: 'pvId',
            showMark: false,
            connectNulls: false, yAxisKey: 'leftAxisId'
          },
          {
            data: footfallvalues, label: 'Besökare/dag', id: 'uvIdc',
            showMark: false,
            connectNulls: false, yAxisKey: 'rightAxisId',
          },
          {
            data: zeroes, label: '0', id: 'uvId',
            showMark: false,
            connectNulls: false, yAxisKey: 'leftAxisId'
          },
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels, valueFormatter: (date) => `${date.toString().slice(0, 2)} ${date.toLocaleDateString().slice(0, -5)} - ${date.toLocaleTimeString().slice(0, -3)}`, }]}
        yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
        rightAxis="rightAxisId"
        sx={{
          '.MuiLineElement-root, .MuiMarkElement-root': {
            strokeWidtharray: '1 4',
          },
          '.MuiLineElement-series-pvId': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-uvIdc': {
            strokeDasharray: '0 0',
          },
          '.MuiLineElement-series-uvId': {
            strokeDasharray: '4 1',
          },
          '.MuiMarkElement-root:not(.MuiMarkElement-highlighted)': {
            fill: '#fff',
          },
          '& .MuiMarkElement-highlighted': {
            stroke: 'none',
          },
        }}
      />
    </Stack>
  );
}