import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport,
  GridActionsCellItem,
  gridClasses,
  LicenseInfo
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { Box, Button, Snackbar, Alert, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';
import { gridConfig } from '../../../config';

LicenseInfo.setLicenseKey(gridConfig);

const classifyPoint = require('robust-point-in-polygon');

const PRICES = ['Budget', 'Mid', ' Premium', 'Luxury'];

export default function LoadedCompetition({ tenantz, spacesOsm, city, spacetypes, onChange, footfall, hClick }) {
  const { translate } = useLocales();
  const apiRef = useGridApiRef();
  const [tenants, setTenants] = useState(tenantz);
  const [content, setContent] = useState('');
  const [openSnackDelete, setOpenSnackDelete] = useState(false);
  const [loadingFootfall, setLoadingFootfall] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  useEffect(() => {
    setTenants(tenantz);
  }, [tenantz]);

  useEffect(() => {
    if (content) return;
    setContent(spacesOsm);
  }, [spacesOsm]);

  useEffect(() => {
    if (!content || !loadingFootfall) return;

    for (let t = 0; t < content.length; t += 1) {
      const item = content[t];
      if (!item.footfall && item.latitude && item.longitude) {
        addFootfallToEmpty(item.latitude, item.longitude, item.id);
      }
      if (!item.address && item.latitude && item.longitude) {
        addAddressToEmpty(item.latitude, item.longitude, item.id);
      }
    }
    setLoadingFootfall(false);
  }, [content]);
  if (!content) return <LoadingScreen />;
  async function getCoordinates(address, city) {
    return Geocode.fromAddress(address + city);
  }
  async function getAddress(lat, lng) {
    return Geocode.fromLatLng(lat, lng);
  }
  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          {translate('Add record')}
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleClick = () => {
    createRow();
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  async function createRow() {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/tenants/create`;
    const newActivity = {
      name: 'Unknown',
      company_id: 1
    };
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newActivity)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        setOpenSnack(true);
        if (!content) {
          setContent([response]);
          setTenants([response]);
        } else {
          setContent((prevRows) => [response, ...prevRows]);
          setTenants((prevRows) => [response, ...prevRows]);
        }
      })
      .catch((error) =>  console.log(error.message));
  }

  async function updateFields(r, f, lat, lng, footfall) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/tenants/update/${r.id}`;
    const coords = lat ? `SRID=4326;POINT(${lng} ${lat})` : null;
    const body = {
      tenant: r,
      [f]: r[f],
      latitude: lat,
      longitude: lng,
      coords,
      footfall
    };
    // console.log(body);
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        // console.log('UPDATED');
        setOpenSnack(true);
      })
      .catch((error) => console.log(error.message));
  }

  const columns = [
    { field: 'id', headerName: translate('Id') },
    { field: 'name', headerName: translate('Name'), width: 180, editable: true },
    { field: 'address', headerName: translate('Address'), type: 'string', editable: true },
    {
      field: 'industry',
      headerName: translate('Industry'),
      type: 'singleSelect',
      valueOptions: spacetypes,
      editable: true
    },
    {
      field: 'price',
      headerName: translate('Price'),
      type: 'singleSelect',
      valueOptions: PRICES,
      editable: true
    },
    { field: 'sqm', headerName: translate('Sqm'), type: 'number', editable: true },
    { field: 'turnover', headerName: translate('Turnover'), type: 'number', editable: true },
    { field: 'footfall', headerName: translate('Footfall'), type: 'string' },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
      ]
    }
  ];
  async function addFootfallToEmpty(latitude, longitude, id) {
    const lat = latitude;
    const lng = longitude;
    // console.log();
    const nn = footfall[0].geom
      .slice(9)
      .slice(0, -2)
      .replace(/, /g, ';')
      .replace(/,/g, ';')
      .replace(/ /g, ',')
      .split(';');
    const nnn = nn.map((n) => n.split(','));
    // console.log(nnn);
    const footfallSquare = footfall.find((p) => {
      const cleanarray = p.geom
        .slice(9)
        .slice(0, -2)
        .replace(/, /g, ';')
        .replace(/,/g, ';')
        .replace(/ /g, ',')
        .split(';');
      const nnn = cleanarray.map((n) => n.split(','));
      return classifyPoint(nnn, [lng, lat]) < 1;
    });
    // console.log(footfallSquare);
    const itemIndex = content.findIndex((c) => c.id === id);
    const newContent = update(content, {
      [itemIndex]: {
        footfall: {
          $set: footfallSquare.visitors
        }
      }
    });
    const newItem = newContent[itemIndex];
    // console.log('SSS');
    onChange(newContent);
    // console.log('New content: ', newContent);
    setContent(
      update(content, {
        [itemIndex]: {
          footfall: {
            $set: footfallSquare.visitors
          }
        }
      })
    );
    const url = `https://plejsapp-api.herokuapp.com/api/v1/tenants/update/${id}`;
    const body = {
      footfall: footfallSquare.visitors
    };
    // console.log(body);
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        // console.log('UPDATED');
        setOpenSnack(true);
      })
      .catch((error) => console.log(error.message));
  }
  async function addAddressToEmpty(latitude, longitude, id) {
    const lat = latitude;
    const lng = longitude;
    // console.log();
    const address = await getAddress(lat, lng).then((response) => {
      let streetAddress;
      let number;
      for (let i = 0; i < response.results[0].address_components.length; i += 1) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j += 1) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'route':
              streetAddress = response.results[0].address_components[i].long_name;
              break;
            case 'street_number':
              number = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      // console.log(streetAddress, number);
      const adding = `${streetAddress} ${number}`;
      const itemIndex = content.findIndex((c) => c.id === id);
      const newContent = update(content, {
        [itemIndex]: {
          address: {
            $set: adding
          }
        }
      });
      const newItem = newContent[itemIndex];
      // console.log('SSS');
      onChange(newContent);
      // console.log('New content: ', newContent);
      setContent(
        update(content, {
          [itemIndex]: {
            address: {
              $set: adding
            }
          }
        })
      );
      const url = `https://plejsapp-api.herokuapp.com/api/v1/tenants/update/${id}`;
      const body = {
        address: adding
      };
      // console.log(body);
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })

        .then((response) => {
          // console.log('UPDATED');
          setOpenSnack(true);
        })
        .catch((error) => console.log(error.message));
    });
  }
  const handleEdit = async (params) => {
    if (params.field === 'address') {
      const address = params.value;
      const coordinates = await getCoordinates(params.value, city).then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        // console.log(footfall[0].geom.slice(9).slice(0, -2));
        // console.log(footfall[0].geom.slice(9).slice(0, -2).replace(/,/g, ';'));
        // console.log(footfall[0].geom.slice(9).slice(0, -2).replace(/, /g, ';').replace(/,/g, ';').replace(/ /g, ','));
        const nn = footfall[0].geom
          .slice(9)
          .slice(0, -2)
          .replace(/, /g, ';')
          .replace(/,/g, ';')
          .replace(/ /g, ',')
          .split(';');
        const nnn = nn.map((n) => n.split(','));
        // console.log(nnn);
        const footfallSquare = footfall.find((p) => {
          const cleanarray = p.geom
            .slice(9)
            .slice(0, -2)
            .replace(/, /g, ';')
            .replace(/,/g, ';')
            .replace(/ /g, ',')
            .split(';');
          const nnn = cleanarray.map((n) => n.split(','));
          return classifyPoint(nnn, [lng, lat]) < 1;
        });
        // console.log(footfallSquare);
        const itemIndex = content.findIndex((c) => c.id === params.id);
        const p = params.field;
        // console.log(itemIndex);
        // console.log(content[itemIndex]);
        // console.log(params);
        const newContent = update(content, {
          [itemIndex]: {
            [p]: {
              $set: params.value
            },
            latitude: {
              $set: lat
            },
            longitude: {
              $set: lng
            },
            footfall: {
              $set: footfallSquare.visitors
            }
          }
        });
        const newItem = newContent[itemIndex];
        // console.log('SSS');
        onChange(newContent);
        // console.log('New content: ', newContent);
        setContent(
          update(content, {
            [itemIndex]: {
              [p]: {
                $set: params.value
              },
              latitude: {
                $set: lat
              },
              longitude: {
                $set: lng
              },
              footfall: {
                $set: footfallSquare.visitors
              }
            }
          })
        );
        updateFields(newItem, p, lat, lng, footfallSquare.visitors);
      });
    } else {
      const itemIndex = content.findIndex((c) => c.id === params.id);
      const p = params.field;
      const newContent = update(content, {
        [itemIndex]: {
          [p]: {
            $set: params.value
          }
        }
      });
      const newItem = newContent[itemIndex];
      // console.log('New content: ', newContent);
      // console.log('SSS');
      onChange(newContent);
      setContent(
        update(content, {
          [itemIndex]: {
            [p]: {
              $set: params.value
            }
          }
        })
      );

      updateFields(newItem, p, newItem.latitude, newItem.longitude, newItem.footfall);
    }
  };
  // console.log(tenants);
  // console.log('content new: ', content);
  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.updateRows([{ id, _action: 'delete' }]);
    // console.log(id);
    deleteField(id);
  };

  const deleteField = (id) => {
    // console.log(id);
    const url = `https://plejsapp-api.herokuapp.com/api/v1/tenants/delete/${id}`;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        // console.log('Deleted');
        setOpenSnackDelete(true);
        //  props.history.push(`/places/${response.id}`);
        //  setComplete(true);
      })
      .catch((error) => console.log(error.message));
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: 800 }}>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
          {translate('updatesSaved')}
        </Alert>
      </Snackbar>
      <DataGridPro
        components={{
          Toolbar: EditToolbar
        }}
        rows={content}
        apiRef={apiRef}
        columns={columns}
        onCellEditCommit={handleEdit}
      />
    </div>
  );
}
