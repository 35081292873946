import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  estimates: [],
  utilities: [],
  hours: [],
  estimate: null
};

const slice = createSlice({
  name: 'estimate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getEstimatesSuccess(state, action) {
      state.isLoading = false;
      state.estimates = action.payload;
    },

    // GET POSTS
    getUtilitiesSuccess(state, action) {
      state.isLoading = false;
      state.utilities = action.payload;
    },

    // GET POSTS
    getHoursSuccess(state, action) {
      state.isLoading = false;
      state.hours = action.payload;
    },
    // GET POST
    getEstimateSuccess(state, action) {
      state.isLoading = false;
      state.estimate = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllEstimates() {
  console.log('getting estimates');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/estimates/index');
      console.log('response estijmates: ', response);
      dispatch(slice.actions.getEstimatesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllUtilities() {
  console.log('getting utilities');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/utilities/index');
      console.log('response utility: ', response);
      dispatch(slice.actions.getUtilitiesSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllHours() {
  console.log('getting hours');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/hours/index');
      console.log('response hours: ', response);
      dispatch(slice.actions.getHoursSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEstimate(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/estimates/estimate/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getEstimateSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
