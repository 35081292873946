import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
//
import { GenderOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 340;
const LEGEND_HEIGHT = 40;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function PopulationBarChart({ ageGroups, height }) {
  const { translate } = useLocales();
  const theme = useTheme();
  if (!ageGroups) return <LoadingScreen />;
  const sum = ageGroups.reduce((a, v) => (a += v.count), 0);
  const array = ageGroups.map((i) => i.count / sum);
  const categories = ageGroups.map((i) => i.group);
  const sumC = ageGroups.reduce((a, v) => (a += v.countCounty), 0);
  const arrayC = ageGroups.map((i) => i.countCounty / sumC);
  const sumCc = ageGroups.reduce((a, v) => (a += v.countCountry), 0);
  const arrayCc = ageGroups.map((i) => i.countCountry / sumCc);
  const newchartdata = [
    { name: translate('Municipality'), data: array },
    { name: translate('County'), data: arrayC },
    { name: translate('Country'), data: arrayCc }
  ];
  const chartOptions = merge(GenderOptionChart(), {
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: '#F15B46'
            },
            {
              from: -45,
              to: 0,
              color: '#FEB019'
            }
          ]
        },
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: '%'
      },
      labels: {
        formatter(y) {
          return (y * 100).toFixed(0);
        }
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.primary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="bar" series={newchartdata} options={chartOptions} height={height} />
    </ChartWrapperStyle>
  );
}
