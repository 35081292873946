import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { styled } from '@mui/material/styles';
//
import useLocales from '../../../hooks/useLocales';

import { GenderOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 340;
const LEGEND_HEIGHT = 52;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important'
  }
}));

// ----------------------------------------------------------------------

const CHART_DATA = [67, 84, 97, 61];

export default function GenderChart({ name, place, muni, county, country }) {
  const { translate } = useLocales();
  const chartdata = [
    {
      name: translate('Women'),
      data: [place, muni, county, country]
    },
    {
      name: translate('Men'),
      data: [100 - place, 100 - muni, 100 - county, 100 - country]
    }
  ];
  const chartOptions = merge(GenderOptionChart(), {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: [name, translate('Municipality'), translate('County'), translate('Country')]
    },
    tooltip: {
      y: {
        formatter(y) {
          return `${y} %`;
        }
      }
    },
    fill: {
      opacity: 1
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="bar" series={chartdata} options={chartOptions} height={340} />
    </ChartWrapperStyle>
  );
}
