import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

export default function BasicEditingGrid({ onSave, industryData }) {
  console.log(industryData);
  const [content, setContent] = useState(industryData);

  const handleEdit = (params) => {
    console.log(params);
    const cont = content.map((item, index) => {
      if (index === params.id) {
        item.marketRent = params.value;
      }
      return item;
    });
    console.log(cont);
    setContent(cont);
  };
  const handleSave = () => {
    console.log(content);
    onSave(content);
  };

  const rowz = content
    .map((industry, index) => ({ id: index, name: industry.name, rent: industry.marketRent }))
    .filter(
      (industry) =>
        industry.name !== 'Vacant' &&
        industry.name !== 'Office' &&
        industry.name !== 'Not service' &&
        industry.name !== 'Co-working' &&
        industry.name !== 'Official Services' &&
        industry.name !== 'Other'
    );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', height: 800 }}>
        <DataGridPro rows={rowz} columns={columns} onCellEditCommit={handleEdit} />
      </div>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'center' }} sx={{ width: '100%', p: { xs: 3, md: 3 } }}>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </div>
    </>
  );
}

const columns = [
  { field: 'name', headerName: 'Name', width: 480, editable: false },
  { field: 'rent', headerName: 'Market Rent', width: 480, type: 'number', editable: true }
];
