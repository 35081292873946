import { Stack, Typography, Card } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import LoadingScreen from '../../LoadingScreenSmall';

const DURATIONS = ['5-20', '20-60', '60-120', '120-300', '300+'];
const DURATIONTIMES = [15, 45, 90, 15, 500];
export default function DashedLineChart({ data }) {
  console.log('dwell', data);
  function uniqueArray(a) {
    return [...new Set(a)];
  }
  const adat = uniqueArray(data);
  const sorted = adat.sort((a, b) => (a.year_month > b.year_month ? 1 : -1));
  console.log('sort, ', sorted);
  const mapped = sorted.map((da) => {
    var d = new Date(da.year_month)
    const value = da.visits;
    const datestring = d.toLocaleDateString();
    const tot = { value, date: d, datestring, duration: da.duration };
    return tot;
  })

  const xLabels = mapped.map((d) => d.date).filter((date, i, self) =>
    self.findIndex(d => d.getTime() === date.getTime()) === i
  );
  const values = mapped.map((d) => d.value);
  const dataSeries = DURATIONS.map((d, idx) => {
    const filtered = mapped.filter((m) => m.duration === d);
    const weight = DURATIONTIMES[idx];
    const values = filtered.map((f) => f.value);
    const sum = values.reduce((a, v) => (a += v * weight));
    const label = d;
    const obj = { id: label, label, data: values, stack: 'total', summa: sum, count: values.length };
    return obj;
  });
  const avgvalue = dataSeries.reduce((a, v) => (a += v.summa), 0) / values.reduce((a, v) => (a += v), 0)
  console.log('datase', dataSeries.reduce((a, v) => (a += v.summa), 0));
  if (!xLabels.length) return <LoadingScreen />;
  return (
    <Stack>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {Math.round(avgvalue) || 0} min
          </Typography>

          <Typography variant='subtitle2'>
            Average
          </Typography>
        </Card>
      </Stack>
      <BarChart
        height={400}
        xAxis={[{
          scaleType: 'band', data: xLabels,
          valueFormatter: (date) => `${date.getMonth() + 1} / ${date.getFullYear()}`,
        }]}
        series={dataSeries}
      />
    </Stack>
  );
}