import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// material
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

//
import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;

const GeoJSON = require('geojson');
const classifyPoint = require('robust-point-in-polygon');

// ----------------------------------------------------------------------

PlacesMap.propTypes = {
  places: PropTypes.any.isRequired,
  onChangedPoly: PropTypes.any
};

export default function PlacesMap({ places, onChangedPoly }) {
  const map = useRef(null);
  const lng = places.longitude || 0;
  const lat = places.latitude || 0;
  const [items, setItems] = useState(places);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: 4
    });
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'draw_polygon'
    });
    map.current.addControl(draw);

    map.current.on('draw.create', updateArea);
    map.current.on('draw.delete', updateArea);
    map.current.on('draw.update', updateArea);

    function updateArea(e) {
      const data = draw.getAll();
      if (data.features.length > 0) {
        getPlaces(data.features[0].geometry.coordinates);
      } else {
        onChangedPoly([]);
        if (e.type !== 'draw.delete') alert('Use the draw tools to draw a polygon!');
      }
    }

    function getPlaces(coordArray) {
      const polyPlaces = items.filter((place) => classifyPoint(coordArray[0], [place.longitude, place.latitude]) < 1);
      onChangedPoly(polyPlaces);
      setItems(polyPlaces);
    }
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      map.current.addLayer({
        id: 'places',
        type: 'circle',
        source: {
          type: 'geojson',
          data: placesgeojson
        },
        paint: {
          'circle-color': '#AE452B',
          'circle-radius': 5,
          'circle-stroke-width': 4,
          'circle-stroke-opacity': 0.05
        }
      });
      // Initialize the marker at the query coordinates
      const popup = new mapboxgl.Popup();

      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
        if (!features.length) {
          popup.remove();
          return;
        }
        const feature = features[0];
        popup
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="/dashboard/places/place/${feature.properties.id}">
              ${feature.properties.name}
              </a></h5><p>
              ${feature.properties.city_name}
              </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });

    map.current.on('click', 'places', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, { layers: ['places'] });
      if (!features.length) {
        return;
      }
      const feature = features[0];
      console.log(feature);
    });
  });

  const placesgeojson = GeoJSON.parse(items, { Point: ['latitude', 'longitude'] });

  return <div id="map" style={{ width: '100%', height: '100%' }} />;
}
