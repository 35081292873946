import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoadingmatches: false,
  isLoadingTurnovers: false,
  isLoading: false,
  error: false,
  spaces: [],
  space: null,
  matchingneeds: [],
  hasMore: true,
  index: 0,
  step: 11,
  population: [],
  consumtion: null,
  turnovers: [],
  traffic: []
};

const slice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startLoadingMatches(state) {
      state.isLoadingmatches = true;
    },
    startLoadingTurnovers(state) {
      state.isLoadingTurnovers = true;
    },
    // GET NEEDS
    getTurnoversSuccess(state, action) {
      state.isLoadingTurnovers = false;
      console.log('turn', action.payload);
      state.turnovers = action.payload.company.turnovers;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getSpacesSuccess(state, action) {
      state.isLoading = false;
      state.spaces = action.payload.spaces;
    },

    // GET POST INFINITE
    getSpacesInitial(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },

    getMoreSpaces(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getSpaceSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.space = action.payload;
    },

    // GET POST
    getConsumtionSuccess(state, action) {
      state.isLoading = false;
      console.log('testing consumtiondata', action.payload);
      state.consumtion = action.payload.consumtion;
    },
    // GET POST
    getMatchesSuccess(state, action) {
      state.isLoadingmatches = false;
      state.matchingneeds = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreSpaces } = slice.actions;

// ----------------------------------------------------------------------

export function getAllSpaces() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spaces/index');
      dispatch(slice.actions.getSpacesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpacesInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/spaces/indexinit',
        params: {
          index,
          step
        }
      });

      const results = response.data.spaces.length;
      const { maxLength } = response.data;
      dispatch(slice.actions.getSpacesInitial(response.data.spaces));
      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTurnovers(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingTurnovers());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/spaces/turnovers/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getTurnoversSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSpace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spaces/space', {
        params: { id }
      });
      dispatch(slice.actions.getSpaceSuccess(response.data.space));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSpacePopulation(id) {
  console.log('Population');
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPop());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spaces/getpopulation', {
        params: { id }
      });
      console.log(response.data);
      dispatch(slice.actions.getSpacePopulation(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSpaceTraffic(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spaces/gettraffic', {
        params: { id }
      });
      dispatch(slice.actions.getSpaceTraffic(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMatchesSpace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMatches());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/spaces/match', {
        params: { id }
      });
      console.log('Matching needs: ', response);
      dispatch(slice.actions.getMatchesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
