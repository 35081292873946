import PropTypes from 'prop-types';
// material
import { Paper, Typography, Button } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', onAdd, ...other }) {
  const handleAdd = () => {
    onAdd(searchQuery);
  };
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Inga träffar
      </Typography>
      <Typography gutterBottom variant="body2" align="center">
        Inga träffar för &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Se efter om du kan ha stavat fel eller pröva på nytt.
      </Typography>
      <Button fullWidth variant="contained" onClick={handleAdd} sx={{ my: 1 }}> Lägg till företag </Button>
    </Paper>
  );
}
