import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Box, AppBar, Toolbar, Container, useMediaQuery } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import LogoHori from '../../components/LogoHori';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import LanguagePopover from '../dashboard/LanguagePopover';
import SettingModeMenu from '../../components/settings/SettingModeMenu';
import SettingFullscreenMenu from '../../components/settings/SettingFullscreenMenu';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'background.default' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <MHidden width="mdDown">
            <Box sx={{ height: '100%' }}>
              <RouterLink to="/">
                <LogoHori />
              </RouterLink>
            </Box>
          </MHidden>
          <MHidden width="smUp">
            <Box sx={{ height: { xs: APP_BAR_MOBILE - 2, md: APP_BAR_DESKTOP - 4 }, mb: 2 }}>
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
          {/*
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 0.5 }}>
            <Button variant="contained" target="_blank" href="/dashboard">
              Till Plejs
            </Button>
            <SettingModeMenu isOffset={isOffset} isHome={isHome} />
            <SettingFullscreenMenu isOffset={isOffset} isHome={isHome} />
          </Stack>
          */}
          <LanguagePopover />
          <SettingModeMenu isOffset={isOffset} isHome={isHome} />

          <MHidden width="mdDown">
            <SettingFullscreenMenu isOffset={isOffset} isHome={isHome} />
          </MHidden>
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
