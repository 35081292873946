import React, { useRef, useEffect, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import editOutline from '@iconify/icons-eva/eye-off-2-outline';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// material
import closeFill from '@iconify/icons-eva/close-fill';
import saveOutline from '@iconify/icons-eva/save-outline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Stack, Typography, IconButton, Card, Tooltip } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// routes
import LoadingScreen from '../../LoadingScreen';
import useLocales from '../../../hooks/useLocales';

import { mapConfig } from '../../../config';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired,
  spaces: PropTypes.any.isRequired
};

export default function PotentialMap(props) {
  const { place, catchment, onSave, onCancel, loading, onSwitch } = props;
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  const [array, setArray] = useState([]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      pitch: 60,
      center: [lng, lat],
      zoom: 15
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }

      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }

      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 13,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }

      map.current.addControl(draw);
      map.current.on('draw.create', updateArea);
      map.current.on('draw.delete', updateArea);
      map.current.on('draw.update', updateArea);
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true
    },
    defaultMode: 'draw_polygon'
  });

  function updateArea() {
    const data = draw.getAll();
    if (data.features.length > 0) {
      setArray(data.features[0].geometry.coordinates[0]);
      props.onCatchment(data.features[0].geometry.coordinates[0], 'walking', '5');
    } else {
      alert('Use the draw tools to draw a polygon!');
    }
  }

  const lngLat = { lng, lat };
  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });
  console.log('LOADING;', loading);

  return (
    <Card sx={{ height: '100%', weight: '100%' }}>
      <Box>
        <div id="map" style={{ width: '100%', height: '90vh' }} />
        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: '#fff',
              'margin-left': 0,
              'margin-top': 0,
              overflow: 'auto'
            }}
          >
            <LoadingScreen />
          </Box>
        ) : (
          <>
            <Tooltip title="Back to analysis">
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: '#fff',
                    'margin-left': 10,
                    'margin-top': 10,
                    overflow: 'auto'
                  }}
                >
                  <ChevronLeftIcon onClick={onSave} color="#008f00" />
                </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </Card>
  );
}
