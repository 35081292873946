import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../charts';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 140;
const LEGEND_HEIGHT = 0;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  }
}));
// ----------------------------------------------------------------------

export default function TruthBar({ hourly, height }) {
  const { translate } = useLocales();
  const theme = useTheme();
  console.log(hourly);
  if (!hourly || hourly.length < 1) return <LoadingScreen />;

  const CATEGORIES = [translate('Morning'), translate('Lunch'), translate('Evening'), translate('Night')];
  const data = [
    Math.round(hourly.morning),
    Math.round(hourly.lunch),
    Math.round(hourly.evening),
    Math.round(hourly.night)
  ];
  const categories = CATEGORIES;
  const newchartdata = [{ name: 'Footfall', data }];
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: '#F15B46'
            },
            {
              from: -45,
              to: 0,
              color: '#FEB019'
            }
          ]
        },
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: 'Footfall'
      },
      labels: {
        formatter(y) {
          return y.toFixed(0);
        }
      }
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="bar" series={newchartdata} options={chartOptions} height={height} />
    </ChartWrapperStyle>
  );
}
