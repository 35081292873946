import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  vouchers: [],
  categories: [],
  bank: [],
  voucher: null,
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getVouchersSuccess(state, action) {
      state.isLoading = false;
      state.vouchers = action.payload;
    },

    // GET POSTS
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.cat;
      state.bank = action.payload.bank;
    },
    // GET POST INFINITE
    getVouchersInitial(state, action) {
      state.isLoading = false;
      state.vouchers = action.payload;
    },

    getMoreVouchers(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getVoucherSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreVouchers } = slice.actions;

// ----------------------------------------------------------------------

export function getAllVouchers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/vouchers/index');
      dispatch(slice.actions.getVouchersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/categories/index');
      console.log(response.data);
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVoucher(id) {
  return async (dispatch) => {
    const id2 = id;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/vouchers/voucher', {
        params: { id, id2 }
      });
      dispatch(slice.actions.getVoucherSuccess(response.data.vouchertype));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
