import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
// material
import { Box } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
//
import LogoAlt from './components/LogoAlt';
import Typography from './theme/overrides/Typography';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  padding: 3,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------



const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const theme = useTheme();
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(()=>{
        setOnline(navigator.onLine)
    },[])

    // event listeners to update the state
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if(isOnline){
    return(
        props.children
    )
    } else {
        return(
          <>
            <RootStyle>
            <motion.div
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{
                duration: 2,
                ease: 'easeInOut',
                repeatDelay: 1,
                repeat: Infinity
                }}
            >
                <LogoAlt sx={{ width: 64, height: 64 }} />
            </motion.div>

            <Box
                component={motion.div}
                animate={{
                scale: [1.2, 1, 1, 1.2, 1.2],
                rotate: [270, 0, 0, 270, 270],
                opacity: [0.25, 1, 1, 1, 0.25],
                borderRadius: ['25%', '25%', '50%', '50%', '25%']
                }}
                transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
                sx={{
                width: 100,
                height: 100,
                borderRadius: '25%',
                position: 'absolute',
                border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`
                }}
            />

            <Box
                component={motion.div}
                animate={{
                scale: [1, 1.2, 1.2, 1, 1],
                rotate: [0, 270, 270, 0, 0],
                opacity: [1, 0.25, 0.25, 0.25, 1],
                borderRadius: ['25%', '25%', '50%', '50%', '25%']
                }}
                transition={{
                ease: 'linear',
                duration: 3.2,
                repeat: Infinity
                }}
                sx={{
                width: 120,
                height: 120,
                borderRadius: '25%',
                position: 'absolute',
                border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`
                }}
            />
            <Box sx={{ position: 'absolute', bottom: '20%', width: '50%', justifyContent:'center', textAlign: 'center' }}>
                <h1> No Internet Connection </h1>
            </Box>
            </RootStyle>
        </>
        )
    }
}

export default NoInternetConnection;

