import PropTypes from 'prop-types';
// material

import { styled } from '@mui/material/styles';
import { Typography, Button, Card, CardContent } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string
};

export default function AppWelcome({ displayName, onFw, onBack }) {
  const { translate } = useLocales();
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 25, lg: 40 },
          color: 'grey.800',
          width: '100%'
        }}
      >
        <Typography gutterBottom variant="h4">
          {translate('Welcome')},
          <br /> {!displayName ? '...' : displayName}!
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto' }}>
          {translate('Since this is the first time using Mind NEO, we would love to show you quickly how you can use the platform.')}
        </Typography>

        <Button variant="contained" onClick={onFw}>
          {translate('Lets go')}
        </Button>
      </CardContent>
    </RootStyle>
  );
}
