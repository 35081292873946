// material
import {
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Card,
  Stack,
  Autocomplete,
  Chip,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useMediaQuery
} from '@mui/material';
import { Icon } from '@iconify/react';

import infoOutline from '@iconify/icons-eva/info-outline';

import { useTheme } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import useLocales from '../../../hooks/useLocales';

import Iconify from '../../Iconify';
import { TruthBarSmall, TruthTable } from '.';
// ----------------------------------------------------------------------
const YEARS = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export default function TruthForecast({
  industrydata,
  name,
  currentForecast,
  onSelectYear,
  onForecasts,
  living,
  forecasts,
  populations,
  onPopulation,
  onSubpopulation,
  traffic,
  place
}) {
  const theme = useTheme();
  const { translate } = useLocales();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const filteredData = industrydata.filter(
    (i) => i.name !== 'Not service' && i.name !== 'Other' && i.name !== 'Vacant' && i.name !== 'Office'
  );

  const [forecastYears, setForecastYears] = useState([]);
  const [year, setYear] = useState(2021);
  const [newestYear, setNewestYear] = useState('');
  const [population, setPopulation] = useState('');
  const [fcast, setFcast] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [subpopulation, setSubpopulation] = useState('');

  useEffect(() => {
    onForecasts(forecastYears);
  }, [forecastYears]);


  const handleYear = (event, newYear) => {
    setYear(newYear);
    onSelectYear(newYear);
  };

  const handleAlterYear = (event, newYear) => {
    const index = forecastYears.indexOf(newYear); // 👉️  0
    if (index !== -1) {
      forecastYears[index] = newYear;
    }
  };
  const handleNewestYear = (event, newYear) => {
    if (!newYear) return;
    setNewestYear(newYear);
    const newForecastYears = [...forecastYears, newYear];
    setForecastYears(newForecastYears);
  };

  const handleClickOpenDetails = (id) => {
    console.log('id,', id, currentForecast);
    if (id < 0) {
      setFcast(currentForecast);
    } else {
      setFcast(forecasts[id]);
    }
    setOpenDialog(true);
  };
  const handleCloseDetails = () => {
    setOpenDialog(false);
  };
  console.log('industry', industrydata);
  if (!industrydata || industrydata.length < 1) return null;
  const totalTurnover = Math.round(filteredData.reduce((a, v) => (a += v.demandPhysical), 0) / 100000) / 10;
  const latestFc = forecasts[forecasts.length - 1];
  console.log('fc', forecasts, latestFc);
  return (
    <>
      <Dialog
        fullScreen={true}
        open={openDialog}
        onClose={handleCloseDetails}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <TruthTable forecast={fcast} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDetails}>
            {translate('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid key="new" item xs={12} sm={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
            <Stack spacing={1} sx={{ width: '100%' }}>
              <Typography variant="subtitle1"> Add forecast </Typography>
              <Autocomplete
                sx={{ width: '100%' }}
                defaultValue=""
                value={newestYear}
                onChange={handleNewestYear}
                options={YEARS.map((option) => option)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip {...getTagProps({ index })} key={option} size="medium" label={option} />
                  ))
                }
                renderInput={(params) => <TextField {...params} label="Year" />}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid key="blank" item xs={8} />
        <Grid key="actual" item xs={12} sm={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            {currentForecast ? (
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1"> Current scenario - 2024 </Typography>
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton>
                      <Icon
                        id={-1}
                        icon={infoOutline}
                        onClick={(e) => handleClickOpenDetails(e.currentTarget.id)}
                        color="primary.main"
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                {/*
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('userGrowth')} </Typography>
                  <Typography variant="subtitle1"> {Math.round(currentForecast.populationIndex * 10) / 10} </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('Total konsumtion')} </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.totalConsumtion / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('Omni-konsumtion i området')} </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.retailConsumtion / 100000) / 10} mkr
                  </Typography>
                </Stack>
             */}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Physical consumption within cathcment </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.physicalConsumtion / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Grocery </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.industrydata.find((i) => i.name === 'Grocery store').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Restaurant & Cafe </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.industrydata.find((i) => i.name === 'Restaurant').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Pharmacy </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.industrydata.find((i) => i.name === 'Pharmacy').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Hairdresser </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.industrydata.find((i) => i.name === 'Hairdresser').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Books and stationary </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(currentForecast.industrydata.find((i) => i.name === 'Books and stationary').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Box sx={{ width: '100%' }}>
                  <TruthBarSmall forecast={currentForecast} height={240} />
                </Box>
              </Stack>
            ) : null}
          </Card>
        </Grid>
        {forecasts.length && (

          <Grid key="future" item xs={12} sm={6}>
            <Card sx={{ display: 'flex', p: 2 }}>
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1">
                    Simulation - {forecasts[forecasts?.length - 1].year}
                  </Typography>
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton>
                      <Icon
                        id="future"
                        icon={infoOutline}
                        onClick={(e) => handleClickOpenDetails(e.currentTarget.id)}
                        color="primary.main"
                      />
                    </IconButton>
                    <IconButton size="small" onClick={handleAlterYear}>
                      <Iconify icon="eva:trash-outline" width={20} height={20} />
                    </IconButton>
                  </Stack>
                </Stack>
                {/*
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('userGrowth')} </Typography>
                  <Typography variant="subtitle1"> {Math.round(forecast.populationIndex * 10) / 10} </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('totalConsumtion')} </Typography>
                  <Typography variant="subtitle1">{Math.round(forecast.totalConsumtion / 100000) / 10} mkr</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> {translate('retailConsumtion')} </Typography>
                  <Typography variant="subtitle1">{Math.round(forecast.retailConsumtion / 100000) / 10} mkr</Typography>
                </Stack>

             */}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}> Physical consumption within catchment </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].physicalConsumtion / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Grocery </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].industrydata.find((i) => i.name === 'Grocery store').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Restaurant & Cafe </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].industrydata.find((i) => i.name === 'Restaurant').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Pharmacy </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].industrydata.find((i) => i.name === 'Pharmacy').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Hairdresser </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].industrydata.find((i) => i.name === 'Hairdresser').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2"> Books and stationary </Typography>
                  <Typography variant="subtitle1">
                    {Math.round(forecasts[forecasts?.length - 1].industrydata.find((i) => i.name === 'Books and stationary').demandPhysical / 100000) / 10} mkr
                  </Typography>
                </Stack>
                <Box sx={{ width: '100%' }}>
                  <TruthBarSmall forecast={forecasts[forecasts?.length - 1]} height={240} />
                </Box>
              </Stack>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}
