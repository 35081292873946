import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------
export function mFormatter(num) {
    console.log(num);
    return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + ' m' : Math.sign(num)*Math.abs(num)
}
export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}
export function fKr(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0kr' : '0,0.00kr');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}
export function fShortenNumberone(number) {
  if (number > 1000000) {
    return replace(numeral(number).format('0.0a'), '.0', '');
  }
  return replace(numeral(number).format('0.0a'), '.0', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
