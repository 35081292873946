import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
//
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;
// ----------------------------------------------------------------------

const GeoJSON = require('geojson');
const classifyPoint = require('robust-point-in-polygon');

// ----------------------------------------------------------------------

PrjctsMap.propTypes = {
  prjcts: PropTypes.any.isRequired,
  onChangedPoly: PropTypes.any
};

export default function PrjctsMap(prjcts, onChangedPoly) {
  const map = useRef(null);
  const lng = prjcts.prjcts[0].longitude || 0;
  const lat = prjcts.prjcts[0].latitude || 0;
  const [items, setItems] = useState(prjcts.prjcts);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/mapbox/light-v10',
      center: [lng, lat],
      zoom: 4
    });
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'draw_polygon'
    });
    map.current.addControl(draw);

    map.current.on('draw.create', updateArea);
    map.current.on('draw.delete', updateArea);
    map.current.on('draw.update', updateArea);

    function updateArea(e) {
      const data = draw.getAll();
      if (data.features.length > 0) {
        getPrjcts(data.features[0].geometry.coordinates);
      } else {
        onChangedPoly([]);
        if (e.type !== 'draw.delete') alert('Use the draw tools to draw a polygon!');
      }
    }

    function getPrjcts(coordArray) {
      const polyPrjcts = items.filter((prjct) => classifyPoint(coordArray[0], [prjct.longitude, prjct.latitude]) < 1);
      onChangedPoly(polyPrjcts);
      setItems(polyPrjcts);
    }
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      map.current.addLayer({
        id: 'prjcts',
        type: 'circle',
        source: {
          type: 'geojson',
          data: prjctsgeojson
        },
        paint: {
          'circle-color': '#AE452B',
          'circle-radius': 5,
          'circle-stroke-width': 4,
          'circle-stroke-opacity': 0.05
        }
      });
      // Initialize the marker at the query coordinates
      const popup = new mapboxgl.Popup();

      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['prjcts'] });
        if (!features.length) {
          popup.remove();
          return;
        }
        const feature = features[0];
        popup
          .setLngLat(feature.geometry.coordinates)
          .setHTML(
            `<h5 className="display-4"> <a href="/properties">
              ${feature.properties.name}
              </a></h5><p>
              ${feature.properties.city_name}
              </p>`
          )
          .addTo(map.current);
        map.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });
    });

    map.current.on('click', 'prjcts', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, { layers: ['prjcts'] });
      if (!features.length) {
        return;
      }
      const feature = features[0];
      console.log(feature);
    });

    // Change the cursor to a pointer when the mouse is over the prjcts layer.
    map.current.on('mouseenter', 'isoLayer', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.current.on('mouseleave', 'isoLayer', () => {
      map.current.getCanvas().style.cursor = '';
    });
  });

  // Create variables to use in getIso()
  const prjctsgeojson = GeoJSON.parse(items, { Point: ['latitude', 'longitude'] });

  return <div id="map" style={{ width: '100%', height: '80vh' }} />;
}
