import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  budgets: [],
  budget: null
};

const slice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getBudgetsSuccess(state, action) {
      state.isLoading = false;
      state.budgets = action.payload;
    },

    // GET POST
    getBudgetSuccess(state, action) {
      state.isLoading = false;
      state.budget = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllBudgets() {
  console.log('getting budgets');
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    console.log('tryomg');
    try {
      console.log('trying');
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/budgets/index');
      console.log('response');
      console.log(response);
      dispatch(slice.actions.getBudgetsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBudget(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id);
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/budgets/budget/${id}`, {
        params: { id }
      });
      console.log(response);
      dispatch(slice.actions.getBudgetSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
