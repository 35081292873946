import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editOutline from '@iconify/icons-eva/edit-outline';
// material
import { Box, IconButton, Card } from '@mui/material';
// mapbox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// routes
import { mapConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';

mapboxgl.accessToken = mapConfig;

// ----------------------------------------------------------------------

PotentialMap.propTypes = {
  place: PropTypes.object.isRequired
};

export default function PotentialMap({ place, population, catchment, popfigure, onChange, key }) {
  const { translate } = useLocales();
  const map = useRef(null);
  const lng = place.longitude || 0;
  const lat = place.latitude || 0;
  useEffect(() => {
    if (map.current || !population || population.length < 1) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'maptdr', // container id
      style: 'mapbox://styles/cronsioejesper/ckttvsrch0gex17s0vbp0x5c8',
      pitch: 60,
      center: [lng, lat - 0.001],
      zoom: 12,
      bearing: 0,
      interactive: false
    });
    map.current.scrollZoom.disable();

    // add terrain, sky, and line layers once the style has loaded
    function rotateCamera(timestamp) {
      // clamp the rotation between 0 -360 degrees
      // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
      map.current.rotateTo((timestamp / 100) % 360, { duration: 0 });
      // Request the next frame of the animation.
      requestAnimationFrame(rotateCamera);
    }
    if (!map.current || !population || population.length < 1) return; // wait for map to initialize
    map.current.on('load', () => {
      rotateCamera(0);
      const sourceIdMaine = 'maine';
      const sourcemaine = map.current.getSource(sourceIdMaine);

      if (!map.current.getLayer('catchment')) {
        map.current.addLayer({
          id: 'catchment',
          type: 'line',
          source: 'catchment', // reference the data source
          layout: { visibility: 'visible' },
          paint: {
            'line-color': '#5a3fc0',
            'line-width': 2
          }
        });
      }
      if (!sourcemaine) {
        map.current.addSource('maine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: population
          }
        });
      }
      const sourceIdCatchment = 'catchment';
      const sourcecatchment = map.current.getSource(sourceIdCatchment);
      if (!sourcecatchment) {
        map.current.addSource('catchment', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              // These coordinates outline Maine.
              coordinates: [catchment]
            }
          }
        });
      }

      // Add a new layer to visualize the polygon.
      if (!map.current.getLayer('3d-buildings')) {
        map.current.addLayer({
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 9,
          paint: {
            'fill-extrusion-color': '#aaa',
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6
          }
        });
      }
      if (!map.current.getLayer('maine')) {
        map.current.addLayer({
          id: 'maine',
          type: 'fill',
          source: 'maine', // reference the data source
          layout: {},
          paint: {
            'fill-color': {
              property: 'population',
              stops: [
                [0, '#008f00'],
                [1000, '#30D5C8'],
                [2000, '#FFFF00'],
                [5000, '#FFD700'],
                [10000, '#8b0000']
              ]
            }, // blue color fill
            'fill-opacity': 0.6
          }
        });
      }
      map.current.flyTo({
        center: [lng, lat],
        zoom: 12,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });

      // Add 3d buildings and remove label layers to enhance the map

      map.current.on('mousemove', (event) => {
        const states = map.current.queryRenderedFeatures(event.point, {
          layers: ['maine']
        });
        onChange(Math.round(states[0].properties.population));
      });
      // make a marker for each feature and add to the map
      marker.setLngLat(lngLat).addTo(map.current);
    });
  });

  // Create a LngLat object to use in the marker initialization
  // https://docs.mapbox.com/mapbox-gl-js/api/#lnglat
  const lngLat = { lng, lat };

  const marker = new mapboxgl.Marker({
    color: '#3E586A'
  });

  return (
    <Box>
      <div id="maptdr" style={{ height: '100vh' }} />
    </Box>
  );
}
