import { LineChart } from '@mui/x-charts/LineChart';
import { Stack, Typography, Card } from '@mui/material';

const calculateCorrelation = require("calculate-correlation");

export default function DashedLineChart({ data }) {
  function uniqueArray(a) {
    return [...new Set(a)];
  }
  const adat = uniqueArray(data);
  const sorted = adat.sort((a, b) => (a.day > b.day ? 1 : -1));
  const mapped = sorted.map((da) => {
    var d = new Date(da.day)
    const value = da.visits;
    const dow = d.getDay();
    const datestring = d.toLocaleDateString();
    const tot = { value, dow, date: d, datestring };
    return tot;
  })
  const xLabels = mapped.map((d) => d.date);
  const values = mapped.map((d) => d.value);
  const weekday = mapped.filter((d) => d.dow !== 6 && d.dow !== 0);
  const weekend = mapped.filter((d) => d.dow === 6 || d.dow === 0);
  const monday = mapped.map((d) => {
    if (d.dow === 1) return 1;
    return 0;
  });
  const tuesday = mapped.map((d) => {
    if (d.dow === 2) return 1;
    return 0;
  });
  const wednesday = mapped.map((d) => {
    if (d.dow === 3) return 1;
    return 0;
  });
  const thursday = mapped.map((d) => {
    if (d.dow === 4) return 1;
    return 0;
  });
  const friday = mapped.map((d) => {
    if (d.dow === 5) return 1;
    return 0;
  });
  const correlationMon = calculateCorrelation(values, monday);
  const correlationTue = calculateCorrelation(values, tuesday);
  const correlationWed = calculateCorrelation(values, wednesday);
  const correlationThu = calculateCorrelation(values, thursday);
  const correlationFri = calculateCorrelation(values, friday);
  console.log('correlations', correlationMon, correlationTue, correlationWed, correlationThu, correlationFri);
  var powerDays = [];
  if (correlationMon > 0.15 || correlationMon < -0.15){
    powerDays.push('Monday')
  } 
  if (correlationTue > 0.15 ||correlationTue < -0.15){
    powerDays.push('Tuesday')
  } 
  if (correlationWed > 0.15 ||correlationWed < -0.15){
    powerDays.push('Wednesday')
  } 
  if (correlationThu > 0.15 ||correlationThu < -0.15){
    powerDays.push('Thursday')
  } 
  if (correlationFri > 0.15 ||correlationFri < -0.15){
    powerDays.push('Friday')
  }
  const averageWeekend = weekend.reduce((a, v) => (a += v.value), 0) / weekend.length;
  const averageValues = values.reduce((a, v) => (a += v), 0) / values.length;
  const eweekday = mapped.map((d, idx) => weekday.find((t) => t.date === d.date)?.value || 0);
  const eweekend = mapped.map((d, idx) => weekend.find((t) => t.date === d.date)?.value || 0);
  const weekendFactor = Math.round(averageWeekend/averageValues * 100) - 100;
  if (!xLabels.length || !eweekday.length || !eweekend.length) return;
  return (
    <Stack>
      <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {Math.min(...values) || 0}
          </Typography>

          <Typography variant='subtitle2'>
            Min
          </Typography>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {powerDays.toString()}
          </Typography>

          <Typography variant='subtitle2'>
            Power days
          </Typography>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {weekendFactor}
          </Typography>

          <Typography variant='subtitle2'>
            Weekend factor
          </Typography>
        </Card>
        <Card sx={{ borderRadius: 1, p: 3 }}>
          <Typography variant='h4'>
            {Math.max(...values) || 0}
          </Typography>

          <Typography variant='subtitle2'>
            Max
          </Typography>
        </Card>
      </Stack>
      <LineChart
        height={400}
        xAxis={[{
          scaleType: 'time', data: xLabels,
          valueFormatter: (date) => `${date.toLocaleDateString()} - ${date.toString().slice(0, 2)}`,
        }]}
        series={[
          {
            curve: "step",
            data: eweekend,
            label: 'Weekend',
            id: 'uvId',
            area: true,
            showMark: false,
            connectNulls: true,
            stack: 'total',
            color: '#e15759',
          },
          {
            curve: "step",
            data: eweekday,
            label: 'Weekday',
            id: 'pvId',
            area: true,
            stack: 'total',
            showMark: false
          },
        ]}
      />
    </Stack>
  );
}