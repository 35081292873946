import { merge } from 'lodash';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Typography, Box, Card } from '@mui/material';
//
import useLocales from '../../../hooks/useLocales';
import { fKr } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function Consumtion({ consumtionmoney, muni, place }) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <>
      <Card sx={{ py: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
            {fKr(Math.round(consumtionmoney * 1000))}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
            {translate('Average consumption/capita')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="caption" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
            {muni} {translate('municipality')}
          </Typography>
        </Box>
      </Card>
    </>
  );
}
