import { merge } from 'lodash';
// material
import { useTheme, styled } from '@mui/material/styles';

import { Typography, Box, Card, Stack, Grid } from '@mui/material';
//
import useLocales from '../../../hooks/useLocales';
import { fKr } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function ItemCard({ value, countryValue, text, type, muni, country, countyValue }) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <>
      <Card sx={{ py: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h4" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
            {translate(text)}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={4}>
            <Stack>
              <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                {value}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
                {muni}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={4}>
            <Stack>
              <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                {countyValue}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
                {translate('County')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={4}>
            <Stack>
              <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                {countryValue}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary, fontSize: 14 }}>
                {translate('Riket')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
